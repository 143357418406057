<!-- Footer Start -->
<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
        <a href="#" class="logo-footer">
          <img src="../../../assets/white logo.png" height="40" alt="" />
        </a>
        <p class="mt-4">
          Join us at CirclesNow in exploring life through five crucial lenses:
          mindset, communication, leadership, community, and lifestyle.
        </p>
        <!-- <ul class="list-unstyled social-icon social mb-0 mt-4">
          <li class="list-inline-item">
            <a href="javascript:void(0)" class="rounded">
              <i-feather
                name="facebook"
                class="fea icon-sm fea-social"
              ></i-feather>
            </a>
          </li>
          <li class="list-inline-item ml-1">
            <a href="javascript:void(0)" class="rounded">
              <i-feather
                name="instagram"
                class="fea icon-sm fea-social"
              ></i-feather>
            </a>
          </li>
          <li class="list-inline-item ml-1">
            <a href="javascript:void(0)" class="rounded">
              <i-feather
                name="twitter"
                class="fea icon-sm fea-social"
              ></i-feather>
            </a>
          </li>
          <li class="list-inline-item ml-1">
            <a href="javascript:void(0)" class="rounded">
              <i-feather
                name="linkedin"
                class="fea icon-sm fea-social"
              ></i-feather>
            </a>
          </li>
        </ul> -->
      </div>
      <!--end col-->

      <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"></div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <h4 class="text-light footer-head">Useful Links</h4>
        <ul class="list-unstyled footer-list mt-4">
          <li>
            <a (click)="redirecToPage('/privacy')" class="text-foot"
              ><i class="mdi mdi-chevron-right mr-1"></i> Privacy Policy</a
            >
          </li>

          <li>
            <a (click)="redirecToPage('/contactUs')" class="text-foot"
              ><i class="mdi mdi-chevron-right mr-1"></i> Contact Us</a
            >
          </li>
        </ul>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</footer>
<!--end footer-->
<footer class="footer footer-bar">
  <div class="container text-center">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="text-sm-left">
          <p class="mb-0">© 2021 CirclesNow.</p>
        </div>
      </div>
      <!--end col-->

      <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <ul class="list-unstyled text-sm-right mb-0">
          <li class="list-inline-item">
            <a href="javascript:void(0)"
              ><img
                src="assets/images/payments/american-ex.png"
                class="avatar avatar-ex-sm"
                title="American Express"
                alt=""
            /></a>
          </li>
          <li class="list-inline-item ml-1">
            <a href="javascript:void(0)"
              ><img
                src="assets/images/payments/discover.png"
                class="avatar avatar-ex-sm"
                title="Discover"
                alt=""
            /></a>
          </li>
          <li class="list-inline-item ml-1">
            <a href="javascript:void(0)"
              ><img
                src="assets/images/payments/master-card.png"
                class="avatar avatar-ex-sm"
                title="Master Card"
                alt=""
            /></a>
          </li>
          <li class="list-inline-item ml-1">
            <a href="javascript:void(0)"
              ><img
                src="assets/images/payments/paypal.png"
                class="avatar avatar-ex-sm"
                title="Paypal"
                alt=""
            /></a>
          </li>
          <li class="list-inline-item ml-1">
            <a href="javascript:void(0)"
              ><img
                src="assets/images/payments/visa.png"
                class="avatar avatar-ex-sm"
                title="Visa"
                alt=""
            /></a>
          </li>
        </ul>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</footer>
