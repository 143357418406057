import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TimerService {
    private customeTime: string = "";
    public timerObsrSubject: any = new BehaviorSubject({
        days:0,_hours:0,_minutes:0,_seconds:0
    });
    public timerObsr: any = this.timerObsrSubject.asObservable();
  constructor() {}

  setTime(str: string) {
    this.customeTime = str;
  }
  getDays(t) {
    return Math.floor(t / (1000 * 60 * 60 * 24));
  }

  getHours(t) {
    return Math.floor((t / (1000 * 60 * 60)) % 24);
  }

  getMinutes(t) {
    return Math.floor((t / 1000 / 60) % 60);
  }

  getSeconds(t) {
    return Math.floor((t / 1000) % 60);
  }
}
