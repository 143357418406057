import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Channel } from 'src/app/service/dataservice.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-channel-list',
  templateUrl: './channel-list.component.html',
  styleUrls: ['./channel-list.component.css']
})
export class ChannelListComponent implements OnInit {

@Input()  channelList:Array<Channel> = [];
  url:string = environment.viewUrl
  constructor(
    private router:Router
  ) { }

  ngOnInit(): void {
  }

  redirectToBrowsePage(channel:Channel) : void {
    let store = JSON.stringify(channel)
    localStorage.setItem("channel",store);
    
    this.router.navigateByUrl("/browse/"+channel.C_ID)
  }

}
