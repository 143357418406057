<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title">Contact Us</h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <!-- <li class="breadcrumb-item"><a href="#">Page</a></li>
                <li class="breadcrumb-item"><a href="#">Contact</a></li> -->
                <li class="breadcrumb-item active" aria-current="page">
                  Contact Us
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
        fill="currentColor"
      ></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Contact -->
<section class="section" style="padding-top: unset;">
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 pt-2 pt-sm-0 order-2 order-md-1">
        <div class="card shadow rounded border-0">
          <div class="card-body py-5">
            <h4 class="card-title">Get In Touch!</h4>
            <div class="custom-form mt-4">
              <div id="message"></div>
              <form
                method="post"
                action="php/contact.php"
                name="contact-form"
                [formGroup]="contactForm"
                id="contact-form"
                (ngSubmit)="submitForm()"
              >
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group position-relative">
                      <label>Name<span class="text-danger">*</span></label>
                      <i-feather
                        name="user"
                        class="fea icon-sm icons"
                      ></i-feather>
                      <input
                        name="name"
                        id="name"
                        type="text"
                        class="form-control pl-5"
                        placeholder="Name "
                        autofocus
                        formControlName="Name"
                      />
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-md-6">
                    <div class="form-group position-relative">
                      <label>Email<span class="text-danger">*</span></label>
                      <i-feather
                        name="mail"
                        class="fea icon-sm icons"
                      ></i-feather>
                      <input
                        name="email"
                        id="email"
                        type="email"
                        class="form-control pl-5"
                        placeholder="Email address"
                        formControlName="Email"
                      />
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-md-12">
                    <div class="form-group position-relative">
                      <label>Subject</label>
                      <i-feather
                        name="book"
                        class="fea icon-sm icons"
                      ></i-feather>
                      <input
                        name="subject"
                        id="subject"
                        type="text"
                        class="form-control pl-5"
                        placeholder="Subject"
                        formControlName="Subject"
                      />
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-md-12">
                    <div class="form-group position-relative">
                      <label>Message</label>
                      <i-feather
                        name="message-circle"
                        class="fea icon-sm icons"
                      ></i-feather>
                      <textarea
                        name="comments"
                        id="comments"
                        rows="4"
                        class="form-control pl-5"
                        placeholder="Message"
                        formControlName = "Message"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <!--end row-->
                <div class="row">
                  <div class="col-sm-12 text-center">
                    <input
                      type="submit"
                      id="submit"
                      name="send"
                      class="submitBnt btn btn-primary btn-block"
                      value="Send Message"
                      [disabled]="contactForm.status==='INVALID'"
                    />
                    <div id="simple-msg " class="mt-2" *ngIf="apiResponse">
                        <div *ngIf="apiResponse.Status==='1'" class="alert alert-success">
                            Thank you for submiting details.
                        </div>
                        <div *ngIf="apiResponse.Status==='0'" class="alert alert-danger">
                          {{
                            apiResponse.Message
                          }}
                        </div>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </form>
              <!--end form-->
            </div>
            <!--end custom-form-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 order-1 order-md-2">
        <div class="card border-0">
          <div class="card-body p-0">
            <img src="assets/images/contact.svg" class="img-fluid" alt="" />
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container-fluid mt-100 mt-60">
    <div class="row">
      <div class="col-12 p-0">
        <!-- <div class="card map border-0">
          <div class="card-body p-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
              style="border:0" allowfullscreen></iframe>
          </div>
        </div> -->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->
<a
  href="javascript: void(0);"
  class="btn btn-icon btn-soft-primary back-to-top"
  id="back-to-top"
  [ngxScrollTo]="'#home'"
>
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
