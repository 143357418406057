<section class="bg-home d-flex align-items-center">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6">
        <div class="mr-lg-5" *ngIf="!courseGUID">
          <img
            src="assets/images/user/login.svg"
            class="img-fluid d-block mx-auto"
            alt=""
          />
        </div>

        <div *ngIf="courseGUID">
          <app-course-to-buy [courseGUID]="courseGUID"></app-course-to-buy>
        </div>
      </div>
      <div class="col-lg-5 col-md-6">
        <div class="card login-page bg-white shadow rounded border-0">
          <div class="card-body">
            <h4 class="card-title text-center">Login</h4>
            <form
              class="login-form mt-4"
              [formGroup]="loginForm"
              (ngSubmit)="submit()"
            >
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group position-relative">
                    <label>Your Email <span class="text-danger">*</span></label>
                    <i-feather
                      name="user"
                      class="fea icon-sm icons"
                    ></i-feather>
                    <input
                      type="email"
                      formControlName="email"
                      class="form-control pl-5"
                      placeholder="Email"
                      name="email"
                      required=""
                      autofocus
                    />

                    <p
                      class="text-danger"
                      *ngIf="
                        loginForm.controls.email.status === 'INVALID' &&
                        loginForm.controls.email.touched
                      "
                    >
                      Email field is required.
                    </p>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group position-relative">
                    <label>Password <span class="text-danger">*</span></label>
                    <i-feather name="key" class="fea icon-sm icons"></i-feather>
                    <input
                      type="password"
                      formControlName="password"
                      class="form-control pl-5"
                      placeholder="Password"
                      required=""
                    />
                    <p
                      class="text-danger"
                      *ngIf="
                        loginForm.controls.password.status === 'INVALID' &&
                        loginForm.controls.password.touched
                      "
                    >
                      Password field is required.
                    </p>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="d-flex justify-content-between">
                    <div class="form-group">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck1"
                        />
                        <label class="custom-control-label" for="customCheck1"
                          >Remember me</label
                        >
                      </div>
                    </div>
                    <p class="forgot-pass mb-0">
                      <a
                        (click)="formgotPassword()"
                        class="text-info cr-pointer font-weight-bold"
                        >Forgot password?</a
                      >
                    </p>
                  </div>
                </div>
                <div class="col-lg-12 mb-0">
                  <button
                    class="btn btn-primary btn-block cr-pointer"
                    [disabled]="!loginForm.valid"
                  >
                    Sign In
                  </button>
                </div>
                <br />
                <div
                  class="alert alert-danger"
                  *ngIf="errorMessage"
                  style="width: 100%; margin-top: 2rem"
                >
                  {{ errorMessage }}
                </div>
                <div class="col-12 text-center">
                  <p class="mb-0 mt-3">
                    <small class="text-dark mr-2">New user?</small>
                    <a
                      (click)="signUp()"
                      class="text-info cr-pointer font-weight-bold"
                      >Create an account</a
                    >
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!---->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->
