import { DatePipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { interval } from "rxjs";
import { map } from "rxjs/operators";
import { AuthenticationService } from "src/app/service/authentication.service";
import { CheckoutService } from "src/app/service/checkout.service";
import {
  Course,
  DataserviceService,
} from "src/app/service/dataservice.service";
import { TimerService } from "src/app/service/timer.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-stream",
  templateUrl: "./stream.component.html",
  styleUrls: ["./stream.component.css"],
})
export class StreamComponent implements OnInit, OnDestroy {
  workShopList: Array<any> = [];
  viewUrl: string = environment.viewUrl;
  errorMsg: string = "";
  _diff: any;
  timer: any;
  _days: any = 0;
  _hours: any = 0;
  _minutes: any = 0;
  _seconds: any = 1;
  courseIntrDetails: Course;
  constructor(
    private dataService: DataserviceService,
    private checkoutService: CheckoutService,
    private router: Router,
    private datePipe: DatePipe,
    private service: TimerService,
    private dateService: DataserviceService,
    public authService: AuthenticationService
  ) {}

  getSPDate(payload: string, callBack) {
    this.dataService.getSPData(payload).subscribe(
      (response) => {
        callBack(response);
      },
      (error) => {
        callBack([]);
      }
    );
  }
  //routerLink="/auth/signup?returnUrl=account"
  redirecToSignUp() {
    this.router.navigateByUrl("/auth/signup?returnUrl=account")
}
  addInCard(course: any): void {
    console.log(this.courseIntrDetails);
    if (this.courseIntrDetails) {
      if (this.courseIntrDetails.IsEnrolled) {
        this.router.navigateByUrl(`/watch/${this.courseIntrDetails.CourseId}`);
        return;
      }
    }
    let courseObject = {
      CourseId: course.CourseId,
      CourseName: course.title,
      Description: course.Description,
      ThumbnailGUID: course.image,
      type: "Workshop",
      web_price: course.Price,
      Price: course.Price,
    };

    if (!this.checkoutService.isCourseAlreadyInBag(courseObject.CourseId)) {
      this.checkoutService.setStoreItem(courseObject, "workshop");
      this.errorMsg = "";
    } else {
      this.errorMsg = "Workshop already added in cart!";
    }
    this.router.navigateByUrl("/buy/buyCourse");
  }
  publishDate: any;
  isNotToday: boolean = false;
  getWorkShopList() {
    this.getSPDate("getContent @courseType=4", (result) => {
      this.workShopList = result["Table"];
      this.getCourseDetails(this.workShopList[0].CourseId);
      let publishDateResp = this.workShopList[0].PublishDate
        ? this.workShopList[0].PublishDate
        : new Date();
      this.publishDate = publishDateResp;

      this.timer = interval(1000).subscribe((x) => {
        let fr_publishDate: any = this.datePipe.transform(
          publishDateResp, //
          "yyyy-MM-dd, h:mm:ss a"
        );
        let fr_currentDate: any = this.datePipe.transform(
          new Date().toString(),
          "yyyy-MM-dd, h:mm:ss a"
        );

        let publishDate: any = Date.parse(new Date(fr_publishDate).toString());

        let currentDate: any = Date.parse(
          new Date(fr_currentDate).toLocaleString("en-US", {
            timeZone: "America/Los_Angeles",
          })
        );
        if (
          new Date(publishDateResp).getDate() != new Date(currentDate).getDate()
        )
          this.isNotToday = true;
        else this.isNotToday = false;

        // console.log(
        //   new Date(publishDateResp).getDate(),
        //   "p",
        //   new Date(currentDate).getDate()
        // );

        // console.log(
        //   "publishDate",
        //   this.datePipe.transform(publishDate, "yyyy-MM-dd, h:mm:ss a"),
        //   "currentDate",
        //   this.datePipe.transform(currentDate, "yyyy-MM-dd, h:mm:ss a")
        // );
        this._diff = publishDate - currentDate;
        this._days = this.getDays(this._diff);
        this._days =
          this._days.toString().length === 1 ? "0" + this._days : this._days;
        this._hours = this.getHours(this._diff);
        this._hours =
          this._hours.toString().length === 1 ? "0" + this._hours : this._hours;
        this._minutes = this.getMinutes(this._diff);
        this._minutes =
          this._minutes.toString().length === 1
            ? "0" + this._minutes
            : this._minutes;
        this._seconds = this.getSeconds(this._diff);
        this._seconds =
          this._seconds.toString().length === 1
            ? "0" + this._seconds
            : this._seconds;
      });
    });
  }

  ngOnDestroy(): void {
    // this.timer.unsubscribe();
  }
  getCourseDetails(courseId: any) {
    if (!courseId) return;
    if (this.authService.isLoggednIn()) {
      this.dataService
        .getCourseDetailsByID(courseId, this.authService.getUserInfo().ID)
        .subscribe((response) => {
          this.courseIntrDetails = response[0];
        });
    }
  }
  getDays(t) {
    return Math.floor(t / (1000 * 60 * 60 * 24));
  }

  getHours(t) {
    return Math.floor((t / (1000 * 60 * 60)) % 24);
  }

  getMinutes(t) {
    return Math.floor((t / 1000 / 60) % 60);
  }

  getSeconds(t) {
    return Math.floor((t / 1000) % 60);
  }

  ngOnInit(): void {
    this.getWorkShopList();
  }
}
