import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChannelListComponent } from '../channel-list/channel-list.component';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { CategoryListComponent } from '../category-list/category-list.component';
import {CoursesListComponent} from "../courses-list/courses-list.component";
import {InstructorListComponent} from "../instructor-list/instructor-list.component";
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { CampaignComponent } from '../campaign/campaign.component';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { MatIconModule } from '@angular/material/icon';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { PackageListComponent } from '../package-list/package-list.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
@NgModule({
  declarations: [
    ChannelListComponent,
    CategoryListComponent,
    CoursesListComponent,
    InstructorListComponent,
    CampaignComponent,
    HeaderComponent,
    FooterComponent,
    PackageListComponent
  ],
  imports: [
    CommonModule,
    FeatherModule.pick(allIcons),
    ScrollToModule.forRoot(),
    MatIconModule,
    BsDropdownModule.forRoot(),
    MatSnackBarModule,
    NgxSkeletonLoaderModule
  ],
  exports: [
    ChannelListComponent,
    CategoryListComponent,
    CoursesListComponent,
    InstructorListComponent,
    CampaignComponent,
     HeaderComponent,
     FooterComponent,
     PackageListComponent
  ]
})
export class SharedModule { }
