import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthenticationService } from "src/app/service/authentication.service";

@Component({
  selector: "app-auth-re-password",
  templateUrl: "./auth-re-password.component.html",
  styleUrls: ["./auth-re-password.component.css"],
})
export class AuthRePasswordComponent implements OnInit {
  returnUrl: string = "";
  courseGUID: string;

  email: string = "";
  otp: any;
  password: string = "";
  confirmPassword: string = "";

  msg: any = {
    message: "",
    status: false,
  };

  data: any = {
    email: { email: "", status: false },
    password: { password: "", status: false },
    otp: { otp: "", status: false },
    step: { step: 1 },
  };
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private spinner: NgxSpinnerService
  ) {}

  redirectToSignIn(): void {
    this.router.navigateByUrl("auth/login?returnUrl=" + this.returnUrl);
  }

  addOTP(): void {
    this.data.otp.otp = this.otp;
    this.data.otp.status = true;
    this.data.step.step = 3;
  }

  resetPassword(): void {
    let payload = {
      EmailId: this.data.email.email,
      Password: this.password,
      token: "",
      Otp: this.data.otp.otp + "",
    };
    this.spinner.show();
    this.authService.resetPasswordOTP(payload).subscribe(
      (response) => {
        if (response === "1") {
          this.msg.message = "Password updated successfully";
          this.msg.status = true;

          setTimeout(() => {
            this.spinner.hide();
            this.login();
          }, 1000);
        } else if (response === "0") {
          this.spinner.hide();
          this.msg.message = "OTP Expired.";
          this.msg.status = false;
          this.data.step.step = 2;
        } else if (response === "-1") {
          this.spinner.hide();
          this.msg.message = "Invalid email address.";
          this.msg.status = false;
          this.data.step.step = 1;
        } else if (response === "-2") {
          this.spinner.hide();
          this.msg.message = "Invalid OTP.";
          this.msg.status = false;
          this.data.step.step = 2;
        }
      },
      (error) => {
        this.spinner.hide();
        this.msg.message = "Error,Please try again.";
        this.msg.status = false;
      }
    );
  }
  submitForm(): void {
      this.authService.checkEmailAlreadyExit(this.email).subscribe(
        (response)=>{
          if(response===0){
            this.postPasswordResetEmail()
         
          }else{
            this.msg.message = "Error :: User not exist.";
            this.msg.status = false;
          }
        },
        (error)=>{
          this.msg.message = "Error :: Please try again.";
          this.msg.status = false;
        }
      )
  }
postPasswordResetEmail() : void {
  this.spinner.show();
  this.authService.passwordResetEmail(this.email).subscribe(
    (response) => {
      if (response === "1") {
        this.data.email.email = this.email;
        this.data.step.step = 2;
        this.msg.message = "";
        this.msg.status = false;
      } else if (response === "-1") {
        this.msg.message = "Error:: Invalid email address.";
        this.msg.status = false;
      }
      this.spinner.hide();
    },
    (error) => {
      this.spinner.hide();
      this.msg.message = "Error,Please try again.";
      this.msg.status = false;
    }
  );
}
  login(): void {
    this.spinner.show();
    let payload = {};
    payload["email"] = this.data.email.email;
    payload["password"] = this.password;

    this.authService.login(payload).subscribe(
      (response) => {
        if (response) {
          if (response.ID) {
            localStorage.setItem("u_info", JSON.stringify(response));
            this.router.navigateByUrl(this.returnUrl);
          } else {
            this.msg.message = response.message;
            this.msg.status = false;
          }

          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
    let url = window.encodeURI(this.returnUrl);

    if (url.includes("EnrollCourse")) {
      this.courseGUID = url.split("EnrollCourse/")[1];
    }
  }
}
