import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import {
  Channel,
  Course,
  DataserviceService,
} from "src/app/service/dataservice.service";

@Component({
  selector: "app-page-work-modern",
  templateUrl: "./page-work-modern.component.html",
  styleUrls: ["./page-work-modern.component.css"],
})
export class PageWorkModernComponent implements OnInit {
  channelList: Array<Channel> = [];

  coursesList: Array<Course> = [];

  authorList: Array<any> = [];
  pageIndex: number = 1;
  pageSize: number = 10;

  channelId: number = 0;

  title: string = "";
  channel: Channel = new Channel();
  constructor(
    private dataService: DataserviceService,
    private route: ActivatedRoute,
    private loader:NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.channel = JSON.parse(localStorage.getItem("channel"));
    this.route.params.subscribe((param) => {
      if (param.id) {
        this.channelId = param.id;
      }
    });

    this.route.url.subscribe((url) => {
      let path = url[0].path;
      if (path.includes("channels")) {
        this.title = "Channels";
        this.getChannelList();
      } else if (path.includes("courses")) {
        this.title = "Courses";
        if (this.channelId) {
          this.getCourses(this.channelId);
        } else {
          this.getAllCourses(this.pageIndex, this.pageSize);
        }
      } else if (path.includes("Instructors")) {
        this.title = "Instructors";
        if (this.channelId) {
          this.getAuthors(this.pageIndex, this.pageSize);
        } else {
          this.getAllAuthor(this.pageIndex, this.pageSize);
        }
      }
    });
  }

  getAllAuthor(pageNumber: number, pageSize: number): void {
    this.dataService.getPopularAuthor().subscribe((response) => {
      this.authorList = response;
    });
  }
  getAuthors(pageNumber: number, pageSize: number): void {
    this.dataService
      .getAllAuthor(this.channelId, pageNumber, pageSize)
      .subscribe((response) => {
        this.authorList = response;
      });
  }
  getAllCourses(pageNumber: number, pageSize: number): void {
    this.loader.show();
    this.dataService
      .getPopularCourses(pageNumber, pageSize)
      .subscribe((response) => {
        this.coursesList = response.map((coures) => {
          coures.CourseDescription = coures.CourseDesc;
          coures.CourseLessons = coures.EnrollCount;
          coures.EnrollCnt = coures.EnrollCount;
          coures.web_price = coures.Price;
          return coures;
        });
        this.loader.hide();
      },
      (error)=>{
        this.loader.hide();
      }
      );
  }
  getChannelList(): void {
    this.dataService
      .getChannelList(this.pageIndex, this.pageSize)
      .subscribe((response) => {
        this.channelList = response;
      });
  }

  getCourses(channelId: number): void {
    this.dataService.getCoursesList(channelId, 1, 10).subscribe((response) => {
    
      this.coursesList = response;
    });
  }
  nextPage(): void {
  
    this.pageIndex = this.pageIndex + 1;
    this.callPageOnIndexMethod();
  
  }
  prevPage(): void {
    if (this.pageIndex != 1) {
      this.pageIndex = this.pageIndex - 1;
      this.callPageOnIndexMethod();
    
    }
  }

  callPageOnIndexMethod(): void {
    if (this.title === "Channels") {
      this.getChannelList();
    } else if (this.title === "Courses") {
      if (this.channelId) this.getCourses(this.channelId);
      else this.getAllCourses(this.pageIndex, this.pageSize);
    } else if (this.title.includes("Instructors")) {
      if (this.channelId) {
        this.getAuthors(this.pageIndex, this.pageSize);
      } else {
        this.getAllAuthor(this.pageIndex, this.pageSize);
      }
    }
  }
  onPageIndex(index: number): void {
 
    this.pageIndex = index;
    this.callPageOnIndexMethod();
    
  }

}
