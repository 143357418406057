import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { AuthenticationService } from "src/app/service/authentication.service";
import { CheckoutService } from "src/app/service/checkout.service";
import {
  Course,
  DataserviceService,
  Package,
} from "src/app/service/dataservice.service";
import { GtmService } from "src/app/service/gtm.service";
import { environment } from "src/environments/environment";
import { SwiperOptions } from "swiper";

@Component({
  selector: "app-shop-product-detail",
  templateUrl: "./shop-product-detail.component.html",
  styleUrls: ["./shop-product-detail.component.css"],
})
export class ShopProductDetailComponent implements OnInit {
  model = 1;
  viewUrl: string = environment.viewUrl;
  courseDetailsObject: Course;
  relatedCoursesList: Array<Course> = [];
  courseId: any;
  isCourseAreadyInBag: boolean = false;

  packageDetails: Package;

  isDataLoaded: boolean = false;
  isCoursesLoaded: boolean = false;
  tmpList: Array<any> = [1, 2, 3];
  constructor(
    private dataService: DataserviceService,
    private route: ActivatedRoute,
    public checkoutService: CheckoutService,
    private router: Router,
    private _authService: AuthenticationService,
    private gtmService: GtmService
  ) {}

  public config: SwiperOptions = {
    a11y: { enabled: true },
    direction: "horizontal",
    slidesPerView: 1,
    keyboard: true,
    navigation: true,
    pagination: false,
  };

  redirectToCrDetails(course: Course): void {
    let id = course.CourseId ? course.CourseId : course["CourseID"];
    this.router.navigateByUrl("/details/course/" + id);
  }
  imageUrl(): string {
    if (this.courseDetailsObject)
      return (
        this.viewUrl +
        "/Uploads/Courses/" +
        this.courseDetailsObject.ThumbnailGUID +
        ".jpg"
      );
    else if (this.packageDetails)
      return (
        this.viewUrl +
        "/Uploads/Package/" +
        this.packageDetails.ImageGUID +
        ".jpg"
      );
    else "";
  }
  imageShow(event) {
    const image = event.target.src;
    const expandImg = document.getElementById(
      "expandedImg"
    ) as HTMLImageElement;
    expandImg.src = image;
  }

  ngOnInit(): void {
    this.route.params.subscribe((param) => {
      if (param.id && param.type) {
        this.packageDetails = undefined;
        this.courseDetailsObject = undefined;
        if (param.type === "package") {
          this.getPackageDetailsByID(param.id);
        } else if (param.type === "course") {
          this.courseId = param.id;
          this.getCourseDetailsByID(param.id);
        }
      }
    });

    let localBagCourses: Array<Course> =
      this.checkoutService.getStoredCoursesArray();
    if (localBagCourses.length > 0) {
      let findCurrentcourse = localBagCourses.find((course) => {
        return course.CourseId == this.courseId;
      });
      if (findCurrentcourse) this.isCourseAreadyInBag = true;
    }
  }

  addInCard(course: Course): void {
    if (!this.checkoutService.isCourseAlreadyInBag(course.CourseId)) {
      this.checkoutService.setStoreItem(course);
    }


  this.gtmService.addInCart(course.CourseName,course.CourseId,course.web_price)

  }
  getCourseDetailsByID(courseID: any): void {
    this.dataService.getCourseDetailsByID(courseID).subscribe(
      (response) => {
        this.courseDetailsObject = response[0];

        this.getRelatedCoursesList(courseID);
        this.isDataLoaded = true;
      },
      (error) => {
        this.isDataLoaded = true;
      }
    );
  }

  getRelatedCoursesList(courseID: number): void {
    this.dataService.getCourseRelatedCourses(courseID).subscribe(
      (response) => {
        this.relatedCoursesList = response;
        this.isCoursesLoaded = true;
      },
      (error) => {
        this.isCoursesLoaded = true;
      }
    );
  }

  //package
  getPackageDetailsByID(packageId: number): void {
    this.dataService.getPackageDetails(packageId).subscribe(
      (response) => {
        this.packageDetails = response;
        this.relatedCoursesList = response.Courses;
        this.isDataLoaded = true;
        this.isCoursesLoaded = true;
      },
      (error) => {
        this.isDataLoaded = true;
        this.isCoursesLoaded = true;
      }
    );
  }

  addInPackageInCard(): void {
    if (this.packageDetails) {
      if (!this.checkoutService.isPackageAlreadyInBag(this.packageDetails)) {
        this.checkoutService.addPackageInBag(this.packageDetails);
      }
    }

    this.gtmService.addInCart(this.packageDetails.Name,this.packageDetails.id,this.packageDetails.web_price)
  
  }
}
