import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CountToModule } from 'angular-count-to';
import { NgxMasonryModule } from 'ngx-masonry';
import { ScrollspyDirective } from './shared/scrollspy.directive';
import { IndexAppsComponent } from './core/components/index-apps/index-apps.component';
import { IndexCourseComponent } from './core/components/index-course/index-course.component';
import { IndexPersonalComponent } from './core/components/index-personal/index-personal.component';
import { IndexShopComponent } from './core/components/index-shop/index-shop.component';
import { PageContactDetailComponent } from './core/components/page-contact-detail/page-contact-detail.component';
import { PageContactOneComponent } from './core/components/page-contact-one/page-contact-one.component';
import { PagePrivacyComponent } from './core/components/page-privacy/page-privacy.component';
import { PageWorkModernComponent } from './core/components/page-work-modern/page-work-modern.component';
import { FeatherModule } from 'angular-feather';

import { allIcons } from 'angular-feather/icons';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SwitcherComponent } from './shared/switcher/switcher.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AuthLoginComponent } from './authentication/auth-login/auth-login.component';
import { AuthRePasswordComponent } from './authentication/auth-re-password/auth-re-password.component';
import { AuthSignupComponent } from './authentication/auth-signup/auth-signup.component';
import { AuthentincationComponent } from './authentication/authentincation/authentincation.component';
import { HomeComponent } from './core/home/home.component';
import { SharedModule } from './shared/shared/shared.module';
import { AuthOptVerificicationComponent } from './authentication/auth-opt-verificication/auth-opt-verificication.component';
import { NgxLocalStorageModule } from 'ngx-localstorage';
import { AuthInterceptor } from './guard/interpreter';
import { MatIconModule } from '@angular/material/icon';
import { CourseToBuyComponent } from './authentication/course-to-buy/course-to-buy.component';
import {
  MatSnackBarModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from '@angular/material/snack-bar';
import { AccountWorksComponent } from './core/components/account-works/account-works.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ShopProductDetailComponent } from './core/components/shop-product-detail/shop-product-detail.component';
import { MatDialogModule } from '@angular/material/dialog';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ViewAllPackagesComponent } from './core/components/view-all-packages/view-all-packages.component';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { StreamComponent } from './core/components/stream/stream.component';
import { DatePipe } from '@angular/common';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
};

const YOUR_GTM_ID = 'GTM-M5F82HS';
@NgModule({
  declarations: [
    AppComponent,
    IndexAppsComponent,
    IndexCourseComponent,
    IndexPersonalComponent,
    IndexShopComponent,
    PageContactDetailComponent,
    PageContactOneComponent,
    PagePrivacyComponent,
    PageWorkModernComponent,
    SwitcherComponent,
    ScrollspyDirective,
    AuthLoginComponent,
    AuthRePasswordComponent,
    AuthSignupComponent,
    AuthentincationComponent,
    HomeComponent,
    AuthOptVerificicationComponent,
    CourseToBuyComponent,
    AccountWorksComponent,
    ShopProductDetailComponent,
    ViewAllPackagesComponent,
    StreamComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    FeatherModule.pick(allIcons),
    ScrollToModule.forRoot(),
    RouterModule.forRoot([], { relativeLinkResolution: 'legacy' }),
    NgxYoutubePlayerModule,
    NgbModule,
    NgbNavModule,
    FormsModule,
    SwiperModule,
    NgxTypedJsModule,
    FlatpickrModule.forRoot(),
    CountToModule,
    NgxMasonryModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    SharedModule,
    NgxLocalStorageModule.forRoot(),
    MatIconModule,
    MatSnackBarModule,
    MatTabsModule,
    ScrollingModule,
    NgxSkeletonLoaderModule,
    MatDialogModule,
    GoogleTagManagerModule.forRoot({
      id: YOUR_GTM_ID,
    }),
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
  ],
  exports: [FeatherModule, ScrollspyDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [PagePrivacyComponent],
  providers: [
  DatePipe,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2000 } },
    { provide: 'googleTagManagerId', useValue: YOUR_GTM_ID },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
