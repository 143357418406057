import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { DataserviceService } from "src/app/service/dataservice.service";

@Component({
  selector: "app-page-contact-one",
  templateUrl: "./page-contact-one.component.html",
  styleUrls: ["./page-contact-one.component.css"],
})
export class PageContactOneComponent implements OnInit {
  contactForm: FormGroup;
  apiResponse: any;
  constructor(
    private dataService: DataserviceService,
    private router: Router,
    private loader: NgxSpinnerService
  ) {
    this.contactForm = new FormGroup({
      Name: new FormControl("", Validators.required),
      Email: new FormControl("", Validators.required),
      Subject: new FormControl("", Validators.required),
      Message: new FormControl("", [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(500),
      ]),
    });
  }

  submitForm(): void {
    this.loader.show();
    if (this.contactForm.valid) {
      this.dataService.contactUsPost(this.contactForm.value).subscribe(
        (response) => {
          this.loader.hide();
          this.apiResponse = response;
          this.contactForm.reset();
        },
        (error) => {
          this.loader.hide();
        }
      );
    }
  }

  ngOnInit(): void {}
}
