<div class="container stream-content" style="margin-top: 4%;">
  <!-- <h2 class="stream-title">
    AHRK Workshop #1 - 10am PST, Saturday, March 26
  </h2> -->

  <section class="section" id="series">
    <div class="container">
      <h4 class="title mb-4 font-weight-bold text-center">Workshops</h4>

      <div
        class="worksho-container border border-primary rounded-sm shadow p-2"
        *ngFor="let shop of workShopList"
      >
        <div class="row" style="align-items: center;">
          <div class="col-sm-12 col-md-5 col-lg-5">
            <img
              [src]="
                viewUrl + '/Uploads/Courses/' + shop.image + '.jpg'
              "
              class="img-fluid rounded-top mx-auto wrk-img"
              alt=""
            />
          </div>
          <div class="col-sm-12 col-md-7 col-lg-7">
            <div>
              <h1 class="font-weight-bold">
                {{ shop.title }}
              </h1>
              <h5>
                {{ shop.Description }}
              </h5>

              <div class="row" *ngIf="!authService.isLoggednIn()">
                <div class="col-md-5 col-lg-5 col-sm-12">
                  <!-- <button
                    class="btn btn-primary mt-3"
                    *ngIf="!authService.isLoggednIn()"
                    (click)="addInCard(shop)"
                  >
                    <h5 style="margin-bottom: unset;" class="font-weight-bold">
                      ENROLL NOW {{ shop.Price |currency:"USD":"symbol" }}
                    </h5>
                  </button> -->
                  <button
                    class="btn btn-primary mt-3"
                    (click)="redirecToSignUp()"
                  >
                    <h5 style="margin-bottom: unset;" class="font-weight-bold">
                      Signup to Watch it
                    </h5>
                  </button>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                  <div
                    class="timer-div-container mt-1 text-primary mt-3"
                    *ngIf="0<=_days && 0<=_hours && 0<=_minutes && 0<=_seconds;else startWthRef"
                  >
                    <div class="tmr-text">Time left:</div>
                    <div
                      class="tmr-dgit shadow-sm border border-default rounded"
                    >
                      {{_days}}
                    </div>
                    <div class="tmr-dgit">:</div>
                    <div
                      class="tmr-dgit shadow-sm border border-default rounded"
                    >
                      {{_hours}}
                    </div>
                    <div class="tmr-dgit">:</div>
                    <div
                      class="tmr-dgit shadow-sm border border-default rounded"
                    >
                      {{_minutes}}
                    </div>
                    <div class="tmr-dgit">:</div>
                    <div
                      class="tmr-dgit shadow-sm border border-default rounded"
                    >
                      {{_seconds}}
                    </div>
                    <div></div>
                    <div class="tmr-text">Days</div>
                    <div></div>
                    <div class="tmr-text">Hours</div>
                    <div></div>
                    <div class="tmr-text">Minutes</div>
                    <div></div>
                    <div class="tmr-text">Seconds</div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="authService.isLoggednIn()">
                <div class="col-md-8">
                  <div class="text-primary">
                    <div *ngIf="isNotToday;" class="mt-2 font-weight-bold">
                      {{publishDate | date:'shortTime'}} {{ publishDate |
                      date:'fullDate'}} PST
                    </div>
                    <div *ngIf="!isNotToday">
                      <div
                        class="timer-div-container mt-1"
                        *ngIf="0<=_days && 0<=_hours && 0<=_minutes && 0<=_seconds;else startWthRef"
                      >
                        <div class="tmr-text">Time left:</div>
                        <div
                          class="tmr-dgit shadow-sm border border-default rounded"
                        >
                          {{_days}}
                        </div>
                        <div class="tmr-dgit">:</div>
                        <div
                          class="tmr-dgit shadow-sm border border-default rounded"
                        >
                          {{_hours}}
                        </div>
                        <div class="tmr-dgit">:</div>
                        <div
                          class="tmr-dgit shadow-sm border border-default rounded"
                        >
                          {{_minutes}}
                        </div>
                        <div class="tmr-dgit">:</div>
                        <div
                          class="tmr-dgit shadow-sm border border-default rounded"
                        >
                          {{_seconds}}
                        </div>
                        <div></div>
                        <div class="tmr-text">Days</div>
                        <div></div>
                        <div class="tmr-text">Hours</div>
                        <div></div>
                        <div class="tmr-text">Minutes</div>
                        <div></div>
                        <div class="tmr-text">Seconds</div>
                      </div>
                      <ng-template #startWthRef>
                        <div class="mt-2">
                          <button
                            class="btn btn-primary mt-3"
                            title="Start watching"
                            (click)="addInCard()"
                          >
                            <h5
                              style="margin-bottom: unset;"
                              class="font-weight-bold"
                            >
                              Start Watching
                            </h5>
                          </button>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <div class="col-md-4"></div>
              </div>
              <div *ngIf="errorMsg!=''" class="alert alert-info mt-3">
                {{ errorMsg }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="!workShopList.length">
        <div class="col-md-5 col-lg-5 col-sm-12">
          <ngx-skeleton-loader
            animation="progress"
            [theme]="{
                'border-radius': '0px',
                height: '22rem',
                'background-color': '#E7F5F4'
              }"
          ></ngx-skeleton-loader>
        </div>
        <div class="col-md-7 col-lg-7 col-sm-12">
          <ngx-skeleton-loader
            animation="progress"
            [theme]="{
                'border-radius': '0px',
                height: '22rem',
                'background-color': '#E7F5F4'
              }"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </section>

  <!-- <div style="padding: 56.25% 0 0 0; position: relative;">
    <iframe
      src="https://player.vimeo.com/video/692117063?h=6ebc6c45ee
    "
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
    ></iframe>
  </div>
  <iframe
   src="https://vimeo.com/live-chat/692117063/"
    style="border: 0;"
    webkitallowfullscreen
    allowfullscreen
    frameborder="no"
    width="320"
    height="437"
    referrerpolicy="no-referrer-when-downgrade"
  ></iframe>

  <div class="mt-3">
    <h4><b>Schedule</b></h4>
    <table class="table table-bordered">
      <tbody>
        <tr>
          <td>
            <b> Session 1</b>
          </td>
          <td>Be the <b>hero</b> of your story Session</td>
        </tr>
        <tr>
          <td>
            <b> Session 2</b>
          </td>
          <td>Discover your <b>VIP</b> and write your next chapter PANEL</td>
        </tr>
        <tr>
          <td>
            <b> Session 3</b>
          </td>
          <td>Break free from <b>BED</b> Session</td>
        </tr>
        <tr>
          <td>
            <b> Session 4</b>
          </td>
          <td>The AHRK pathway as a way of life <b>PANEL</b></td>
        </tr>
        <tr>
          <td>
            <b> Session 5</b>
          </td>
          <td>Journey through the <b>5 lenses of CirclesNow</b> for victory</td>
        </tr>
        <tr>
          <td>
            <b> Session 6</b>
          </td>
          <td>Seeing your next steps clearly</td>
        </tr>
      </tbody>
    </table>
  </div> -->
</div>
