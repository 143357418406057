import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { interval, Observable, Subject } from "rxjs";
import { take, toArray } from "rxjs/operators";
import {
  AuthenticationService,
  UserDetails,
} from "src/app/service/authentication.service";
import {
  Course,
  DataserviceService,
} from "src/app/service/dataservice.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-account-works",
  templateUrl: "./account-works.component.html",
  styleUrls: ["./account-works.component.css"],
})
export class AccountWorksComponent implements OnInit {
  navClass = "nav-light";

  userDetails: UserDetails;

  courseList: Array<Course> = [];
  workShopList: Array<Course> = [];

  tmpCourseList: Array<Course> = [];
  seriesList: Array<any> = [];

  viewUrl: string = environment.viewUrl;
  editProfile: boolean = false;
  userform: FormGroup;

  courseObsrv: any = new Subject();
  coursePageNumber: any;

  short_name: string;

  tmpSeriesList: Array<any> = [];

  seriesPageNumber: any;

  tmpArray: Array<any> = [1, 2, 3, 4];

  errorMsg: string = "";
  _diff: any;
  timer: any;
  _days: any = 0;
  _hours: any = 0;
  _minutes: any = 0;
  _seconds: any = 3;
  courseIntrDetails: Course;
  isWorkShopLoaded: boolean = false;
  constructor(
    private authService: AuthenticationService,
    private dataService: DataserviceService,
    private loader: NgxSpinnerService,
    private datePipe: DatePipe
  ) {
    this.userform = new FormGroup({
      firstname: new FormControl("", Validators.required),
      lastname: new FormControl("", Validators.required),
    });
  }

  getSPDate(payload: string, callBack) {
    this.dataService.getSPData(payload).subscribe(
      (response) => {
        callBack(response);
      },
      (error) => {
        callBack([]);
      }
    );
  }
  isNotToday: boolean = false;
  publishDate: any = "";
  getWorkShopList() {
    this.getSPDate(`getContent @courseType=4`, (result) => {
      let workstmpList = result["Table"];
      if (workstmpList.length) {
        this.getCourseDetails(workstmpList[0].CourseId, workstmpList);
        let publishDateResp = workstmpList[0].PublishDate
          ? workstmpList[0].PublishDate
          : new Date();
        this.publishDate = publishDateResp;

        this.timer = interval(1000).subscribe((x) => {
          let fr_publishDate: any = this.datePipe.transform(
            publishDateResp, //
            "yyyy-MM-dd, h:mm:ss a"
          );
          let fr_currentDate: any = this.datePipe.transform(
            new Date().toString(),
            "yyyy-MM-dd, h:mm:ss a"
          );

          let publishDate: any = Date.parse(
            new Date(fr_publishDate).toString()
          );

          let currentDate: any = Date.parse(
            new Date(fr_currentDate).toLocaleString("en-US", {
              timeZone: "America/Los_Angeles",
            })
          );
          if (
            new Date(publishDateResp).getDate() !=
            new Date(currentDate).getDate()
          )
            this.isNotToday = true;
          else this.isNotToday = false;

          // console.log(
          //   new Date(publishDateResp).getDate(),
          //   "p",
          //   new Date(currentDate).getDate()
          // );

          // console.log(
          //   "publishDate",
          //   this.datePipe.transform(publishDate, "yyyy-MM-dd, h:mm:ss a"),
          //   "currentDate",
          //   this.datePipe.transform(currentDate, "yyyy-MM-dd, h:mm:ss a")
          // );
          this._diff = publishDate - currentDate;
          this._days = this.getDays(this._diff);
          this._days =
            this._days.toString().length === 1 ? "0" + this._days : this._days;
          this._hours = this.getHours(this._diff);
          this._hours =
            this._hours.toString().length === 1
              ? "0" + this._hours
              : this._hours;
          this._minutes = this.getMinutes(this._diff);
          this._minutes =
            this._minutes.toString().length === 1
              ? "0" + this._minutes
              : this._minutes;
          this._seconds = this.getSeconds(this._diff);
          this._seconds =
            this._seconds.toString().length === 1
              ? "0" + this._seconds
              : this._seconds;
        });
      }
    });
  }
  getCourseDetails(courseId: any, list: Array<any> = []) {
    if (!courseId) return;
    if (this.authService.isLoggednIn()) {
      this.dataService
        .getCourseDetailsByID(courseId, this.authService.getUserInfo().ID)
        .subscribe((response) => {
          this.courseIntrDetails = response[0];
          if (this.courseIntrDetails.IsEnrolled) {
            this.workShopList = list;
          }
          this.isWorkShopLoaded = true;
        });
    }
  }
  getDays(t) {
    return Math.floor(t / (1000 * 60 * 60 * 24));
  }

  getHours(t) {
    return Math.floor((t / (1000 * 60 * 60)) % 24);
  }

  getMinutes(t) {
    return Math.floor((t / 1000 / 60) % 60);
  }

  getSeconds(t) {
    return Math.floor((t / 1000) % 60);
  }

  submitForm(): void {
    this.loader.show();
    if (this.userform.valid) {
      let payload = {};
      payload = this.userform.value;
      payload["ID"] = this.authService.getUserInfo().ID;
      this.authService.updateUserDetails(payload).subscribe(
        (response) => {
          this.loader.hide();
          this.getUserDetails();
          this.editProfile = false;
        },
        (error) => {
          this.loader.hide();
        }
      );
    }
  }
  getUserDetails(): void {
    this.authService.getUserDetail().subscribe(
      (response) => {
        this.userDetails = response;

        let u_info = {
          firstname: this.userDetails.firstname,
          lastname: this.userDetails.lastname,
        };
        this.short_name =
          this.userDetails.firstname.charAt(0) +
          this.userDetails.lastname.charAt(0);
        this.short_name = this.short_name.toLocaleUpperCase();
        this.userform.setValue(u_info);
        this.getEnrolledCourses();
      },
      (error) => {
        this.getEnrolledCourses();
      }
    );
  }

  courseIndex: number = 0;
  prevPageCourse(): void {
    if (this.courseIndex > 0) {
      this.courseIndex--;
      this.calCourseIndex();
    }
  }
  onPageIndexCourse(index): void {
    this.courseIndex = index;
    this.calCourseIndex();
  }
  nextPageCourse(): void {
    this.courseIndex++;
    this.calCourseIndex();
  }
  calCourseIndex(): void {
    let startIndex = this.courseIndex * 9;
    let endIndex = this.courseIndex * 9 + 9;
    this.tmpCourseList = this.courseList.slice(startIndex, endIndex);
  }

  isCoursesLoaded: boolean = false;
  getEnrolledCourses(): void {
    this.dataService.getEnrolledCourse().subscribe(
      (response) => {
        this.isCoursesLoaded = true;
        this.courseList = response;
        this.getWorkShopList();
        if (this.courseList.length > 9) {
          let number = Math.floor(this.courseList.length / 9);
          this.coursePageNumber = interval(0).pipe(take(number + 1), toArray());
          this.tmpCourseList = this.courseList.slice(0, 9);
        } else {
          this.tmpCourseList = response;
        }
        this.getEnrolledSeries();
      },
      (error) => {
        this.isCoursesLoaded = true;
      }
    );
  }

  getEnrolledSeries(): void {
    this.dataService.getEnrolledPackage().subscribe((response) => {
      this.seriesList = response;
      if (this.seriesList.length > 9) {
        let number = Math.floor(this.seriesList.length / 9);
        this.seriesPageNumber = interval(0).pipe(take(number + 1), toArray());
        this.tmpSeriesList = this.seriesList.slice(0, 9);
      } else {
        this.tmpSeriesList = response;
      }
    });
  }

  seriesIndex: number = 0;
  prevPageSeries(): void {
    if (this.seriesIndex > 0) {
      this.seriesIndex--;
    }
  }
  onPageIndexSeries(index): void {
    this.seriesIndex = index;
    this.calIndexSeries();
  }

  nextPageSeries(): void {
    this.seriesIndex++;
    this.calIndexSeries();
  }
  calIndexSeries(): void {
    let startIndex = this.seriesIndex * 9;
    let endIndex = this.seriesIndex * 9 + 9;
    this.tmpSeriesList = this.seriesList.slice(startIndex, endIndex);
  }
  ngOnInit(): void {
    this.getUserDetails();
  }
}
