<div class="container mt-100 mt-60">
  <div class="row">
    <div class="col-12">
      <h5 class="mb-0">Top Categories</h5>
    </div>
    <!--end col-->
  </div>
  <!--end row-->

  <div class="row">
    <div class="col-lg-2 col-md-4 col-6 mt-4 pt-2" *ngFor="let cat of category">
      <div class="card explore-feature border-0 rounded text-center bg-white">
        <div class="card-body">
          <div
            class="icon rounded-circle shadow-lg d-inline-block h2"
            [style]="'background-color:' + cat.ColorCode"
          >
          </div>
          <div class="content mt-3">
            <h6 class="mb-0">
              <a href="javascript:void(0)" class="title text-dark">
                {{ cat.CategoryName }}
              </a>
            </h6>
          </div>
        </div>
      </div>
    </div>
    <!--end col-->

    <!--end col-->
  </div>
  <!--end row-->
</div>
