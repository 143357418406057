<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title" *ngIf="courseDetailsObject || packageDetails">
            {{
              courseDetailsObject
                ? courseDetailsObject.CourseName
                : packageDetails
                ? packageDetails.Name
                : ""
            }}
          </h4>
          <h4 *ngIf="!courseDetailsObject || !packageDetails"></h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item">
                  <a routerLink="/">Circlesnow</a>
                </li>
                <li class="breadcrumb-item course-title">
                  <a *ngIf="courseDetailsObject || packageDetails">
                    {{
                      courseDetailsObject
                        ? " course name"
                        : packageDetails
                        ? "package name"
                        : ""
                    }}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
        fill="currentColor"
      ></path>
    </svg>
  </div>
</div>

<section class="section">
  <!-- skeleton loader -->
  <div class="container" *ngIf="!isDataLoaded">
    <div class="row">
      <div class="col-md-5">
        <ngx-skeleton-loader
          animation="progress"
          [theme]="{
            'border-radius': '0px',
            height: '16rem',
            'background-color': '#E7F5F4'
          }"
        ></ngx-skeleton-loader>
      </div>

      <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <ngx-skeleton-loader
          animation="progress"
          count="4"
          [theme]="{
            'border-radius': '0px',
            height: '1rem',
            'background-color': '#E7F5F4'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  </div>

  <div
    class="container"
    *ngIf="(courseDetailsObject || packageDetails) && isDataLoaded"
  >
    <div class="row">
      <div class="col-md-5 col-sm-12 col-lg-4">
        <img
          [src]="imageUrl()"
          class="img-fluid rounded-top mx-auto course-main-image w-100"
          alt=""
        />
      </div>
      <!--end col-->

      <div class="col-md-7 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ml-md-4">
          <h4 class="title course-title">
            {{
              courseDetailsObject
                ? courseDetailsObject.CourseName
                : packageDetails
                ? packageDetails.Name
                : ""
            }}
          </h4>
          <h4>
            {{ courseDetailsObject ? courseDetailsObject.ChannelName : "" }}
          </h4>
          <h4 class="text-muted font-weight-bold">
            $
            {{
              courseDetailsObject
                ? courseDetailsObject.web_price
                : packageDetails
                ? packageDetails.web_price
                : ""
            }}
          </h4>

          <h6 class="mt-4 py-2">Description :</h6>
          <p class="text-muted">
            {{
              courseDetailsObject
                ? courseDetailsObject.Description
                : packageDetails
                ? packageDetails.Description
                : ""
            }}
          </p>

          <div class="row mt-4 pt-2"></div>
          <!--end row-->

          <div class="mt-4 pt-2">
            <button
              *ngIf="courseDetailsObject"
              class="btn btn-primary ml-2"
              [disabled]="
                checkoutService.isCourseAlreadyInBag(
                  courseDetailsObject.CourseId
                )
              "
              (click)="addInCard(courseDetailsObject)"
              title="{{
                !checkoutService.isCourseAlreadyInBag(
                  courseDetailsObject.CourseId
                )
                  ? 'Add in cart.'
                  : 'Already added to cart.'
              }}"
            >
              Add to Cart
            </button>
            
            <button
              *ngIf="packageDetails"
              class="btn btn-primary ml-2"
              (click)="addInPackageInCard()"
              [disabled]="checkoutService.isPackageAlreadyInBag(packageDetails)"
              title="{{
                !checkoutService.isPackageAlreadyInBag(packageDetails)
                  ? 'Add in cart.'
                  : 'Already added to cart.'
              }}"
            >
              Add to Cart
            </button>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>

  <div class="container mt-100 mt-60">
    <div class="w-100">
      <h5 class="mb-0">
        {{
          courseDetailsObject
            ? " Related courses"
            : packageDetails
            ? "Courses"
            : ""
        }}
      </h5>
    </div>

    <div class="flex-courses">
      <!--end col-->

      <div
        *ngFor="let relCourses of relatedCoursesList"
        class="flex-courses-item"
      >
        <div
          class="
            card
            shop-list
            border-0
            position-relative
            overflow-hidden
            m-2
            pointer
          "
        >
          <div
            class="shop-image position-relative overflow-hidden rounded shadow"
          >
            <a (click)="redirectToCrDetails(relCourses)">
              <img
                [src]="
                  viewUrl +
                  '/Uploads/Courses/' +
                  relCourses.ThumbnailGUID +
                  '.jpg'
                "
                class="img-fluid"
                alt=""
            /></a>
            <a (click)="redirectToCrDetails(relCourses)" class="overlay-work">
              <img
                [src]="
                  viewUrl +
                  '/Uploads/Courses/' +
                  relCourses.ThumbnailGUID +
                  '.jpg'
                "
                class="img-fluid"
                alt=""
              />
            </a>
          </div>
          <div class="card-body content pt-4 p-2">
            <a class="text-dark product-name h6">
              {{ relCourses.CourseName }}</a
            >
            <div class="d-flex justify-content-between mt-1">
              <h6 class="text-muted small font-italic mb-0 mt-1">
                <span class="h6">$</span> {{ relCourses.web_price }}
              </h6>
            </div>
          </div>
        </div>
      </div>

      <!--end col-->
    </div>

    <div class="row" *ngIf="relatedCoursesList.length <= 0 && !isCoursesLoaded">
      <div class="col-md-4 col-lg-4" *ngFor="let tmp of tmpList">
        <div class="card border-white">
          <div class="card-body">
            <ngx-skeleton-loader
              animation="progress"
              [theme]="{
                'border-radius': '0px',
                height: '16rem',
                'background-color': '#E7F5F4 '
              }"
            ></ngx-skeleton-loader>
            <ngx-skeleton-loader
              animation="progress"
              count="2"
              [theme]="{
                'border-radius': '0px',
                height: '1rem',
                'background-color': '#E7F5F4 '
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
</section>
