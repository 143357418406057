import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  title = "landrick-angular";
  addclass: string = "nav-light";
  navClass = "nav-light";
  /**
   * Router activation
   */
  onActivate(componentReference: any) {
    this.addclass = componentReference.navClass;
  }
  constructor(private router: Router) {
    /**
     * Unicons icon refreshed on route change.
     */
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        window["Unicons"]["refresh"]();
      }
    });

    this.onChangeColor("");
  }
  onChangeColor(color: string) {
    document
      .getElementById("color-opt")
      .setAttribute("href", "./assets/css/colors/" + "skyblue" + ".css");
  }

  ngOnInit(): void {}
}
