<!-- Hero Start -->
<section class="bg-home d-flex align-items-center">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6">
        <div class="mr-lg-5" *ngIf="!courseGUID">
          <img
            src="assets/images/user/signup.svg"
            class="img-fluid d-block mx-auto"
            alt=""
          />
        </div>
        <div *ngIf="courseGUID">
          <app-course-to-buy [courseGUID]="courseGUID"></app-course-to-buy>
        </div>
      </div>
      <div class="col-lg-5 col-md-6">
        <div class="card login_page shadow rounded border-0">
          <div class="card-body">
            <h4 class="card-title text-center">Signup</h4>

            <div class="row">
              <div class="col-lg-12">
                <div
                  class="alert alert-success"
                  *ngIf="msg.status && msg.message != ''"
                >
                  {{ msg.message }}
                </div>
                <div
                  class="alert alert-danger"
                  *ngIf="!msg.status && msg.message != ''"
                >
                  {{ msg.message }}
                </div>
              </div>
            </div>
            <div *ngIf="msg.step === 2">
              <div class="row">
                <div class="col-lg-12">
                  <p class="text-muted">
                    Please enter OTP that we have sent on your email address.
                  </p>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Enter OTP</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter OTP"
                      [(ngModel)]="OTP"
                      name="OTP"
                    />
                  </div>
                </div>

                <div class="col-lg-12 col-md-12">
                  <button
                    class="btn btn-primary btn-block"
                    (click)="verifyOTP()"
                  >
                    Submit
                  </button>

                  <div class="mx-auto w-100">
                    <p class="mb-0 mt-3 float-right">
                      <a
                        (click)="sendOTP()"
                        class="text-info signIn font-weight-bold"
                        >Resend OTP</a
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="msg.step === 1">
              <form
                [formGroup]="form"
                (ngSubmit)="submit()"
                class="login-form mt-4"
              >
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group position-relative">
                      <label
                        >First name <span class="text-danger">*</span></label
                      >
                      <i-feather
                        name="user"
                        class="fea icon-sm icons"
                      ></i-feather>
                      <input
                        [formControl]="firstname"
                        type="text"
                        class="form-control pl-5"
                        placeholder="First Name"
                        name="s"
                        required=""
                        autofocus
                      />
                      <span class="help-block" *ngIf="isSubmitingForm">
                        <label
                          class="text-danger"
                          *ngIf="form.get('firstname').hasError('required')"
                          >First Name is required</label
                        >
                        <label
                          class="text-danger"
                          *ngIf="form.get('firstname').hasError('minlength')"
                          >Minimum of 3 characters</label
                        >
                      </span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group position-relative">
                      <label
                        >Last name <span class="text-danger">*</span></label
                      >
                      <i-feather
                        name="user-check"
                        class="fea icon-sm icons"
                      ></i-feather>
                      <input
                        type="text"
                        [formControl]="lastname"
                        class="form-control pl-5"
                        placeholder="Last Name"
                        name="s"
                        required=""
                      />
                      <span class="help-block" *ngIf="isSubmitingForm">
                        <label
                          class="text-danger"
                          *ngIf="form.get('lastname').hasError('required')"
                          >Last Name is required</label
                        >
                        <label
                          class="text-danger"
                          *ngIf="form.get('lastname').hasError('minlength')"
                          >Minimum of 3 characters</label
                        >
                      </span>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group position-relative">
                      <label
                        >Your Email <span class="text-danger">*</span></label
                      >
                      <i-feather
                        name="mail"
                        class="fea icon-sm icons"
                      ></i-feather>
                      <input
                        type="email"
                        [formControl]="email"
                        class="form-control pl-5"
                        placeholder="Email"
                        name="email"
                        required=""
                      />
                      <span class="help-block" *ngIf="isSubmitingForm">
                        <label
                          class="text-danger"
                          *ngIf="form.get('email').hasError('required')"
                          >Email is required</label
                        >
                        <label
                          class="text-danger"
                          *ngIf="form.get('email').hasError('invalidEmail')"
                          >Invalid email address</label
                        >
                      </span>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group position-relative">
                      <label
                        >Confirm Email <span class="text-danger">*</span></label
                      >
                      <i-feather
                        name="mail"
                        class="fea icon-sm icons"
                      ></i-feather>
                      <input
                        type="email"
                        [formControl]="confirmEmail"
                        class="form-control pl-5"
                        placeholder="Confirm email"
                        name="confirmEmail"
                        required=""
                      />
                      <span class="help-block">
                        <label
                          class="text-danger"
                          *ngIf="form.get('email').hasError('required')"
                          >Email is required</label
                        >

                        <label
                          class="text-danger"
                          *ngIf="
                            !isValidBothEmail &&
                            !form.get('email').hasError('required')
                          "
                          >Cofirm email do not match.</label
                        >
                      </span>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group position-relative">
                      <label>
                        Password <span class="text-danger">*</span></label
                      >
                      <i-feather
                        name="key"
                        class="fea icon-sm icons"
                      ></i-feather>
                      <input
                        type="password"
                        class="form-control pl-5"
                        placeholder="Password"
                        required=""
                        [formControl]="password"
                      />
                      <span class="help-block" *ngIf="isSubmitingForm">
                        <label
                          class="text-danger"
                          *ngIf="form.get('password').hasError('required')"
                          >Password is required</label
                        >
                        <label
                          class="text-danger"
                          *ngIf="form.get('password').hasError('minlength')"
                          >Password isn't long enough, minimum of 6
                          characters</label
                        >
                      </span>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group position-relative">
                      <label
                        >Confirm Password
                        <span class="text-danger">*</span></label
                      >
                      <i-feather
                        name="key"
                        class="fea icon-sm icons"
                      ></i-feather>
                      <input
                        type="password"
                        class="form-control pl-5"
                        placeholder="Password"
                        required=""
                        [formControl]="confirmPassword"
                      />
                      <span class="help-block">
                        <label
                          class="text-danger"
                          *ngIf="
                            form.get('confirmPassword').hasError('required')
                          "
                          >Confirm Password is required</label
                        >
                        <label
                          class="text-danger"
                          *ngIf="
                            form
                              .get('confirmPassword')
                              .hasError('mismatchedPasswords')
                          "
                          >Passwords do not match</label
                        >
                      </span>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck1"
                          formControlName="terCondition"
                        />
                        <label class="custom-control-label" for="customCheck1"
                          >I Accept
                          <a class="text-info pointer"
                            >Terms And Condition</a
                          ></label
                        >
                      </div>
                      <label
                        class="text-danger"
                        *ngIf="
                          form.get('terCondition').hasError('required') &&
                          isSubmitingForm
                        "
                        >Please select accept terms and condition</label
                      >
                    </div>
                  </div>
                  <div class="col-md-12">
                    <button class="btn btn-primary btn-block">Register</button>
                  </div>

                  <div class="mx-auto">
                    <p class="mb-0 mt-3">
                      <small class="text-dark mr-2"
                        >Already have an account?</small
                      >
                      <a
                        (click)="redirectToSignIn()"
                        class="text-info signIn font-weight-bold"
                        >Sign in</a
                      >
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->
