import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CheckoutService } from "src/app/service/checkout.service";
import { Course } from "src/app/service/dataservice.service";
import { environment } from "src/environments/environment";
import { MatSnackBar } from "@angular/material/snack-bar";
@Component({
  selector: "app-courses-list",
  templateUrl: "./courses-list.component.html",
  styleUrls: ["./courses-list.component.css"],
})
export class CoursesListComponent implements OnInit {
  @Input() courses: Array<Course> = [];
  viewUrl: string = environment.viewUrl;
  cartCourseList: Array<Course> = [];
  cartCourseId: Array<any> = [];
  @Input() isCoursesLoaded: boolean = false;
  tmpSkeletonList: Array<any> = [1, 2, 3, 4, 5, 5, 6, 7, 7];
  constructor(
    private router: Router,
    private _checkoutServ: CheckoutService,
    private _snackBar: MatSnackBar
  ) {
    this.getCartCourses();
  }

  openSnackBar(msg: string) {
    this._snackBar.open(msg, "Cart", {
      horizontalPosition: "end",
      verticalPosition: "bottom",
    });
  }

  getCartCourses(): void {
    this.cartCourseList = this._checkoutServ.getStoredCoursesArray();
    this.cartCourseId = this.cartCourseList.map((cr) => cr.CourseId);
  }

  ngOnInit(): void {
    this._checkoutServ.refresStoreItem();
  }

  redirectToViewMoreCourse(): void {
    this.router.navigateByUrl("/courses");
  }

  redirectToCourseDetails(course: Course): void {
    if (course.CourseId)
      this.router.navigateByUrl("/details/course/" + course.CourseId);
  }
}
