<div class="flex-package" *ngIf="isPackageLoaded">
  <div
    style="cursor: pointer"
    *ngFor="let package of _packagetList"
    (click)="redirectDetailsPage(package)"
  >
    <div class="card blog rounded border-0 shadow overflow-hidden">
      <div class="position-relative">
        <img
          [src]="viewUrl + '/Uploads/Package/' + package.ImageGUID"
          class="card-img-top"
          alt=""
        />
        <div class="overlay rounded-top bg-dark"></div>
      </div>
      <div class="card-body content">
        <h5>
          <a href="javascript:void(0)" class="card-title title text-dark">
            {{ package.Name }}
          </a>
        </h5>
        <div class="post-meta d-flex justify-content-between mt-3">
          <a
            (click)="redirectDetailsPage(package)"
            class="text-muted readmore"
            style="cursor: pointer"
            >Read More <i class="mdi mdi-chevron-right"></i
          ></a>
        </div>
      </div>
      <div class="author">
        <div class="text-light date">
          <i class="mdi mdi-calendar-check"></i>
          {{ package.CreatedDate | date: "MM/dd/yyyy" }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="flex-package" *ngIf="!isPackageLoaded">
  <div class="mt-4 pt-2" *ngFor="let package of tmpLoadingList">
    <div class="shadow">
      <ngx-skeleton-loader
        animation="progress"
        [theme]="{
          'border-radius': '0px',
          height: '14rem',
          'background-color': '#E7F5F4'
        }"
      ></ngx-skeleton-loader>
      <div class="w-100">
        <ngx-skeleton-loader
          count="2"
          [theme]="{
            'border-radius': '0px',
            height: '15px',
            'background-color': '#E7F5F4'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</div>
