import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { interval } from "rxjs";
import { AuthenticationService } from "src/app/service/authentication.service";
import { CheckoutService, Detailstype } from "src/app/service/checkout.service";
import { Course, Package } from "src/app/service/dataservice.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  @Input() navClass: string;
  shopingBagItem: Array<any> = [];
  viewUrl: string = environment.viewUrl;
  urlPathName: string = "";
  totalPrice: any = 0;
  viewModalcount = 0;
  viewBagPopup: boolean = false;
  intrId: any;

  detailsType:Detailstype = new Detailstype();
  constructor(
    private router: Router,
    public authService: AuthenticationService,
    private _checkoutServ: CheckoutService,
    private route: ActivatedRoute
  ) {
    this._checkoutServ.getStoreItem().subscribe((item) => {
      if (item.length < this.shopingBagItem.length) {
        this.shopingBagItem = [];
        this.shopingBagItem = item;
        if (this.shopingBagItem.length)
          this.calCulateTotal();
      } else {
        this.shopingBagItem = [];
        this.shopingBagItem = item;
        if (this.shopingBagItem.length)
          this.calCulateTotal();
        if (this.viewModalcount > 0) {
          this.viewBagPopup = true;
          this.setInterValToBagModal();
        }
        ++this.viewModalcount;
      }
    });

    router.events.subscribe((event: any) => {
      this.urlPathName = window.location.pathname;
      this.calCulateTotal();
    });
  }
  isCondensed = false;

  calCulateTotal(): void {
    
    let courseItem:Array<Course> = this._checkoutServ.getStoredCoursesArray();
    let packageItem:Array<Package> = this._checkoutServ.getStoredPackgeArray();

    this.totalPrice = 0;

    if(courseItem.length) courseItem.forEach((cr) => {
      this.totalPrice += cr.web_price;
    });

    if(packageItem.length) packageItem.forEach((pk) => {
      this.totalPrice += pk.web_price;
    });

  }

  getImageUrl(item:any) : string {
  
   if (item.type === this.detailsType.course)
   return (
     this.viewUrl +
     "/Uploads/Courses/" +
     item.ThumbnailGUID +
     ".jpg"
   );
 else if(item.type === this.detailsType.package)
   return (
     this.viewUrl +
     "/Uploads/Package/" +
     item.ImageGUID +
     ".jpg"
   );
   else "";
  }
  ngAfterViewInit() {
    this.urlPathName = window.location.pathname;
  }
  logOut(): void {
    this._checkoutServ.refresStoreItem();
    localStorage.clear();
    //sessionStorage.clear()
    this.router.navigateByUrl("/");
  }
  naviageTo_(url): void {
    this.router.navigateByUrl(url);
  }

  bagOnHover(): void {
    if (this.intrId) {
      clearInterval(this.intrId);
    }
    this.setInterValToBagModal();
  }
  setInterValToBagModal(): void {
    this.intrId = setTimeout(() => {
      this.viewBagPopup = false;
    }, 4000);
  }
  ngOnInit(): void {
    if(this._checkoutServ.getAllItemFromBag())
    this.shopingBagItem = this._checkoutServ.getAllItemFromBag();
  }

  getShortName(): string {
    if (this.authService.getUserInfo()) {
      let name: string =
        this.authService.getUserInfo().firstname.charAt(0) +
        this.authService.getUserInfo().lastname.charAt(0);
      return name.toLocaleUpperCase();
    }
    return "";
  }
  getActiveNavItemClass(url: string): string {
    let urlPath: string = window.location.pathname;

    if (urlPath.includes(url)) return "active";
    else "";
  }
  redirectToHome(): void {
    this.router.navigateByUrl("/");
  }
  _activateMenuDropdown() {
    /**
     * Menu activation reset
     */
    const resetParent = (el) => {
      el.classList.remove("active");
      const parent = el.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.remove("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.remove("active");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("active");
            const parent4 = parent3.parentElement;
            if (parent4) {
              const parent5 = parent4.parentElement;
              parent5.classList.remove("active");
            }
          }
        }
      }
    };
    let links = document.getElementsByClassName("nav-link-ref");
    let matchingMenuItem = null;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // reset menu
      resetParent(links[i]);
    }
    for (let i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i]["pathname"]) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      const parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("active");
            const parent4 = parent3.parentElement;
            if (parent4) {
              const parent5 = parent4.parentElement;
              parent5.classList.add("active");
            }
          }
        }
      }
    }
  }

  /**
   * Window scroll method
   */
  // tslint:disable-next-line: typedef
  windowScroll() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      document.getElementById("topnav").classList.add("nav-sticky");
    } else {
      document.getElementById("topnav").classList.remove("nav-sticky");
    }
    if (document.getElementById("back-to-top")) {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        document.getElementById("back-to-top").style.display = "inline";
      } else {
        document.getElementById("back-to-top").style.display = "none";
      }
    }
  }
  /**
   * Toggle menu
   */
  toggleMenu() {
    this.isCondensed = !this.isCondensed;
    if (this.isCondensed) {
      document.getElementById("navigation").style.display = "block";
    } else {
      document.getElementById("navigation").style.display = "none";
    }
  }

  /**
   * Menu clicked show the submenu
   */
  onMenuClick(event) {
    event.preventDefault();
    const nextEl = event.target.nextSibling.nextSibling;
    if (nextEl && !nextEl.classList.contains("open")) {
      const parentEl = event.target.parentNode;
      if (parentEl) {
        parentEl.classList.remove("open");
      }
      nextEl.classList.add("open");
    } else if (nextEl) {
      nextEl.classList.remove("open");
    }
    return false;
  }
}
