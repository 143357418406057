<!-- Hero Start -->
<section class="bg-home d-flex align-items-center">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6">
        <div class="mr-lg-5">
          <img
            src="assets/images/user/recovery.svg"
            class="img-fluid d-block mx-auto"
            alt=""
          />
        </div>
      </div>
      <div class="col-lg-5 col-md-6">
        <div class="card login_page shadow rounded border-0">
          <div class="card-body">
            <h4 class="card-title text-center">OTP Verification</h4>

            <form class="login-form mt-4">
              <div class="row">
                <div class="col-lg-12">
                  <p class="text-muted">
                    Please enter OTP that we have sent on your email address.
                  </p>
                  <div class="form-group position-relative">
                    <label>OTP <span class="text-danger">*</span></label>
                    <i-feather
                      name="mail"
                      class="fea icon-sm icons"
                    ></i-feather>
                    <input
                      type="text"
                      class="form-control pl-5"
                      placeholder="Enter OTP"
                      name="OTP"
                      [(ngModel)]="OTPVal"
                      #otp_ref="ngModel"
                      required=""
                      minlength="4"
                      maxlength="8"
                      autofocus
                    />
                  </div>
                  <p
                    class="text-danger"
                    *ngIf="otp_ref.invalid && otp_ref.touched"
                  >
                    OTP field is required.
                  </p>
                  <div *ngIf="message != ''">
                    <p
                      [ngClass]="
                        message.includes('Error ::')
                          ? 'text-danger'
                          : 'text-success'
                      "
                    >
                      {{ message }}
                    </p>
                  </div>
                </div>
                <div class="col-lg-12">
                  <button
                    class="btn btn-primary btn-block"
                    (click)="verifyOTP()"
                    [disabled]="otp_ref.invalid"
                  >
                    Submit
                  </button>
                </div>
                <div class="mx-auto"></div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->
