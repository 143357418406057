<!-- Navbar STart -->
<header
  id="topnav"
  class="defaultscroll sticky"
  (window:scroll)="windowScroll()"
  [class]="navClass"
>
  <div class="container">
    <!-- Logo container-->
    <div>
      <a class="logo" *ngIf="navClass !== 'nav-light'">
        <img
          src="../../../assets/images/Group 71-1080w.png"
          class="l-dark"
          height="60"
          alt=""
          (click)="redirectToHome()"
        />
        <img
          src="../../../assets/images/Group 71-1080w.png"
          class="l-light"
          height="60"
          alt=""
          (click)="redirectToHome()"
        />
      </a>
      <a class="logo" routerLink="/" *ngIf="navClass === 'nav-light'">
        <img
          src="../../../assets/images/Group 71-1080w.png"
          class="l-dark"
          height="60"
          alt=""
          (click)="redirectToHome()"
        />
        <img
          src="../../../assets/white logo.png"
          class="l-light"
          height="60"
          alt=""
          (click)="redirectToHome()"
        />
      </a>
    </div>
    <div class="buy-button">
      <a
        (click)="naviageTo_('/auth/login')"
        *ngIf="!authService.isLoggednIn()"
        class="btn btn-primary login-btn-primary"
        >Sign in</a
      >
      <div
        class="btn-group"
        dropdown
        placement="left"
        *ngIf="authService.isLoggednIn()"
      >
        <button
          id="button-animated"
          dropdownToggle
          type="button"
          class="btn btn-default dropdown-toggle"
          aria-controls="dropdown-animated"
          style="padding-top: 1rem; color: #6ac1b8"
        >
          <mat-icon>account_circle</mat-icon> <span class="caret"></span>
        </button>
        <ul
          id="dropdown-animated"
          *dropdownMenu
          class="dropdown-menu dropdown-menu-right"
          role="menu"
          aria-labelledby="button-animated"
        >
          <li role="menuitem">
            <a class="dropdown-item">
              <div class="profile-user">
                <div>
                  <strong class="short-name"> {{ getShortName() }}</strong>
                </div>
                <div class="name-email">
                  <p style="margin-top: 0.4rem; margin-bottom: unset">
                    <b style="font-size: 20px"
                      >{{
                        authService.getUserInfo().firstname +
                          ' ' +
                          authService.getUserInfo().lastname
                      }}
                    </b>
                    <br />
                    <span class="text-muted">
                      {{ authService.getUserInfo().email }}
                    </span>
                  </p>
                </div>
              </div>
            </a>
          </li>
          <li role="menuitem">
            <a class="dropdown-item" (click)="naviageTo_('/account')">
              My library
            </a>
          </li>
          <li (click)="logOut()" role="menuitem">
            <a class="dropdown-item"
              >Log out
              <i-feather name="log-out" style="float: right"></i-feather
            ></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="menu-extras">
      <div class="menu-item">
        <!-- Mobile menu toggle-->
        <a
          class="navbar-toggle"
          (click)="toggleMenu()"
          [ngClass]="{ open: isCondensed === true }"
        >
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
      </div>
    </div>

    <div id="navigation">
      <!-- Navigation Menu-->
      <ul #cardBagIcon class="navigation-menu" [class]="navClass">
        <li style="width: 41%"></li>
        <li [class]="urlPathName === '/' ? 'active' : ''">
          <a (click)="naviageTo_('/')" class="nav-link-ref">App</a>
        </li>

        <li [class]="urlPathName === '/workshop' ? 'active' : ''">
          <a (click)="naviageTo_('/workshop')" class="nav-link-ref">WORKSHOP</a>
        </li>

        <li [class]="urlPathName.includes('store') ? 'active' : ''">
          <a (click)="naviageTo_('/store')" class="nav-link-ref">Store</a>
        </li>

        <li
          [class]="urlPathName.includes('buy') ? 'active' : ''"
         
          style="cursor: pointer"
        >
        <!-- (click)="naviageTo_('/buy')" -->
        <!-- (click)="naviageTo_('/buy')" -->
          <a
            class="btn-default"
            style="cursor: pointer"
            
            (mouseover)="viewBagPopup = true; bagOnHover()"
          >
            <i-feather name="shopping-cart"></i-feather>
            <span *ngIf="shopingBagItem" class="badge badge-info">
              {{ shopingBagItem.length }}
            </span>
          </a>

          <div
            id="myModal"
            class="modalx container"
            (mouseout)="viewBagPopup = false"
            (mouseover)="viewBagPopup = true; bagOnHover()"
          >
            <!-- Modal content -->
            <div
              class="modal-content"
              (mouseover)="viewBagPopup = true; bagOnHover()"
              [ngClass]="viewBagPopup ? 'm-fadeIn' : 'm-fadeOut'"
              id="md-content"
            >
              <div class="bag-header">
                <div>
                  <b> My cart,</b> {{ shopingBagItem.length }}
                  {{ shopingBagItem.length <= 1 ? 'item' : 'items' }}
                </div>
                <div>
                  <span class="close" (click)="viewBagPopup = false"
                    >&times;</span
                  >
                </div>
              </div>

              <div class="bag-courses">
                <div
                  class="bag-course-item"
                  *ngFor="let item of shopingBagItem"
                >
                  <div>
                    <img
                      [src]="getImageUrl(item)"
                      class="img-fluid rounded mx-auto"
                      alt=""
                    />
                  </div>

                  <div>
                    <div class="mt-2 pl-3 row" *ngIf="item.type===detailsType.course">
                        <div class="col-lg-12">
                          <span *ngIf="item.type===detailsType.course">{{
                            item.web_price | currency: 'USD':'symbol'
                          }}</span>
                        </div>

                        <div class="col-lg-12">
                          <span class="course-title" *ngIf="item.type===detailsType.course">{{ item.CourseName }}</span>
                          <br />
                          <span class="channel-title" *ngIf="item.type===detailsType.course">
                            {{ item.ChannelName }}
                          </span>
                        </div>
                    </div>

                    <div class="mt-1 pl-3 row"  *ngIf="item.type===detailsType.package">
                    
                        <div class="col-lg-12"><span>{{
                          item.web_price | currency: 'USD':'symbol'
                        }}</span></div>

                        <div class="col-lg-12">
                          <span class="course-title" >{{ item.Name }}</span>
                        </div>
                     
                    </div>

                  </div>
                </div>

                <div
                  *ngIf="shopingBagItem.length <= 0"
                  class="alert alert-info"
                >
                  Bag is empty.
                </div>
              </div>
              <div class="bag-total-price">
                <div class="col">
                  <b>Sub-total</b>
                </div>
                <div class="col" style="text-align: right">
                  {{ totalPrice | currency: 'USD':'symbol' }}
                </div>
              </div>
              <div class="bag-checkout">
                <button class="btn btn-block  btn-soft-primary" (click)="naviageTo_('/buy')">
                  VIEW BAG
                </button>

                <button
                  class="btn btn-soft-success btn-block "
                  (click)="naviageTo_('/buy/buyCourse')"
                  [disabled]="shopingBagItem.length <= 0"
                >
                  CHECKOUT
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="buy-menu-btn d-none">
        <a
          (click)="naviageTo_('/auth/login')"
          *ngIf="!authService.isLoggednIn()"
          class="btn btn-primary login-btn-primary"
          >Sign in</a
        >
        <a
          (click)="logOut()"
          *ngIf="authService.isLoggednIn()"
          class="btn btn-primary login-btn-primary"
          >Log out</a
        >
      </div>
    </div>
  </div>
</header>
