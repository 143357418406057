import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "src/app/service/authentication.service";
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialog } from "@angular/material/dialog";
import { PagePrivacyComponent } from "src/app/core/components/page-privacy/page-privacy.component";
import { map } from "rxjs/operators";
@Component({
  selector: "app-auth-signup",
  templateUrl: "./auth-signup.component.html",
  styleUrls: ["./auth-signup.component.css"],
})
export class AuthSignupComponent implements OnInit {
  registerForm: FormGroup;
  public form: FormGroup;
  public firstname: AbstractControl;
  public middlename: AbstractControl;
  public lastname: AbstractControl;
  public email: AbstractControl;
  public password: AbstractControl;
  public usertypeid: AbstractControl;
  public confirmPassword: AbstractControl;
  public confirmEmail: AbstractControl;
  public isValidBothEmail: boolean = false;
  courseGUID: string;
  returnUrl: string = "";

  msg: any = {
    message: "",
    status: false,
    step: 1,
  };
  OTP: any = "";

  constructor(
    fb: FormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) {
    this.form = fb.group(
      {
        firstname: [
          "",
          Validators.compose([Validators.required, Validators.minLength(3)]),
        ],
        middlename: [""],
        lastname: [
          "",
          Validators.compose([Validators.required, Validators.minLength(3)]),
        ],
        email: ["", Validators.compose([Validators.required, emailValidator])],
        password: ["", Validators.required],
        confirmPassword: ["", Validators.required],
        terCondition: ["", Validators.required],
        confirmEmail: ["", Validators.compose([Validators.required])],
      },
      { validator: matchingPasswords("password", "confirmPassword") }
    );

    this.firstname = this.form.controls["firstname"];
    this.middlename = this.form.controls["middlename"];
    this.lastname = this.form.controls["lastname"];
    this.email = this.form.controls["email"];
    this.password = this.form.controls["password"];
    this.confirmPassword = this.form.controls["confirmPassword"];
    this.usertypeid = this.form.controls["usertypeid"];
    this.confirmEmail = this.form.controls["confirmEmail"];

    this.confirmEmail.valueChanges.subscribe((confirmEmail) => {
      if (confirmEmail === this.email.value) this.isValidBothEmail = true;
      else this.isValidBothEmail = false;
    });
    this.email.valueChanges.subscribe((email) => {
      if (email === this.confirmEmail.value) this.isValidBothEmail = true;
      else this.isValidBothEmail = false;
    });
  }

  redirectToSignIn(): void {
    this.router.navigateByUrl("auth/login?returnUrl=" + this.returnUrl);
  }

  submit(): void {
    this.isSubmitingForm = true;

    if (this.form.valid && this.isValidBothEmail) {
      this.spinner.show();
      let value = this.form.value;

      this.authService.checkEmailAlreadyExit(this.email.value).subscribe(
        (response) => {
          this.spinner.hide();
          if (response === 0) {
            this.msg.message = "Error Occurred  Email id already exist.";
            this.msg.status = false;
            this.msg.step = 1;
          } else {
            //this.sendOTP()
            this.registerUser();
          }
        },
        (error) => {
          this.spinner.hide();
        }
      );
    }
  }

  isSubmitingForm: boolean = false;
  sendOTP(): void {
    this.msg.message = "";
    if (this.form.valid) {
      this.spinner.show();
      let value = this.form.value;
      this.authService
        .sendOtp(this.email.value, this.firstname.value)
        .subscribe(
          (response) => {
            this.spinner.hide();
            if (response == "1") {
              this.msg.status = true;
              this.msg.message =
                "OTP sent on your email. Please check your inbox. if not received check your SPAM and flag OTP email as not SPAM.";
              this.msg.step = 2;
            }
          },
          (error) => {
            this.spinner.hide();
          }
        );
    }
  }
  verifyOTP(): void {
    this.spinner.show();
    this.authService
      .otpVerification(this.email.value, this.OTP)
      .subscribe((response) => {
        this.spinner.hide();
        if (response === -1) {
          this.msg.message = "Error OTP Expired.";
          this.msg.status = false;
          this.msg.step = 2;
        } else if (response === 1) {
          this.msg.message = "Sucess OTP verified successfully.";
          this.msg.status = true;
          this.msg.step = 2;

          this.registerUser();
        } else if (response === -2) {
          this.msg.message = "Error Invalid email address.";
          this.msg.status = false;
          this.msg.step = 2;
        } else if (response === 0) {
          this.msg.message = "Error Invalid OTP.";
          this.msg.status = false;
          this.msg.step = 2;
        }
      });
  }
  registerUser(): void {
    this.spinner.show();
    let value = this.form.value;
    value.RegFrmType = "2"; // 2 client , 5 admin
    value.usertypeid = "2"; //
    value["OTP"] = this.OTP;

    this.authService.registration(value).subscribe(
      (response) => {
        if (response) {
          this.spinner.hide();
          if (response === 0) {
            this.msg.status = false;
            this.msg.message = "Conflict in registration.";
            this.msg.step = 2;
          } else if (response === 2) {
            this.msg.status = false;
            this.msg.message = "Email id already exist.";
            this.msg.step = 2;
          } else if (response === 1) {
            this.msg.status = false;
            this.msg.message = "First name & last name is blank.";
            this.msg.step = 2;
          } else if (response > 5) {
            this.msg.status = true;
            this.msg.message = "Registration successfully.";
            this.msg.step = 3;
            this.loginUser();
          }
        }
      },
      (error) => {}
    );
  }

  loginUser(): void {
    this.spinner.show();
    let body = {};
    body["email"] = this.email.value;
    body["password"] = this.password.value;

    this.authService.login(body).subscribe(
      (response) => {
        if (response) {
          if (response.ID) {
            localStorage.setItem("u_info", JSON.stringify(response));
            this.router.navigateByUrl(this.returnUrl);
          } else {
            this.msg.status = false;
            this.msg.message = response.message;
            this.msg.step = 2;
          }

          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  ngOnInit(): void {
    this.returnUrl =
      this.route.snapshot.queryParams["returnUrl"] || "/auth/login";

    let url = window.encodeURI(this.returnUrl);

    if (url.includes("EnrollCourse")) {
      this.courseGUID = url.split("EnrollCourse/")[1];
    }
  }
}
export function emailValidator(control: FormControl): { [key: string]: any } {
  var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
  if (control.value && !emailRegexp.test(control.value)) {
    return { invalidEmail: true };
  }
}

export function matchingPasswords(
  passwordKey: string,
  passwordConfirmationKey: string
) {
  return (group: FormGroup) => {
    let password = group.controls[passwordKey];
    let passwordConfirmation = group.controls[passwordConfirmationKey];
    if (password.value !== passwordConfirmation.value) {
      return passwordConfirmation.setErrors({ mismatchedPasswords: true });
    }
  };
}
