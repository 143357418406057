import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  Channel,
  DataserviceService,
} from "src/app/service/dataservice.service";

@Component({
  selector: "app-index-personal",
  templateUrl: "./index-personal.component.html",
  styleUrls: ["./index-personal.component.css"],
})
export class IndexPersonalComponent implements OnInit {
  channelList: Array<Channel> = [];
  constructor(
    private dataService: DataserviceService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getChannelList();
  }

  getChannelList(): void {
    this.dataService.getChannelList(1, 8).subscribe((response) => {
      this.channelList = response;
    });
  }

  routeToMorechannels(): void {
    this.router.navigateByUrl("/channels");
  }

}
