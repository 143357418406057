<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title">Privacy Policy</h4>
          <ul class="list-unstyled mt-4">
            <li class="list-inline-item h6 date text-muted">
              <span class="text-dark">Last Revised :</span> 23th Sep, 2019
            </li>
          </ul>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <!-- <li class="breadcrumb-item"><a href="#">Page</a></li>
                <li class="breadcrumb-item"><a href="#">Utility</a></li> -->
                <li class="breadcrumb-item active" aria-current="page">
                  Privacy
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
        fill="currentColor"
      ></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Privacy -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow rounded border-0">
          <div class="card-body">
            <div class="container" style="margin-top: 4rem">
              <div id="ctl00_ContentPlaceHolder1_pnlContent">
                <div id="divMainPolicy" style="margin-bottom: 60px">
                  <div class="lnkBack"></div>
                  <div id="divLogo">
                    <div class="row">
                      <div class="col-md-2 col-lg-2 col-sm-12">
                        <a routerLink="/">Back</a>
                      </div>
                      <div class="col-md-10 col-lg-10 col-sm-12">
                        <h3 style="text-align: center; margin-right: 20%">
                          PRIVACY POLICY
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div
                    style="
                      text-align: justify;
                      font-family: Arial;
                      font-size: 12px !important;
                      margin: 10px 30px 15px 15px;
                    "
                  >
                    <div class="WordSection1">
                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <i
                          ><span
                            style="
                              font-size: 12pt;
                              font-family: 'Segoe UI' sans-serif;
                              color: #091e42;
                            "
                            >Effective starting: January 31, 2020</span
                          ></i
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >&nbsp;
                        </span>
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 15pt;
                          margin-right: 0in;
                          margin-bottom: 15pt;
                          margin-left: 0in;
                          line-height: normal;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 16.5pt;
                            font-family: 'Segoe UI', sans-serif;
                            color: #253858;
                          "
                          >What this policy covers</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >Your privacy is important to us, and so is being
                          transparent about how we collect, use, and share
                          information about you. This policy is intended to help
                          you understand:</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 0in;
                          margin-right: 0in;
                          margin-bottom: 0in;
                          margin-left: 15pt;
                          margin-bottom: 0.0001pt;
                          text-indent: 0in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 10pt;
                            font-family: Symbol;
                            color: #091e42;
                          "
                          >·<span style='font: 7.0pt "Times New Roman&quot'
                            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span></span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          ><a
                            href="https://sophiafiori.com/PrivacyPolicySF.aspx#what-information-we-collect-about-you"
                            ><span style="color: #0052cc; text-decoration: none"
                              >What information we collect about you</span
                            ></a
                          ></span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 0in;
                          margin-right: 0in;
                          margin-bottom: 0in;
                          margin-left: 15pt;
                          margin-bottom: 0.0001pt;
                          text-indent: 0in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 10pt;
                            font-family: Symbol;
                            color: #091e42;
                          "
                          >·<span style='font: 7.0pt "Times New Roman&quot'
                            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span></span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          ><a
                            href="https://sophiafiori.com/PrivacyPolicySF.aspx#how-we-use-information-we-collect"
                            ><span style="color: #0052cc; text-decoration: none"
                              >How we use information we collect&nbsp;</span
                            ></a
                          ></span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 0in;
                          margin-right: 0in;
                          margin-bottom: 0in;
                          margin-left: 15pt;
                          margin-bottom: 0.0001pt;
                          text-indent: 0in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 10pt;
                            font-family: Symbol;
                            color: #091e42;
                          "
                          >·<span style='font: 7.0pt "Times New Roman&quot'
                            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span></span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          ><a
                            href="https://sophiafiori.com/PrivacyPolicySF.aspx#how-we-share-information-we-collect"
                            ><span style="color: #0052cc; text-decoration: none"
                              >How we share information we collect</span
                            ></a
                          >&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 0in;
                          margin-right: 0in;
                          margin-bottom: 0in;
                          margin-left: 15pt;
                          margin-bottom: 0.0001pt;
                          text-indent: 0in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 10pt;
                            font-family: Symbol;
                            color: #091e42;
                          "
                          >·<span style='font: 7.0pt "Times New Roman&quot'
                            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span></span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          ><a
                            href="https://sophiafiori.com/PrivacyPolicySF.aspx#how-we-store-and-secure-information-we-collect"
                            ><span style="color: #0052cc; text-decoration: none"
                              >How we store and secure information we
                              collect&nbsp;</span
                            ></a
                          ></span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 0in;
                          margin-right: 0in;
                          margin-bottom: 0in;
                          margin-left: 15pt;
                          margin-bottom: 0.0001pt;
                          text-indent: 0in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 10pt;
                            font-family: Symbol;
                            color: #091e42;
                          "
                          >·<span style='font: 7.0pt "Times New Roman&quot'
                            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span></span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          ><a
                            href="https://sophiafiori.com/PrivacyPolicySF.aspx#how-to-access-and-control-your-information"
                            ><span style="color: #0052cc; text-decoration: none"
                              >How to access and control your
                              information&nbsp;</span
                            ></a
                          ></span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 0in;
                          margin-right: 0in;
                          margin-bottom: 0in;
                          margin-left: 15pt;
                          margin-bottom: 0.0001pt;
                          text-indent: 0in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 10pt;
                            font-family: Symbol;
                            color: #091e42;
                          "
                          >·<span style='font: 7.0pt "Times New Roman&quot'
                            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span></span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          ><a
                            href="https://sophiafiori.com/PrivacyPolicySF.aspx#how-we-transfer-information-we-collect-internationally"
                            ><span style="color: #0052cc; text-decoration: none"
                              >How we transfer information we collect
                              internationally&nbsp;</span
                            ></a
                          ></span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 0in;
                          margin-right: 0in;
                          margin-bottom: 0in;
                          margin-left: 15pt;
                          margin-bottom: 0.0001pt;
                          text-indent: 0in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 10pt;
                            font-family: Symbol;
                            color: #091e42;
                          "
                          >·<span style='font: 7.0pt "Times New Roman&quot'
                            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span></span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          ><a
                            href="https://sophiafiori.com/PrivacyPolicySF.aspx#other-important-privacy-information"
                            ><span style="color: #0052cc; text-decoration: none"
                              >Other important privacy information</span
                            ></a
                          ></span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >This Privacy Policy covers the information we collect
                          about you when you use our products or services, or
                          otherwise interact with us&nbsp;(for example, by
                          attending our premises or events or by communicating
                          with us), unless a different policy is
                          displayed.&nbsp; Sophia Fiori, we and us refers to
                          Sophia Fiori International, LTD, Sophia Fiori
                          International BH and any of our corporate
                          affiliates.&nbsp; We offer a wide range of
                          products</span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI', sans-serif;
                            color: black;
                          "
                          >,&nbsp;<a
                            href="https://www.atlassian.com/legal/privacy-policy/product-family"
                            ><span style="color: black; text-decoration: none"
                              >including&nbsp;our fine jewelry, art, watches,
                              website catalog, diamond club membership, live
                              auctions and</span
                            ></a
                          >
                          other online services.&nbsp;&nbsp;We refer to all of
                          these products, together with our other&nbsp;services
                          and websites as "Services" in this
                          policy.&nbsp;&nbsp;&nbsp;&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >This policy also explains your choices surrounding
                          how we use information about you, which include how
                          you can object to certain uses of information about
                          you&nbsp;and how you can access and update certain
                          information about you.&nbsp;&nbsp;<b
                            >If you do not agree with this policy, do not access
                            or use our Services or interact with any other
                            aspect of our business</b
                          >.&nbsp;&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >Where we provide the Services under contract with an
                          organization (for example, your employer) that
                          organization controls the information processed by the
                          Services. For more information, please see Notice to
                          End Users below.&nbsp;This policy does not apply to
                          the extent we process personal information in the role
                          of a processor on behalf of such organizations.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 15pt;
                          margin-right: 0in;
                          margin-bottom: 15pt;
                          margin-left: 0in;
                          line-height: normal;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 16.5pt;
                            font-family: 'Segoe UI', sans-serif;
                            color: #253858;
                          "
                          >What information we collect about you</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >We collect information about you when you provide it
                          to us, when you use our Services, and when other
                          sources provide it to us, as further described
                          below.&nbsp;&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><span
                            style="
                              font-size: 12pt;
                              font-family: 'Segoe UI' sans-serif;
                              color: #091e42;
                            "
                            >Information you provide to us</span
                          ></b
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >We collect information about you when you input it
                          into the Services or otherwise provide it directly to
                          us.&nbsp; &nbsp;&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Account and Profile Information:&nbsp;</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >We collect information about you when you register
                          for an account, create or modify your profile, set
                          preferences, sign-up for or make purchases through the
                          Services. For example, you provide your contact
                          information and, in some cases, billing information,
                          when you register for the Services. You also have the
                          option of adding a display name, profile photo, job
                          title, and other details to your profile information
                          to be displayed in our Services.&nbsp; We keep track
                          of your preferences when you select settings within
                          the Services.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Content you provide through our
                              products:&nbsp;</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >The Services include the Sophia Fiori products you
                          use, where we collect and store content that you post,
                          send, receive and share. This content includes any
                          information about you that you may choose to include.
                          Data you enter in our online websites and any feedback
                          you provide to us.&nbsp;Content also includes the
                          files and links you upload to the Services.&nbsp;If
                          you use a server or data center version of the
                          Services, we do not host, store, transmit, receive or
                          collect&nbsp;information about you
                          (including&nbsp;your content),&nbsp;except in limited
                          cases, where permitted by your administrator: we
                          collect feedback you provide directly to us through
                          the product&nbsp;and;&nbsp;we collect content using
                          analytics techniques that hash, filter or otherwise
                          scrub the information to exclude information&nbsp;that
                          might identify&nbsp;you or your
                          organization;&nbsp;and&nbsp;we collect clickstream
                          data about how you interact with and use features in
                          the Services. Server&nbsp;and data center
                          administrators can disable our collection of this
                          information from the Services via the administrator
                          settings or prevent this information from being shared
                          with us by blocking transmission at the local network
                          level.&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Content you provide through our
                              websites:&nbsp;</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >The Services also include our websites owned or
                          operated by us. We collect other content that you
                          submit to these websites, which include social media
                          or social networking websites operated by us. For
                          example, you provide content to us when you provide
                          feedback or when you participate in any interactive
                          features, surveys, contests, promotions, sweepstakes,
                          activities or events.&nbsp;&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Information you provide through our support
                              channels:&nbsp;</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >The Services also include our customer support, where
                          you may choose to submit information regarding a
                          problem you are experiencing with a Service.&nbsp;
                          Whether you designate yourself as a technical contact,
                          open a support ticket, speak to one of
                          our&nbsp;representatives directly or otherwise engage
                          with our support team, you will be asked to provide
                          contact information, a summary of the problem you are
                          experiencing, and any other documentation, screenshots
                          or information that would be helpful in resolving the
                          issue.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Payment Information:&nbsp;&nbsp;</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >We collect payment and billing information when you
                          register for certain paid Services.&nbsp; For example,
                          we ask you to designate a billing representative,
                          including name and contact information, upon
                          registration.&nbsp; You might also
                          provide&nbsp;payment information, such as payment card
                          details, which we collect via secure payment
                          processing services.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><span
                            style="
                              font-size: 12pt;
                              font-family: 'Segoe UI' sans-serif;
                              color: #091e42;
                            "
                            >Information we collect automatically when you use
                            the Services</span
                          ></b
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >We collect information about you when you use our
                          Services, including browsing our websites and taking
                          certain actions within the Services.&nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp;&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Your use of the Services</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >:&nbsp;We keep track of certain information about you
                          when you visit and interact with any of our Services.
                          This information includes the features you use; the
                          links you click on; the type, size and filenames of
                          attachments you upload to the Services; frequently
                          used search terms; and how you interact with others on
                          the Services.&nbsp; We also collect information about
                          the teams and people you work with and how you work
                          with them, like who you collaborate with and
                          communicate with most frequently.&nbsp; If you use a
                          server or data center version of the Services, the
                          information we collect about your use of the Services
                          is limited to clickstream data&nbsp;about how you
                          interact with and use features in the Services, in
                          addition to content-related information described in
                          "Content you provide through&nbsp;our products,"
                          above.&nbsp; Server and data center administrators can
                          disable our collection of this information from the
                          Services via the administrator settings or prevent
                          this information from being shared with us by blocking
                          transmission at the local network level.&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Device and Connection Information</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >: We collect information about your computer, phone,
                          tablet, or other devices you use to access the
                          Services. This device information includes your
                          connection type and settings when you install, access,
                          update, or use our Services. We also collect
                          information through your device about your operating
                          system, browser type, IP address, URLs of
                          referring/exit pages, device identifiers, and crash
                          data. We use your IP address and/or country preference
                          in order to approximate your location to provide you
                          with a better Service experience.&nbsp;&nbsp;How much
                          of this information we collect depends on the type and
                          settings of the device you use to access the
                          Services.&nbsp; Server&nbsp;and data center Service
                          administrators can disable collection of this
                          information via the administrator settings or prevent
                          this information from being shared with us by blocking
                          transmission at the local network
                          level.&nbsp;&nbsp;&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Cookies and Other Tracking Technologies</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >: Sophia Fiori and our third-party partners, such as
                          our advertising and analytics partners, use cookies
                          and other tracking technologies (e.g., web beacons,
                          device identifiers and pixels) to provide
                          functionality and to recognize you across different
                          Services and devices. For more </span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI', sans-serif;
                            color: black;
                          "
                          >information, please see our&nbsp;Cookies and Tracking
                          Notice, which includes </span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >information on how to control or opt out&nbsp;of
                          these cookies and tracking technologies.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><span
                            style="
                              font-size: 12pt;
                              font-family: 'Segoe UI' sans-serif;
                              color: #091e42;
                            "
                            >Information we receive from other sources</span
                          ></b
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >We receive information about you from other
                          Service&nbsp;users, from third-party services, from
                          our related companies, social media platforms, public
                          databases, and from our business and channel
                          partners.&nbsp;We may combine this information with
                          information we collect through other means described
                          above. This helps us to update and improve our
                          records, identify new customers, create more
                          personalized advertising and suggest services that may
                          be of interest to you.&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Other users of the Services</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >: Other users of our Services may provide information
                          about you when they submit content through the
                          Services.&nbsp;For example, you may be mentioned in a
                          support issue opened by someone else,&nbsp;a team
                          member may upload content about you to a Trello board,
                          or your team’s story status may be discussed in our
                          online forums.&nbsp;&nbsp;We also receive your email
                          address from other Service users when they provide it
                          in order to invite you to the Services.&nbsp;
                          Similarly, an administrator may provide your contact
                          information when they designate you as the billing or
                          technical contact on your company's account or when
                          they designate you as an administrator.&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Sophia Fiori Companies</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >:&nbsp;&nbsp;We receive information about </span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI', sans-serif;
                            color: black;
                          "
                          >you from&nbsp;companies that are owned or operated by
                          Sophia Fiori, in accordance </span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >with&nbsp;their terms and policies.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Sophia Fiori Partners</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >:&nbsp; We </span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI', sans-serif;
                            color: black;
                          "
                          >work with a&nbsp;global network of retail and
                          wholesale partners. Some of these </span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >partners also help us to market and promote our
                          Products and Services, generate leads for us, and
                          resell our jewelry.&nbsp; We receive information from
                          these partners, such as billing information, billing
                          and technical contact information, company name, what
                          Sophia Fiori Services&nbsp;you have purchased or may
                          be interested in, evaluation information you have
                          provided, what events you have attended, and what
                          country you are in.&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Other Partners</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >: We receive information about you and your
                          activities on and off the Services from third-party
                          partners, such as&nbsp;advertising and market research
                          partners who provide us with information about your
                          interest in and engagement with, our Services and
                          online advertisements.&nbsp; &nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Third Party Providers</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >: We may receive information about you from third
                          party providers of business information and publicly
                          available sources (like&nbsp;social media platforms),
                          including physical mail addresses, job titles, email
                          addresses, phone numbers, intent data (or user
                          behavior data), IP addresses and social media
                          profiles, for the purposes of targeted advertising of
                          products that may interest you, delivering
                          personalized communications, event promotion, and
                          profiling.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 15pt;
                          margin-right: 0in;
                          margin-bottom: 15pt;
                          margin-left: 0in;
                          line-height: normal;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 16.5pt;
                            font-family: 'Segoe UI', sans-serif;
                            color: #253858;
                          "
                          >How we use information we collect</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >How we use the information we collect depends in part
                          on which Services you use, how you use them, and any
                          preferences you have communicated to us.&nbsp; Below
                          are the specific purposes for which we use the
                          information we collect about you.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >To provide the Services and personalize your
                              experience</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >:&nbsp;We use information about you to provide the
                          Services to you, including to process transactions
                          with you, authenticate you when you log in, provide
                          customer support, and operate,&nbsp;maintain, and
                          improve&nbsp;the Services.&nbsp;&nbsp;For example, we
                          use the name and picture you provide&nbsp;in
                          your&nbsp;account to identify you to other Service
                          users.&nbsp;Our&nbsp;Services also
                          include&nbsp;tailored features that&nbsp;personalize
                          your experience, enhance your productivity, and
                          improve your ability to collaborate effectively with
                          others&nbsp;by automatically analyzing the activities
                          of your team to provide search results, activity
                          feeds, notifications, connections and recommendations
                          that are most relevant for you and your team.&nbsp;
                          For example, we may use your stated job title and
                          activity to return search results we think are
                          relevant to your job function.&nbsp; We also use
                          information about you to connect you with other team
                          members seeking your subject matter expertise.&nbsp;We
                          may use your email domain to infer your affiliation
                          with a particular organization or industry to
                          personalize the content and experience you receive on
                          our websites.&nbsp;Where you&nbsp;use multiple
                          Services, we combine information about you and your
                          activities to provide an integrated experience, such
                          as to allow you to find information from one Service
                          while searching from another&nbsp;or to present
                          relevant product information as you travel across our
                          websites.&nbsp;Based on your interactions with
                          different Sophia Fiori products and advertisements, we
                          will personalize your experience and tailor our
                          communications and offers to you. To opt out of this
                          personalization, please contact
                          privacy@sophiafiori.com. &nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >For research and development</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >:&nbsp; We are always looking for ways to make our
                          Services smarter, faster, secure, integrated, and
                          useful.&nbsp; We use information and&nbsp;collective
                          learnings (including feedback)&nbsp;about how people
                          use our Services to troubleshoot, to identify trends,
                          usage, activity patterns, and areas for integration
                          and&nbsp;to improve our Services and to develop new
                          products, features and technologies that benefit our
                          users and the public.&nbsp;For example,
                          to&nbsp;improve the&nbsp;@mention feature, we
                          automatically analyze recent interactions among users
                          and how often they @mention one another
                          to&nbsp;surface the most relevant connections for
                          users. We automatically analyze and aggregate
                          frequently used search terms to improve the accuracy
                          and relevance of suggested topics that auto-populate
                          when you use the search feature.&nbsp;In some cases,
                          we apply these learnings across our Services to
                          improve and develop similar features, to better
                          integrate the Services you use, or to provide you with
                          insights based on how others use our Services. We also
                          test and analyze certain new features with some users
                          before rolling the feature out to all
                          users.&nbsp;&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >To communicate with you about the Services</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >: We use your contact information to send
                          transactional communications via email and within the
                          Services, including confirming your purchases,
                          reminding you of subscription expirations, responding
                          to your comments, questions and requests, providing
                          customer support, and sending you technical notices,
                          updates, security alerts, and administrative
                          messages.&nbsp; We send you email notifications when
                          you or others interact with you on the Services, for
                          example, when you are&nbsp;@mentioned on a page or
                          ticket,&nbsp;when a task is&nbsp;assigned to you, or
                          when you are added to a Trello board.&nbsp;We also
                          provide tailored communications based on your activity
                          and interactions with us.&nbsp; For example, certain
                          actions you take in the Services may automatically
                          trigger a feature or third-party app suggestion within
                          the Services that would make that task easier.&nbsp;
                          We also send you communications as you onboard to a
                          particular Service to help you become more proficient
                          in using that Service.&nbsp;These communications are
                          part of the Services and in most cases, you cannot opt
                          out&nbsp;of them.&nbsp; If an opt out is available,
                          you will find that option within the communication
                          itself or in your account
                          settings.&nbsp;&nbsp;&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >To market, promote and drive engagement with the
                              Services:&nbsp;</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >We use your contact information and information about
                          how you use the Services to send promotional
                          communications that may be of specific interest to
                          you, including by email and by displaying Sophia Fiori
                          ads on other companies' websites and
                          applications.&nbsp; These communications may be
                          informed by audits of interactions (like counting ad
                          impressions), and are&nbsp;aimed at driving engagement
                          and maximizing what you get out of the Services,
                          including&nbsp;information about new features, survey
                          requests, newsletters, and events we think may be of
                          interest to you.&nbsp; We also communicate with you
                          about new Services, product offers, promotions, and
                          contests.&nbsp; You can control whether you receive
                          these communications as described below under "Opt-out
                          of communications."</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Customer support</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >:&nbsp;We use your information to resolve technical
                          issues you encounter, to respond to your requests for
                          assistance, to analyze crash information, and to
                          repair and improve the Services.&nbsp;Where you give
                          us express permission to do so, we share information
                          with a third-party expert for the purpose of
                          responding to support-related requests.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >For safety and security</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >:&nbsp;We use information about you and your Service
                          use to verify accounts and activity, to detect,
                          prevent, and respond to potential or actual security
                          incidents and to monitor and protect against other
                          malicious, deceptive, fraudulent or illegal activity,
                          including violations&nbsp;of Service
                          policies.&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >To protect our legitimate business interests and
                              legal rights</span
                            ></i
                          ></b
                        ><b
                          ><span
                            style="
                              font-size: 12pt;
                              font-family: 'Segoe UI' sans-serif;
                              color: #091e42;
                            "
                            >:</span
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >&nbsp;Where required by law or where we believe it is
                          necessary to protect our legal rights, interests and
                          the interests of others, we use information about you
                          in connection with legal claims, compliance,
                          regulatory, and audit functions, and&nbsp;disclosures
                          in connection with the acquisition, merger or sale of
                          a business.&nbsp;&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >With your&nbsp;consent</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >: We use information about you where you have given
                          us consent to do so for a specific purpose not listed
                          above.&nbsp; For example, we may publish testimonials
                          or featured customer stories to promote the Services,
                          with your permission.&nbsp;&nbsp; &nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Legal bases for processing (for EEA users)</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >:&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >If you are an individual in the European Economic
                          Area (EEA), we collect and process information about
                          you only where we have legal bases for doing so under
                          applicable EU laws.&nbsp; The legal bases depend on
                          the Services you use and how you use them.&nbsp;This
                          means we collect and use your information only
                          where:</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 7.5pt;
                          margin-right: 0in;
                          margin-bottom: 0in;
                          margin-left: 15pt;
                          margin-bottom: 0.0001pt;
                          text-indent: 0in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 10pt;
                            font-family: Symbol;
                            color: #091e42;
                          "
                          >·<span style='font: 7.0pt "Times New Roman&quot'
                            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span></span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >We need it to provide you the Services, including to
                          operate the Services, provide customer support and
                          personalized features and to protect the safety and
                          security of the Services;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 7.5pt;
                          margin-right: 0in;
                          margin-bottom: 0in;
                          margin-left: 15pt;
                          margin-bottom: 0.0001pt;
                          text-indent: 0in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 10pt;
                            font-family: Symbol;
                            color: #091e42;
                          "
                          >·<span style='font: 7.0pt "Times New Roman&quot'
                            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span></span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >It satisfies a legitimate interest (which is not
                          overridden by your data protection interests), such as
                          for research and development, to market and
                          promote&nbsp;the Services and to protect our legal
                          rights and interests;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 7.5pt;
                          margin-right: 0in;
                          margin-bottom: 0in;
                          margin-left: 15pt;
                          margin-bottom: 0.0001pt;
                          text-indent: 0in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 10pt;
                            font-family: Symbol;
                            color: #091e42;
                          "
                          >·<span style='font: 7.0pt "Times New Roman&quot'
                            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span></span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >You give us consent to do so for a specific purpose;
                          or</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 7.5pt;
                          margin-right: 0in;
                          margin-bottom: 0in;
                          margin-left: 15pt;
                          margin-bottom: 0.0001pt;
                          text-indent: 0in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 10pt;
                            font-family: Symbol;
                            color: #091e42;
                          "
                          >·<span style='font: 7.0pt "Times New Roman&quot'
                            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span></span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >We need to process your data to comply with a legal
                          obligation.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >If you have consented to our use of information about
                          you for a specific purpose, you have the right to
                          change your mind at any time, but this will not affect
                          any processing that has already taken place.&nbsp;
                          Where we are using your information because we or a
                          third party (e.g. your employer) have a legitimate
                          interest to do so, you have the right to object to
                          that use though, in some cases, this may mean no
                          longer using the Services.&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 15pt;
                          margin-right: 0in;
                          margin-bottom: 15pt;
                          margin-left: 0in;
                          line-height: normal;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 16.5pt;
                            font-family: 'Segoe UI', sans-serif;
                            color: #253858;
                          "
                          >How we share information we collect</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >We make collaboration tools, and we want them to work
                          well for you.&nbsp; This means sharing information
                          through the Services and with certain third
                          parties.&nbsp; We share information we collect about
                          you in the ways discussed below,&nbsp;including in
                          connection with possible business transfers. We are
                          not in the business of selling&nbsp;information about
                          you to advertisers or other third parties.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><span
                            style="
                              font-size: 12pt;
                              font-family: 'Segoe UI' sans-serif;
                              color: #091e42;
                            "
                            >Sharing with other Service users&nbsp;</span
                          ></b
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >When you use the Services, we share certain
                          information about you with other Service users.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><span
                            style="
                              font-size: 12pt;
                              font-family: 'Segoe UI' sans-serif;
                              color: #091e42;
                            "
                            >Sharing with third parties</span
                          ></b
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >We share information with third parties that help
                          us&nbsp;operate, provide, improve, integrate,
                          customize, support and market our Services.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Service Providers</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >: We&nbsp;work with third-party service
                          providers&nbsp;to provide website and application
                          development, hosting, maintenance, backup, storage,
                          virtual infrastructure, payment processing, analysis
                          and other services for us, which may require them
                          to&nbsp;access or use information about you.&nbsp; If
                          a service provider needs to&nbsp;access information
                          about you to perform services on our behalf, they do
                          so under close instruction from us, including
                          appropriate security and
                          confidentiality&nbsp;procedures designed to protect
                          your information.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Sophia Fiori Partners</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >: We work with third parties who provide consulting,
                          sales, support, and technical services to deliver and
                          implement customer solutions around the Services. We
                          may share your information with these third parties in
                          connection with their services, such as to assist with
                          billing and collections, to provide localized support,
                          and to provide customizations.&nbsp; We may also share
                          information with these third parties where you have
                          agreed to that sharing.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Third Party Apps</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >: You, your administrator or other Service users may
                          choose to&nbsp;add new functionality or change the
                          behavior of the Services by installing third party
                          apps within the Services.&nbsp;&nbsp;Doing so may give
                          third-party apps access to your account and
                          information about you like&nbsp;your name and email
                          address,&nbsp;and any content you choose to use in
                          connection with those apps.&nbsp; If you are an
                          administrator, or a&nbsp;technical or billing contact
                          listed on an account, we share your details with the
                          third-party app provider upon
                          installation.&nbsp;Third-party app policies and
                          procedures are not controlled by us, and this privacy
                          policy does not cover how third-party apps use your
                          information. We encourage you to review the privacy
                          policies of third parties before connecting to or
                          using their applications or services to learn more
                          about their privacy and information handling
                          practices.&nbsp;If you object to information about you
                          being shared with these third parties, please
                          uninstall the app.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Links to Third Party Sites</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >: The Services may include links that direct you to
                          other websites or services whose privacy practices may
                          differ from ours. If you submit information to any of
                          those third-party sites, your information is governed
                          by their privacy policies, not this one. We encourage
                          you to carefully read the privacy policy of any
                          website you visit.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Social Media Widgets</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >: The Services may include links that direct you to
                          other websites or services whose privacy practices may
                          differ from ours. Your use of and any information you
                          submit to any of those third-party sites is governed
                          by their privacy policies, not this one.&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Third-Party Widgets</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >: Some of our Services contain widgets and social
                          media features. These widgets and features collect
                          your IP address, which page you are visiting on the
                          Services, and may set a cookie to enable the feature
                          to function properly. Widgets and social media
                          features are either hosted by a third party or hosted
                          directly on our Services. Your interactions with these
                          features are governed by the privacy policy of the
                          company providing it.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >With your consent</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >: We share information about you with third parties
                          when you give us consent to do so.&nbsp; For example,
                          we often display personal testimonials of satisfied
                          customers on our public websites. With your consent,
                          we may post your name alongside the
                          testimonial.&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><span
                            style="
                              font-size: 12pt;
                              font-family: 'Segoe UI' sans-serif;
                              color: #091e42;
                            "
                            >Sharing with affiliated companies</span
                          ></b
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >We share information we collect with affiliated
                          companies and, in some cases, with prospective
                          affiliates.&nbsp; Affiliated companies are companies
                          owned or operated by us.&nbsp; The protections of this
                          privacy policy apply to the information we share in
                          these circumstances.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Sophia Fiori companies</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >:&nbsp;We share information we have about you with
                          other Sophia Fiori corporate affiliates in order to
                          operate and improve products and services and to offer
                          other Sophia Fiori affiliated services to
                          you.&nbsp;This includes companies that own or operate
                          the Services.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Business Transfers</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >: We may share or transfer information we collect
                          under this privacy policy in connection with any
                          merger, sale of company assets, financing, or
                          acquisition of all or a portion of our business to
                          another company. You will be notified via email and/or
                          a prominent notice on the Services if a transaction
                          takes place, as well as any choices you may have
                          regarding your information.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 15pt;
                          margin-right: 0in;
                          margin-bottom: 15pt;
                          margin-left: 0in;
                          line-height: normal;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 16.5pt;
                            font-family: 'Segoe UI', sans-serif;
                            color: #253858;
                          "
                          >How we store and secure information we collect</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><span
                            style="
                              font-size: 12pt;
                              font-family: 'Segoe UI' sans-serif;
                              color: #091e42;
                            "
                            >Information storage and security</span
                          ></b
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >We use industry standard technical and organizational
                          measures to secure the information we store.&nbsp;For
                          more information on where and how we store your
                          information, please see the&nbsp;information security
                          trust&nbsp;pages on our site.&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >While we implement safeguards designed to protect
                          your information, no security system is impenetrable
                          and due to the inherent nature of the
                          Internet,&nbsp;we cannot guarantee that information,
                          during transmission through the Internet or while
                          stored on our systems or otherwise in our
                          care,&nbsp;is&nbsp;absolutely&nbsp;safe&nbsp;from&nbsp;intrusion
                          by others.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >If you use our server or data center Services,
                          responsibility for securing storage and access to the
                          information you put into the Services rests with you
                          and not Sophia Fiori. We strongly recommend that
                          server or data center users configure SSL to prevent
                          interception of information transmitted over networks
                          and to restrict access to the databases and other
                          storage points used.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><span
                            style="
                              font-size: 12pt;
                              font-family: 'Segoe UI' sans-serif;
                              color: #091e42;
                            "
                            >How long we keep information</span
                          ></b
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >How long we keep information we collect about you
                          depends on the type of information, as described in
                          further detail below.&nbsp; After such time, we will
                          either delete or anonymize your information or, if
                          this is not possible (for example, because the
                          information has been stored in backup archives), then
                          we will securely store your information and isolate it
                          from any further use until deletion is
                          possible.&nbsp;&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Account information</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >: We&nbsp;retain your account information for as long
                          as your account is active and a reasonable period
                          thereafter in case you decide to re-activate the
                          Services.&nbsp; We also retain some of your
                          information as necessary to comply with our legal
                          obligations, to resolve disputes, to enforce our
                          agreements, to support business operations, and to
                          continue to develop and improve our Services. Where we
                          retain information for Service improvement and
                          development, we take steps to eliminate information
                          that directly identifies you, and we only use the
                          information&nbsp;to uncover collective insights about
                          the use of our Services, not to specifically analyze
                          personal characteristics about you.&nbsp;&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Information you share on the Services</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >: If your account is deactivated or disabled, some of
                          your information and the content you have provided
                          will remain in order to allow your team members or
                          other users to make full use of the Services.&nbsp;
                          For example, we continue to display messages you sent
                          to the users that received them and continue to
                          display content you provided,&nbsp;but when requested
                          details that can identify you will be
                          removed.&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Managed&nbsp;accounts</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >: If the Services are made available to you through
                          an organization (e.g., your employer), we retain your
                          information as long as required by the administrator
                          of your account.&nbsp; For more information, see
                          "Managed accounts and administrators" above.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Marketing&nbsp;information</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >: If you have elected to receive marketing emails
                          from us, we retain information about your marketing
                          preferences for a reasonable period of time from the
                          date you last expressed interest in our Services, such
                          as when you last opened an email from us or ceased
                          using your Sophia Fiori account.&nbsp; We retain
                          information derived from cookies and other tracking
                          technologies for a reasonable period of time from the
                          date such information was created.&nbsp; &nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 15pt;
                          margin-right: 0in;
                          margin-bottom: 15pt;
                          margin-left: 0in;
                          line-height: normal;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 16.5pt;
                            font-family: 'Segoe UI', sans-serif;
                            color: #253858;
                          "
                          >How to access and control your information</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >You have certain choices available to you when it
                          comes to your information. Below is a summary of those
                          choices, how to exercise them and any
                          limitations.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><span
                            style="
                              font-size: 12pt;
                              font-family: 'Segoe UI' sans-serif;
                              color: #091e42;
                            "
                            >Your Choices</span
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >:&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >You have the right to request a copy of your
                          information, to object to our use of your information
                          (including for marketing purposes), to request the
                          deletion or restriction of your information, or to
                          request your information in a structured, electronic
                          format.&nbsp; Below, we describe the tools and
                          processes for making these requests.&nbsp; You can
                          exercise some of the choices by logging into the
                          Services and using settings available within the
                          Services or your account.&nbsp;Where the Services are
                          administered for you by an administrator (see "Notice
                          to End Users" below), you may need to contact your
                          administrator to assist with your requests
                          first.&nbsp;&nbsp;For all other requests, you may
                          contact us as provided in the Contact Us section
                          below&nbsp;to request assistance.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >Your request and choices may be limited in certain
                          cases: for example, if fulfilling your request would
                          reveal information about another person, or if you ask
                          to delete information which we or your administrator
                          are permitted by law or have compelling legitimate
                          interests to keep.&nbsp; Where you have asked us to
                          share data with third parties, for example, by
                          installing third-party apps, you will need to contact
                          those third-party service providers directly to have
                          your information deleted or otherwise
                          restricted.&nbsp; If you have unresolved concerns, you
                          may have the right to complain to a data protection
                          authority in the country where you live, where you
                          work or where you feel your rights were
                          infringed.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Access and update your information</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >:&nbsp;Our Services and related </span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI', sans-serif;
                            color: black;
                          "
                          >documentation&nbsp;(see&nbsp;Sophia Fiori
                          Documentation) give you </span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >the ability&nbsp;to&nbsp;access and update certain
                          information about you from within the Service. For
                          example, you can access your profile information from
                          your account and search for content containing
                          information about you using key word searches in the
                          Service.&nbsp; You can update your profile information
                          within your profile settings and modify content that
                          contains information about you using the editing tools
                          associated with that content.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Deactivate your&nbsp;account</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >:&nbsp;&nbsp;If you no longer wish to use our
                          Services, you or your administrator may be able to
                          deactivate your Services account. If you can
                          deactivate your own account, that setting
                          is&nbsp;available to you in your account settings.
                          Otherwise, please contact your administrator. If you
                          are an administrator and are unable to deactivate an
                          account through your administrator settings, please
                          contact the appropriate support </span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI', sans-serif;
                            color: black;
                          "
                          >team (see&nbsp;the
                          <a href="https://support.atlassian.com/"
                            ><span style="color: black; text-decoration: none"
                              >Sophia Fiori</span
                            ></a
                          >
                          support pages).&nbsp; Please </span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >be aware that&nbsp;deactivating your account does not
                          delete your information; your information remains
                          visible to&nbsp;other&nbsp;Service users based on your
                          past participation within the Services.&nbsp; For more
                          information on how to delete your information, see
                          below.&nbsp; &nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Delete your information</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >: Our Services and related </span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI', sans-serif;
                            color: black;
                          "
                          >documentation&nbsp;(see&nbsp;Sophia Fiori
                          Documentation) give you the ability </span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >to delete certain information about you from within
                          the Service. For example, you can remove content that
                          contains information about you using the key word
                          search and editing tools associated with that content,
                          and you can remove certain profile information within
                          your profile settings. Please note, however, that we
                          may need to retain certain information for record
                          keeping purposes, to complete transactions or to
                          comply with our legal obligations.&nbsp; &nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Request that we stop using your information</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >:&nbsp;&nbsp;In some cases, you may ask us to stop
                          accessing, storing, using and otherwise processing
                          your information where you believe we don't have the
                          appropriate rights to do so.&nbsp; For example, if you
                          believe a Services account was created for you without
                          your permission or you are no longer an active user,
                          you can request that we delete your account as
                          provided in this policy.&nbsp;&nbsp;Where you gave us
                          consent to use your information for a limited purpose,
                          you can contact us to withdraw that consent, but this
                          will not affect any processing that has already taken
                          place at the time. You can also opt-out of our use of
                          your information for marketing purposes by contacting
                          us, as provided below.&nbsp;&nbsp;When you make such
                          requests, we may need time to investigate and
                          facilitate your request.&nbsp; If there is delay or
                          dispute as to whether we have the right to continue
                          using your information, we will restrict any further
                          use of your information until the request is honored
                          or the dispute is resolved, provided your
                          administrator does not object (where
                          applicable).&nbsp;&nbsp;If you object to information
                          about you being shared with a third-party app, please
                          disable the app or contact your administrator to do
                          so.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Opt out of&nbsp;communications</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >:&nbsp;You may opt out of receiving promotional
                          communications from us by using the unsubscribe link
                          within each email, updating your email
                          preferences&nbsp;within your&nbsp;Service account
                          settings menu, or by contacting us as provided below
                          to have your contact information removed from our
                          promotional email list or registration database.&nbsp;
                          Even after you opt out from receiving promotional
                          messages from us, you will continue to receive
                          transactional messages from us regarding
                          our&nbsp;Services. You can opt out of some
                          notification messages in your account
                          settings.&nbsp;Please note, you will continue to
                          receive generic ads.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >You may be able to opt out of receiving personalized
                          advertisements from other companies who are members of
                          the Network Advertising Initiative or who subscribe to
                          the Digital Advertising Alliance's Self-Regulatory
                          Principles for Online Behavioral Advertising. For more
                          information about this practice and to understand your
                          options, please visit:&nbsp;<a
                            href="http://www.aboutads.info/"
                            ><span style="color: #0052cc; text-decoration: none"
                              >http://www.aboutads.info</span
                            ></a
                          >,&nbsp;<a
                            href="http://optout.networkadvertising.org/"
                            ><span style="color: #0052cc; text-decoration: none"
                              >http://optout.networkadvertising.org/</span
                            ></a
                          >&nbsp;and&nbsp;<br />
                          <a href="http://www.youronlinechoices.eu/"
                            ><span style="color: #0052cc; text-decoration: none"
                              >http://www.youronlinechoices.eu</span
                            ></a
                          >.&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Turn off Cookie Controls</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >:&nbsp;Relevant browser-based cookie controls are </span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI', sans-serif;
                            color: black;
                          "
                          >described in our&nbsp;Cookies &amp; Tracking
                          Notice.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Send "Do Not Track" Signals</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >: Some browsers have incorporated "Do Not Track"
                          (DNT) features that can send a signal to the websites
                          you visit indicating you do not wish to be tracked.
                          Because there is not yet a common understanding of how
                          to interpret the DNT signal, our Services do not
                          currently respond to browser DNT signals. You can use
                          the range of other tools we provide to control data
                          collection and use, including the ability to opt out
                          of receiving marketing from us&nbsp;as described
                          above.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Data&nbsp;portability</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >: Data portability is the ability to obtain some of
                          your information in a format you can move from one
                          service provider to another (for instance, when you
                          transfer your mobile phone number to another
                          carrier).&nbsp; Depending on the context, this applies
                          to some of your information, but not to all of your
                          information.&nbsp; Should you request it, we will
                          provide you with an electronic file of your basic
                          account information and the information you create on
                          the spaces under your sole&nbsp;control, like your
                          personal Bitbucket repository.&nbsp;&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 15pt;
                          margin-right: 0in;
                          margin-bottom: 15pt;
                          margin-left: 0in;
                          line-height: normal;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 16.5pt;
                            font-family: 'Segoe UI', sans-serif;
                            color: #253858;
                          "
                          >How we transfer information we collect
                          internationally</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><span
                            style="
                              font-size: 12pt;
                              font-family: 'Segoe UI' sans-serif;
                              color: #091e42;
                            "
                            >International transfers of information we
                            collect</span
                          ></b
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >We collect information globally and may transfer,
                          process and store&nbsp;your information outside of
                          your country of residence, to wherever we or our
                          third-party service providers operate for the purpose
                          of providing you the Services.&nbsp; Whenever we
                          transfer your information, we take steps to protect
                          it.&nbsp;&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >International transfers within the Sophia Fiori
                              Companies</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >:&nbsp;To facilitate our global operations, we
                          transfer information globally and allow access to that
                          information from countries in which the Sophia Fiori
                          owned or operated companies have operations for the
                          purposes described in this policy. These countries may
                          not have equivalent privacy and data protection laws
                          to the laws of many of the countries where our
                          customers and users are based. When we share
                          information about you&nbsp;within and
                          among&nbsp;Sophia Fiori corporate affiliates, we make
                          use of standard contractual data protection clauses,
                          which have been approved by the European Commission,
                          and we rely on the EU-U.S. and Swiss-U.S. Privacy
                          Shield Framework to safeguard the transfer of
                          information we collect from the European Economic
                          Area, the United Kingdom (the "UK"), and Switzerland.
                          Please see our Privacy Shield notice below for more
                          information&nbsp;or contact us as provided
                          below.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >International transfers to third parties</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >: Some of the third parties described in this privacy
                          policy, which provide services to us under contract,
                          are based in&nbsp;other countries that may not have
                          equivalent privacy and data protection laws to the
                          country in which you reside. When we share information
                          of customers in the European Economic Area, the UK, or
                          Switzerland, we make use of the EU-U.S. and Swiss-U.S.
                          Privacy Shield Frameworks, European
                          Commission-approved standard contractual data
                          protection clauses,&nbsp;binding corporate rules for
                          transfers to data processors, or other appropriate
                          legal mechanisms to safeguard the
                          transfer.&nbsp;Please see our Privacy Shield Notice
                          below.&nbsp;&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 15pt;
                          margin-right: 0in;
                          margin-bottom: 15pt;
                          margin-left: 0in;
                          line-height: normal;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 16.5pt;
                            font-family: 'Segoe UI', sans-serif;
                            color: #253858;
                          "
                          >&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 15pt;
                          margin-right: 0in;
                          margin-bottom: 15pt;
                          margin-left: 0in;
                          line-height: normal;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 16.5pt;
                            font-family: 'Segoe UI', sans-serif;
                            color: #253858;
                          "
                          >Other important privacy information</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><span
                            style="
                              font-size: 12pt;
                              font-family: 'Segoe UI' sans-serif;
                              color: #091e42;
                            "
                            >Notice to End Users</span
                          ></b
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >Many of our products are intended for use by
                          organizations. Where the Services are made available
                          to you through an organization&nbsp;(e.g. your
                          employer), that organization is the administrator of
                          the Services and is responsible for the accounts
                          and/or Service sites&nbsp;over which it has control.
                          If this is the case, please direct your data privacy
                          questions to your administrator, as your use of the
                          Services is subject to that organization's policies.
                          We are not responsible&nbsp;for the privacy or
                          security practices of an administrator's organization,
                          which may be different than this policy.&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >Administrators&nbsp;are able to:</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 7.5pt;
                          margin-right: 0in;
                          margin-bottom: 0in;
                          margin-left: 15pt;
                          margin-bottom: 0.0001pt;
                          text-indent: 0in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 10pt;
                            font-family: Symbol;
                            color: #091e42;
                          "
                          >·<span style='font: 7.0pt "Times New Roman&quot'
                            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span></span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >require you to reset your account password;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 7.5pt;
                          margin-right: 0in;
                          margin-bottom: 0in;
                          margin-left: 15pt;
                          margin-bottom: 0.0001pt;
                          text-indent: 0in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 10pt;
                            font-family: Symbol;
                            color: #091e42;
                          "
                          >·<span style='font: 7.0pt "Times New Roman&quot'
                            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span></span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >restrict, suspend or terminate your access to the
                          Services;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 7.5pt;
                          margin-right: 0in;
                          margin-bottom: 0in;
                          margin-left: 15pt;
                          margin-bottom: 0.0001pt;
                          text-indent: 0in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 10pt;
                            font-family: Symbol;
                            color: #091e42;
                          "
                          >·<span style='font: 7.0pt "Times New Roman&quot'
                            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span></span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >access information in and about your account;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 7.5pt;
                          margin-right: 0in;
                          margin-bottom: 0in;
                          margin-left: 15pt;
                          margin-bottom: 0.0001pt;
                          text-indent: 0in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 10pt;
                            font-family: Symbol;
                            color: #091e42;
                          "
                          >·<span style='font: 7.0pt "Times New Roman&quot'
                            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span></span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >access or retain information stored as part of your
                          account;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 7.5pt;
                          margin-right: 0in;
                          margin-bottom: 0in;
                          margin-left: 15pt;
                          margin-bottom: 0.0001pt;
                          text-indent: 0in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 10pt;
                            font-family: Symbol;
                            color: #091e42;
                          "
                          >·<span style='font: 7.0pt "Times New Roman&quot'
                            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span></span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >install or uninstall third-party apps or other
                          integrations&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >In some cases, administrators can also:&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 7.5pt;
                          margin-right: 0in;
                          margin-bottom: 0in;
                          margin-left: 15pt;
                          margin-bottom: 0.0001pt;
                          text-indent: 0in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 10pt;
                            font-family: Symbol;
                            color: #091e42;
                          "
                          >·<span style='font: 7.0pt "Times New Roman&quot'
                            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span></span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >restrict, suspend or terminate your account
                          access;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 7.5pt;
                          margin-right: 0in;
                          margin-bottom: 0in;
                          margin-left: 15pt;
                          margin-bottom: 0.0001pt;
                          text-indent: 0in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 10pt;
                            font-family: Symbol;
                            color: #091e42;
                          "
                          >·<span style='font: 7.0pt "Times New Roman&quot'
                            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span></span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >change the email address associated with your
                          account;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 7.5pt;
                          margin-right: 0in;
                          margin-bottom: 0in;
                          margin-left: 15pt;
                          margin-bottom: 0.0001pt;
                          text-indent: 0in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 10pt;
                            font-family: Symbol;
                            color: #091e42;
                          "
                          >·<span style='font: 7.0pt "Times New Roman&quot'
                            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span></span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >change your information, including profile
                          information;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-top: 7.5pt;
                          margin-right: 0in;
                          margin-bottom: 0in;
                          margin-left: 15pt;
                          margin-bottom: 0.0001pt;
                          text-indent: 0in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 10pt;
                            font-family: Symbol;
                            color: #091e42;
                          "
                          >·<span style='font: 7.0pt "Times New Roman&quot'
                            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span></span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >restrict your ability to edit, restrict, modify or
                          delete information</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >Even if the Services are not currently administered
                          to you by an organization, if you&nbsp;are a member of
                          a Trello team administered by an organization, or if
                          you use an email address provided by an organization
                          (such as your work email address) to access the
                          Services, then the owner of the domain associated with
                          your email address (e.g. your employer)&nbsp;may
                          assert administrative control over your account and
                          use of the Services at a later date.&nbsp; You will be
                          notified if this happens.&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >If you do not want an administrator to be able to
                          assert control over your account or use of the
                          Services, you should deactivate your membership with
                          the relevant Trello board, team or enterprise, or use
                          your personal email address to register for or access
                          the Services.&nbsp; If an administrator has not
                          already asserted control over your account or access
                          to the Services, you can update the email address
                          associated with your account through your account
                          settings in your&nbsp;profile.&nbsp; Once an
                          administrator asserts control over your account or use
                          of the Services, you will no longer be able to change
                          the email address associated with your account without
                          administrator approval.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >Please contact your organization or refer to your
                          administrator’s organizational policies for more
                          information.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><span
                            style="
                              font-size: 12pt;
                              font-family: 'Segoe UI' sans-serif;
                              color: #091e42;
                            "
                            >California Requirements</span
                          ></b
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Exercising your rights</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >: If you are a California resident, there are some
                          additional rights that may be available to you under
                          the California Consumer Protection Act (“CCPA”). This
                          policy explains the tools that we have made available
                          to you to exercise your data rights under the CCPA,
                          such as the right to deletion and the right to request
                          access to the categories of information we have
                          collected about you. For more information on how to
                          exercise your rights please visit the “<a
                            href="https://sophiafiori.com/PrivacyPolicySF.aspx#how-to-access-and-control-your-information"
                            ><span style="color: #0052cc; text-decoration: none"
                              >How to access and control your information</span
                            ></a
                          >” section of this policy. We encourage you to manage
                          your information, and to make use of the privacy
                          controls we have included in our Services.&nbsp;You
                          will not be discriminated against for exercising any
                          of your privacy rights under the CCPA.&nbsp;In order
                          to protect your information from unauthorized access
                          or deletion, we may require you to provide additional
                          information for verification. If we cannot verify your
                          identity, we will not provide or delete your
                          information.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Sharing your personal information</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >: We don't sell your personal information. We do
                          share your information with others as described in the
                          “<a
                            href="https://sophiafiori.com/PrivacyPolicySF.aspx#how-we-share-information-we-collect"
                            ><span style="color: #0052cc; text-decoration: none"
                              >How we share information we collect</span
                            ></a
                          >” section of this policy. We also show ads that we
                          think are relevant. We've provided more information
                          about how you can manage your advertising, and
                          do-not-track preferences, within this policy.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><i
                            ><span
                              style="
                                font-size: 12pt;
                                font-family: 'Segoe UI' sans-serif;
                                color: #091e42;
                              "
                              >Processing your information</span
                            ></i
                          ></b
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >: This policy describes the categories of personal
                          information we may collect, the sources of that
                          information, and our deletion and retention
                          policies.&nbsp;We’ve also included information about
                          how we may process your information, which includes
                          for "business purposes" under the CCPA&nbsp;- such as
                          to protect against illegal activities, and for the
                          development of new products, features, and
                          technologies.&nbsp; If you have questions about the
                          categories of information we may collect about you,
                          please be sure to visit the section of this policy
                          called, “<a
                            href="https://sophiafiori.com/PrivacyPolicySF.aspx#what-information-we-collect-about-you"
                            ><span style="color: #0052cc; text-decoration: none"
                              >What information we collect about you.</span
                            ></a
                          >”&nbsp;For more details about our processing
                          activities, please be sure to visit the section
                          called, “<a
                            href="https://sophiafiori.com/PrivacyPolicySF.aspx#how-we-use-information-we-collect"
                            ><span style="color: #0052cc; text-decoration: none"
                              >How we use information we collect.</span
                            ></a
                          >”</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >If you have any questions or would like to exercise
                          your rights under the CCPA, you can reach out to us at
                          privacy@Sophiafiori.com.&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><span
                            style="
                              font-size: 12pt;
                              font-family: 'Segoe UI' sans-serif;
                              color: #091e42;
                            "
                            >Our policy towards children</span
                          ></b
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >The Services are not directed to individuals under
                          16. We do not knowingly collect&nbsp;personal
                          information&nbsp;from children under 16.&nbsp;If we
                          become aware that a child under 16 has provided us
                          with personal information, we will take steps to
                          delete such information. If you become aware that a
                          child has provided us with personal information,
                          please contact the appropriate support team (</span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI', sans-serif;
                            color: black;
                          "
                          >see&nbsp;Sophia Fiori support </span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >pages).&nbsp;</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><span
                            style="
                              font-size: 12pt;
                              font-family: 'Segoe UI' sans-serif;
                              color: #091e42;
                            "
                            >Changes to our Privacy Policy</span
                          ></b
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >We may change this privacy policy from time to
                          time.&nbsp;We will post any privacy policy changes on
                          this page and, if the changes are significant, we will
                          provide a more prominent notice&nbsp;by adding a
                          notice on the Services homepages, login screens, or by
                          sending you an email notification. We&nbsp;will also
                          keep prior versions of this Privacy Policy in an
                          archive for your review.&nbsp; We&nbsp;encourage you
                          to review our privacy policy whenever you use the
                          Services to stay informed about our information
                          practices and the ways you can help protect your
                          privacy.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >If you disagree with any changes to this privacy
                          policy, you will need to stop using the Services and
                          deactivate your account(s), as outlined above.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <b
                          ><span
                            style="
                              font-size: 12pt;
                              font-family: 'Segoe UI' sans-serif;
                              color: #091e42;
                            "
                            >Contact Us</span
                          ></b
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0.25in;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >Your information&nbsp;is controlled by Sophia Fiori
                          Pty Ltd and Sophia Fiori, Inc. &nbsp;If you have
                          questions or concerns about how your
                          information&nbsp;is handled, please direct your
                          inquiry to Sophia Fiori BH LTD, which we have
                          appointed to be responsible for facilitating such
                          inquiries or, if you are a resident of the European
                          Economic Area, please contact our EU
                          Representative.</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >Gold Brick Publishing<br />
                          c/o Sophia Fiori, Inc.<br />
                          910 N Amelia Ave</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >San Dimas, CA 91773</span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >E-Mail:&nbsp;</span
                        ><span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI', sans-serif;
                            color: #0052cc;
                          "
                          ><a href="mailto:privacy@sophiafiori.com"
                            >privacy@sophiafiori.com</a
                          ></span
                        >
                      </p>

                      <p
                        class="MsoNormal"
                        style="
                          margin-bottom: 0in;
                          margin-bottom: 0.0001pt;
                          line-height: 21pt;
                          background: white;
                          vertical-align: baseline;
                        "
                      >
                        <span
                          style="
                            font-size: 12pt;
                            font-family: 'Segoe UI' sans-serif;
                            color: #091e42;
                          "
                          >&nbsp;</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a href="javascript:void(0)" class="btn btn-primary">Print</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Privacy -->

<!-- Back to top -->
<a
  href="javascript: void(0);"
  class="btn btn-icon btn-soft-primary back-to-top"
  id="back-to-top"
  [ngxScrollTo]="'#home'"
>
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
