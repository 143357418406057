import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  Channel,
  Course,
  DataserviceService,
  Package,
} from "src/app/service/dataservice.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-index-course",
  templateUrl: "./index-course.component.html",
  styleUrls: ["./index-course.component.css"],
})
export class IndexCourseComponent implements OnInit {
  authors: Array<any> = [];
  channelList: Array<Channel> = [];
  coursesList: Array<Course> = [];
  campaignList: Array<any> = [];
  pageIndex: number = 1;
  pageSize: number = 10;
  pageInx: Array<number> = [];
  callStack: number = 0;

  packageData: any = {
    isPackageLoaded: false,
    packageList: [],
  };
  courseData: any = {
    coursesList: [],
    isCoursesLoaded: false,
  };
  constructor(
    private modalService: NgbModal,
    private dataService: DataserviceService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  navClass = "nav-light";
  showNavigationArrows = false;
  showNavigationIndicators = false;

  ngOnInit(): void {
    this.route.params.subscribe((param) => {
      if (param.id) {
      }
    });
    this.getPopularCourses(1, 12);
  }

  nextPage(): void {
    this.pageIndex = this.pageIndex + 1;
    this.getPopularCourses(this.pageIndex, 12);
  }
  prevPage(): void {
    if (this.pageIndex != 1) {
      this.pageIndex = this.pageIndex - 1;
      this.getPopularCourses(this.pageIndex, 12);
    }
  }
  onPageIndex(index: number): void {
    this.pageIndex = index;
    this.getPopularCourses(index, 16);
  }

  getChannelList(): void {
    this.dataService.getChannelList(1, 4).subscribe(
      (response) => {
        this.channelList = response;
        if (this.channelList.length > 0) this.getPopularCourses(1, 4);
      },
      (error) => {
        this.callStack++;
        if (this.callStack <= 3) this.getChannelList();
      }
    );
  }
  routeToMorechannels(): void {
    this.router.navigateByUrl("/channels");
  }
  redirectToViewMoreCourse(): void {
    this.router.navigateByUrl("/courses/");
  }
  redirectToViewMoreAuthor(): void {
    this.router.navigateByUrl("/Instructors");
  }
  navidateToViewAll(): void {
    this.router.navigateByUrl("/packages");
  }

  totalIndex: any;
  getPopularCourses(pageNo: number, pageSize: number): void {
    this.pageInx = [];
    this.dataService.getPopularCourses(pageNo, pageSize).subscribe(
      (response) => {
        this.courseData.isCoursesLoaded = true;
        this.courseData.coursesList = response.map((coures) => {
          coures.CourseDescription = coures.CourseDesc;
          coures.CourseLessons = coures.EnrollCount;
          coures.EnrollCnt = coures.EnrollCount;
          coures.web_price = coures.web_price;
          return coures;
        });
        //CourseCount
        if (this.courseData.coursesList.length > 0) {
          let course: any = this.courseData.coursesList[0];
          let couseCount = course.CourseCount;

          if (couseCount > 16) {
            let pageIndex: any = couseCount / 16;
            if (pageIndex % 1 != 0) {
              ++pageIndex;
            }
            for (let i = 1; i <= pageIndex; i++) {
              this.pageInx.push(i);
            }
            this.totalIndex = pageIndex;
          }
        }
        this.getPackageList();
        //  this.getAuthor();
      },
      (error) => {
        this.courseData.isCoursesLoaded = true;
        this.callStack++;
        if (this.callStack <= 3) this.getPopularCourses(1, 10);
      }
    );
  }

  getAuthor(): void {
    this.dataService.getPopularAuthor().subscribe((response) => {
      this.authors = response;
      this.authors = this.authors.splice(0, 4);
    });
  }

  getPackageList(): void {
    this.dataService.getPackageList().subscribe(
      (response) => {
        this.packageData.packageList = response;
        if (this.packageData.packageList.length > 3) {
          this.packageData.packageList = this.packageData.packageList.splice(
            0,
            3
          );
        }

        this.packageData.isPackageLoaded = true;
      },
      (error) => {
        this.packageData.isPackageLoaded = true;
      }
    );
  }
  /**
   * Open modal for show the video
   * @param content content of modal
   */
  openWindowCustomClass(content) {
    this.modalService.open(content, {
      windowClass: "dark-modal",
      size: "lg",
      centered: true,
    });
  }
}
