import { Component, OnInit } from '@angular/core';
import { DataserviceService, Package } from 'src/app/service/dataservice.service';

@Component({
  selector: 'app-view-all-packages',
  templateUrl: './view-all-packages.component.html',
  styleUrls: ['./view-all-packages.component.css']
})
export class ViewAllPackagesComponent implements OnInit {

  packageList:Array<Package> = [];

  packageData:any = {
    packageList:[],
    isPackageLoaded:false
  }
  constructor(
    private dataService:DataserviceService
  ) { }

  ngOnInit(): void {
    this.getAllPackages();
  }

  getAllPackages() : void {
    this.dataService.getPackageList().subscribe(
      (response)=>{
        this.packageData.packageList = response;
        this.packageData.isPackageLoaded = true;
      },
      (error)=>{
        this.packageData.isPackageLoaded = true;
      }
    )
  }
}
