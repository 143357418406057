<div class="container mt-100 mt-60" id="instructors">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Instructors</h4>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-3 col-md-6 mt-4 pt-2" *ngFor="let aut of authors">
        <div class="card team text-center rounded border-0">
          <div class="card-body">
            <div class="position-relative">
              <img [src]="fileUrl+'/Uploads/authors/'+aut.PicGUID+'.jpg'" class="img-fluid avatar avatar-ex-large rounded-circle shadow"
                alt="">
              <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
              </ul>
              <!--end icon-->
            </div>
            <div class="content pt-3">
              <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">{{ aut.FName}} {{aut.LName}}</a></h5>
              <small class="designation text-muted">{{ aut.AuthProfile}}</small>
            </div>
          </div>
        </div>
      </div>
    
      <!--end col-->
    </div>
    <!--end row-->
  </div>