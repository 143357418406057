<!-- Hero Start -->
<section class="bg-half-260 pb-lg-0 pb-md-4 bg-primary d-table w-100" id="home">
  <div class="bg-overlay bg-black" style="opacity: 0.8"></div>
  <div class="container">
    <div class="row position-relative" style="z-index: 1">
      <div class="col-md-7 col-12 mt-lg-5">
        <div class="title-heading">
          <h1 class="heading text-white title-dark mb-4">
            {{ channel.C_Name }}
          </h1>
          <p class="para-desc text-white-50">
            {{ channel.Description }}
          </p>
          <div class="watch-video mt-4 pt-2"></div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-5 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="shape-before">
          <div class="carousel-cell">
            <img
              [src]="
                viewUrl + '/Uploads/Channels/' + channel.ThumbnailGuid + '.jpg'
              "
              class="img-fluid rounded-md"
              alt=""
            />
          </div>
          <img
            src="assets/images/shapes/shape1.png"
            class="img-fluid shape-img"
            alt=""
          />
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<!-- Start -->
<app-instructor-list [authors]="authors"></app-instructor-list>

<div class="col-12 mt-4 pt-2 text-center">
  <a routerLink="/Instructors/{{ channelId }}" class="btn btn-outline-primary"
    >See More <i class="mdi mdi-chevron-right"></i
  ></a>
</div>

<app-category-list [category]="category"></app-category-list>
<br />
<div class="col-12 mt-4 pt-2 text-center">
  <a (click)="loadMoreCategory()" class="btn btn-outline-primary"
    >See More <i class="mdi mdi-chevron-down"></i
  ></a>
</div>
<br />
<app-courses-list [courses]="coursesList"></app-courses-list>

<div class="col-12 mt-4 pt-2 text-center">
  <a (click)="redirectToViewMoreCourse()" class="btn btn-outline-primary"
    >See More <i class="mdi mdi-chevron-right"></i
  ></a>
</div>

<br />

<!--end section-->
<!-- End -->

<!-- Back to top -->
<a
  href="javascript: void(0);"
  class="btn btn-icon btn-soft-primary back-to-top"
  id="back-to-top"
  [ngxScrollTo]="'#home'"
>
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
