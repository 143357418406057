<!-- Hero Start -->
<section class="bg-home d-flex align-items-center">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6">
        <div class="mr-lg-5" *ngIf="!courseGUID">
          <img
            src="assets/images/user/recovery.svg"
            class="img-fluid d-block mx-auto"
            alt=""
          />
        </div>

        <div *ngIf="courseGUID">
          <app-course-to-buy [courseGUID]="courseGUID"></app-course-to-buy>
        </div>
      </div>
      <div class="col-lg-5 col-md-6">
        <div class="card login_page shadow rounded border-0">
          <div class="card-body">
            <h4 class="card-title text-center">Recover Account</h4>
            <div
              class="card"
              *ngIf="msg.message != '' && msg.status"
              style="border: 1px solid #6ac1b8"
            >
              <div class="card-body">
                <h4 class="textPR">
                  {{ msg.message }}
                </h4>
              </div>
            </div>

            <div *ngIf="!msg.status">
              <!-- for email -->
              <div class="row" *ngIf="data.step.step === 1">
                <div class="col-lg-12">
                  <p class="text-muted">
                    Please enter your email address. You will receive a link to
                    create a new password via email.
                  </p>
                  <div class="form-group position-relative">
                    <label
                      >Email address <span class="text-danger">*</span></label
                    >
                    <i-feather
                      name="mail"
                      class="fea icon-sm icons"
                    ></i-feather>
                    <input
                      type="email"
                      [(ngModel)]="email"
                      name="email"
                      class="form-control pl-5"
                      placeholder="Enter Your Email Address"
                      name="email"
                      required=""
                      autofocus
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <button
                    class="btn btn-primary btn-block"
                    (click)="submitForm()"
                  >
                    Send
                  </button>
                </div>
                <div class="mx-auto">
                  <p class="mb-0 mt-3">
                    <small class="text-dark mr-2"
                      >Remember your password ?</small
                    >
                    <a
                      (click)="redirectToSignIn()"
                      class="text-dark font-weight-bold"
                      >Sign in</a
                    >
                  </p>
                </div>
              </div>
              <!-- for OTP -->
              <div class="row" *ngIf="data.step.step === 2">
                <div class="col-lg-12">
                  <div class="form-grop">
                    <label>Enter OTP:</label>
                    <input
                      type="number"
                      [(ngModel)]="otp"
                      class="form-control"
                      name="otp"
                      autofocus
                      placeholder="Enter OTP that sent on your email"
                    />
                  </div>
                  <br />
                </div>
                <div class="col-lg-12">
                  <button class="btn btn-primary btn-block" (click)="addOTP()">
                    Submit
                  </button>

                  <div class="mx-auto w-100">
                    <p class="mb-0 mt-3 float-right">
                
                      <a
                        (click)="submitForm()"
                        class="text-info signIn font-weight-bold po"
                        style="cursor: pointer;"
                        >Resend OTP</a
                      >
                    </p>
                  </div>

                </div>
              </div>
              <!-- password -->

              <div class="row" *ngIf="data.step.step === 3">
                <div class="col-lg-12">
                  <div class="form-grop">
                    <label>Enter new password:</label>
                    <input
                      type="password"
                      [(ngModel)]="password"
                      class="form-control"
                      name="otp"
                      placeholder="new password"
                      autofocus
                    />
                  </div>
                  <br />
                </div>

                <div class="col-lg-12">
                  <div class="form-grop">
                    <label>Confirm password:</label>
                    <input
                      type="password"
                      [(ngModel)]="confirmPassword"
                      class="form-control"
                      name="otp"
                      placeholder="Confirm password"
                    />
                  </div>
                  <p class="text-danger" *ngIf="confirmPassword != password">
                    Password not match.
                  </p>
                </div>

                <div class="col-lg-12">
                  <br />
                  <button
                    class="btn btn-primary btn-block"
                    *ngIf="confirmPassword === password"
                    (click)="resetPassword()"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
            <br />
            <div
              class="alert alert-danger"
              *ngIf="!msg.status && msg.message != ''"
            >
              {{ msg.message }}
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->
