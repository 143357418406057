import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthenticationService } from "src/app/service/authentication.service";

@Component({
  selector: "app-auth-opt-verificication",
  templateUrl: "./auth-opt-verificication.component.html",
  styleUrls: ["./auth-opt-verificication.component.css"],
})
export class AuthOptVerificicationComponent implements OnInit {
  OTPVal: any = "";
  email: string = "";
  message: string = "";

  returnUrl: string = "";
  constructor(
    private _route: ActivatedRoute,
    private _authService: AuthenticationService,
    private _router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this._route.params.subscribe((param) => {
      this.email = param.email;
    });
    this.returnUrl =
      this.route.snapshot.queryParams["returnUrl"] || "/auth/login";
  }

  verifyOTP(): void {
    this._authService
      .otpVerification(this.email, this.OTPVal)
      .subscribe((response) => {
        if (response === -1) this.message = "Error :: OTP Expired.";
        else if (response === 1) {
          this.message = "Sucess:: OTP verified successfully.";
          setTimeout(() => {
            this._router.navigateByUrl(this.returnUrl);
          }, 2000);
        } else if (response === -2)
          this.message = "Error :: Invalid email address.";
      });
  }
}
