<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title">{{ title }}</h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul
                *ngIf="channel"
                class="breadcrumb bg-white rounded shadow mb-0"
              >
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item">
                  <a routerLink="/browse/{{ channel.C_ID }}">Browse</a>
                </li>
                <li class="breadcrumb-item active">
                  <a>
                    {{ title }}
                  </a>
                </li>
              </ul>
              <ul
                *ngIf="!channel"
                class="breadcrumb bg-white rounded shadow mb-0"
              >
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item"><a>Browse</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
        fill="currentColor"
      ></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<!-- Start Works -->
<section class="section" id="courseSec">
  <div class="container">
    <div class="projects-wrapper">
      <app-channel-list
        *ngIf="channelList.length > 0"
        [channelList]="channelList"
      ></app-channel-list>

      <app-courses-list
        *ngIf="coursesList.length > 0"
        [courses]="coursesList"
      ></app-courses-list>

      <app-instructor-list
        *ngIf="authorList.length > 0"
        [authors]="authorList"
      ></app-instructor-list>

      <!-- PAGINATION START -->
      <br />
      <div class="col-12">
        <ul class="pagination justify-content-center mb-0">
          <li class="page-item">
            <a class="page-link" (click)="prevPage()" aria-label="Previous"
              >Prev</a
            >
          </li>
          <li class="page-item" [ngClass]="pageIndex == 1 ? 'active' : ''">
            <a class="page-link" (click)="onPageIndex(1)">1</a>
          </li>
          <li class="page-item" [ngClass]="pageIndex == 2 ? 'active' : ''">
            <a class="page-link" (click)="onPageIndex(2)">2</a>
          </li>
          <li class="page-item" [ngClass]="pageIndex == 3 ? 'active' : ''">
            <a class="page-link" (click)="onPageIndex(3)">3</a>
          </li>
          <li class="page-item" [ngClass]="pageIndex == 4 ? 'active' : ''">
            <a class="page-link" (click)="onPageIndex(4)">4</a>
          </li>

          <li class="page-item">
            <a class="page-link" (click)="nextPage()" aria-label="Next">Next</a>
          </li>
        </ul>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
