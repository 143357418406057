<!-- Hero Start -->
<section
  class="bg-profile d-table w-100 bg-primary"
  id="home"
  style="background: url('assets/images/account/bg.png') center center;"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div
          class="card public-profile border-0 rounded shadow"
          style="z-index: 1;"
        >
          <div class="card-body">
            <div class="row align-items-center">
              <!-- <div class="col-lg-2 col-md-3 text-md-left text-center">
                  <img src="assets/images/user_mask.JPG"
                    class="avatar avatar-large rounded-circle shadow d-block mx-auto" alt="">
                </div> -->
              <!--end col-->

              <div class="col-lg-10 col-md-9">
                <div class="row align-items-end" *ngIf="!editProfile">
                  <div
                    class="col-md-7 text-md-left text-center mt-4 mt-sm-0 empty-section"
                  >
                    <div class="short-name-con">
                      <div>
                        <b class="short-name-round">
                          {{ short_name?short_name:'NM' }}
                        </b>
                      </div>
                      <div class="user-full-name" *ngIf="!userDetails">
                        <ngx-skeleton-loader
                          animation="progress"
                          [theme]="{
                            'border-radius': '0px',
                            height: '3rem',
                            'background-color': '#c4e8e4 '
                          }"
                        ></ngx-skeleton-loader>
                      </div>
                      <div class="user-full-name" *ngIf="userDetails">
                        <h3 class="title mb-0" *ngIf="userDetails">
                          {{ userDetails.firstname+" "+userDetails.lastname }}
                        </h3>
                        <p class="text-muted" *ngIf="userDetails">
                          {{ userDetails.email }}
                        </p>
                      </div>

                      <div>
                        <mat-icon
                          class="text-info"
                          style="cursor: pointer;"
                          (click)="editProfile=true"
                          >edit</mat-icon
                        >
                      </div>
                    </div>
                    <!-- <h3 class="title mb-0" *ngIf="userDetails">
                        {{
                          "Hi "+userDetails.firstname
                        }}

                      </h3> -->
                  </div>
                  <div class="col-md-5 text-md-right text-center"></div>
                </div>

                <form *ngIf="editProfile" [formGroup]="userform">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="form-group">
                        <label>First name:</label>
                        <input
                          formControlName="firstname"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <p
                        class="text-danger"
                        *ngIf="userform.controls.firstname.status==='INVALID' && userform.controls.firstname.touched"
                      >
                        First name is required.
                      </p>
                    </div>

                    <div class="col-md-5">
                      <div class="form-group">
                        <label>Last name:</label>
                        <input
                          formControlName="lastname"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <p
                        class="text-danger"
                        *ngIf="userform.controls.lastname.status==='INVALID' && userform.controls.lastname.touched"
                      >
                        Last name is required.
                      </p>
                    </div>

                    <div class="col-md-5 d-flex">
                      <input
                        (click)="submitForm()"
                        type="button"
                        class="btn btn-primary"
                        value="Update"
                      />
                      <button
                        class="btn btn-default text-danger"
                        (click)="editProfile=false"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <!--end col-->
            </div>

            <!--end row-->
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--ed container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Profile Start -->
<section class="section mt-60">
  <div class="container mt-lg-3">
    <div class="row">
      <!-- <div class="col-lg-4 col-md-6 col-12 d-lg-block d-none">
          <div class="sidebar sticky-bar p-4 rounded shadow">
            <div class="widget">
              <h5 class="widget-title">Library progress:</h5>
           
           
            </div>

       
          </div>
        </div> -->
      <!--end col-->

      <div class="col-lg-12 col-12">
        <div class="rounded shadow p-4">
          <div class="row">
            <div class="col-lg-12" id="series-course-tab">
              <mat-tab-group mat-align-tabs="start">
                <mat-tab label="Workshop">
                  <div class="card">
                    <div class="card-body">
                      <div
                        class="alert alert-info empty-section"
                        *ngIf="!workShopList.length&&isWorkShopLoaded"
                      >
                        Workshop enrollment is empty, buy more workshop
                        <a class="" routerLink="/workshop"> Click. </a>
                      </div>

                      <div
                        class="row"
                        *ngIf="!workShopList.length&&!isWorkShopLoaded"
                      >
                        <div
                          class="col-md-4 col-lg-4 col-sm-12"
                          *ngFor="let item of [1,2,4]"
                        >
                          <ngx-skeleton-loader
                            animation="progress"
                            [theme]="{
                            'border-radius': '0px',
                            height: '16rem',
                            'background-color': '#c4e8e4 '
                          }"
                          ></ngx-skeleton-loader>
                          <ngx-skeleton-loader
                            animation="progress"
                            [theme]="{
                            'border-radius': '0px',
                            height: '2rem',
                            'background-color': '#c4e8e4 '
                          }"
                          ></ngx-skeleton-loader>
                        </div>
                      </div>
                      <div class="row">
                        <div
                          class="mt-4 pt-2 col-md-4"
                          *ngFor="let course of workShopList"
                        >
                          <div
                            class="card border-0 work-container work-classic"
                          >
                            <div class="card-body p-0">
                              <img
                                [src]="
                              viewUrl + '/Uploads/Courses/' + course.image + '.jpg'
                            "
                                class="img-fluid rounded work-image"
                                alt=""
                              />
                              <div class="content pt-3">
                                <h5 class="mb-0">
                                  <a class="text-dark title">
                                    {{ course.title }}</a
                                  >
                                </h5>

                                <div class="text-primary">
                                  <div
                                    *ngIf="isNotToday;"
                                    class="mt-2 font-weight-bold"
                                  >
                                    {{publishDate | date:'shortTime'}} {{
                                    publishDate | date:'fullDate'}} PST
                                  </div>
                                  <div *ngIf="!isNotToday">
                                    <div
                                      class="timer-div-container mt-1"
                                      *ngIf="0<=_days && 0<=_hours && 0<=_minutes && 0<=_seconds;else startWthRef"
                                    >
                                      <div class="tmr-text">Time left:</div>
                                      <div
                                        class="tmr-dgit shadow-sm border border-default rounded"
                                      >
                                        {{_days}}
                                      </div>
                                      <div class="tmr-dgit">:</div>
                                      <div
                                        class="tmr-dgit shadow-sm border border-default rounded"
                                      >
                                        {{_hours}}
                                      </div>
                                      <div class="tmr-dgit">:</div>
                                      <div
                                        class="tmr-dgit shadow-sm border border-default rounded"
                                      >
                                        {{_minutes}}
                                      </div>
                                      <div class="tmr-dgit">:</div>
                                      <div
                                        class="tmr-dgit shadow-sm border border-default rounded"
                                      >
                                        {{_seconds}}
                                      </div>
                                      <div></div>
                                      <div class="tmr-text">Days</div>
                                      <div></div>
                                      <div class="tmr-text">Hours</div>
                                      <div></div>
                                      <div class="tmr-text">Minutes</div>
                                      <div></div>
                                      <div class="tmr-text">Seconds</div>
                                    </div>
                                    <ng-template #startWthRef>
                                      <div class="mt-2">
                                        <a
                                          class="p-2 border border-primary rounded shadow"
                                          title="Start watching"
                                          routerLink="/watch/{{course.CourseId}}"
                                          >Start Watching</a
                                        >
                                      </div>
                                    </ng-template>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab label="Courses">
                  <div
                    class="card"
                    *ngIf="courseList.length<=0 && isCoursesLoaded"
                  >
                    <div class="card-body">
                      <div class="alert alert-info empty-section">
                        Course enrollment is empty, buy more courses
                        <a class="" routerLink="/store"> Click. </a>
                      </div>
                    </div>
                  </div>
                  <div class="row projects-wrapper" *ngIf="!isCoursesLoaded">
                    <div
                      class="col-md-4 col-sm-6 col-lg-4 mt-4 pt-2"
                      *ngFor="let tmp of tmpArray"
                    >
                      <div class="card border-0 work-container work-classic">
                        <div class="card-body p-0">
                          <!-- <img
                            [src]="
                              viewUrl + '/Uploads/Courses/' + course.ThumbnailGUID + '.jpg'
                            "
                            class="img-fluid rounded work-image" 
                            alt=""
                          /> -->

                          <ngx-skeleton-loader
                            animation="progress"
                            [theme]="{
                            'border-radius': '0px',
                            height: '16rem',
                            'background-color': '#c4e8e4 '
                          }"
                          ></ngx-skeleton-loader>

                          <div class="content">
                            <h5 class="mb-0">
                              <ngx-skeleton-loader
                                animation="progress"
                                [theme]="{
                                  'border-radius': '0px',
                                  height: '20px',
                                  'background-color': '#c4e8e4 '
                                }"
                              ></ngx-skeleton-loader>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="flex-cn-item projects-wrapper"
                    *ngIf="courseList.length>0 && isCoursesLoaded"
                  >
                    <div class="mt-4 pt-2" *ngFor="let course of tmpCourseList">
                      <div class="card border-0 work-container work-classic">
                        <div class="card-body p-0">
                          <img
                            [src]="
                              viewUrl + '/Uploads/Courses/' + course.ThumbnailGUID + '.jpg'
                            "
                            class="img-fluid rounded work-image"
                            alt=""
                          />
                          <div class="content pt-3">
                            <h5 class="mb-0">
                              <a
                                routerLink="/page-work-detail"
                                class="text-dark title"
                              >
                                {{ course.CourseName }}</a
                              >
                            </h5>
                            <!-- <span class="text-muted tag mb-0">
                                {{
                                  course.web_price | currency: "USD":"symbol"
                                }}
                              </span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div
                      class="col-lg-12"
                      style="padding: 2rem;"
                      *ngIf="courseList.length>9"
                    >
                      <div class="col-12">
                        <ul class="pagination justify-content-center mb-0">
                          <li class="page-item">
                            <a
                              [ngxScrollTo]="'#series-course-tab'"
                              class="page-link"
                              (click)="prevPageCourse()"
                              aria-label="Previous"
                              >Prev</a
                            >
                          </li>
                          <li
                            [ngxScrollTo]="'#series-course-tab'"
                            class="page-item"
                            *ngFor="let inx of coursePageNumber | async; let i = index"
                            [ngClass]="courseIndex == inx ? 'active' : ''"
                          >
                            <!-- [ngxScrollTo]="'#courses'" -->
                            <a
                              [ngxScrollTo]="'#series-course-tab'"
                              class="page-link"
                              (click)="onPageIndexCourse(inx)"
                              >{{ inx+1}}
                            </a>
                          </li>

                          <li class="page-item">
                            <a
                              [ngxScrollTo]="'#courses'"
                              class="page-link"
                              (click)="nextPageCourse()"
                              aria-label="Next"
                              >Next</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </mat-tab>

                <mat-tab label="Package">
                  <div class="card" *ngIf="seriesList.length<=0">
                    <div class="card-body">
                      <div class="alert alert-info empty-section">
                        Package enrollment is empty.
                      </div>
                    </div>
                  </div>

                  <div
                    class="flex-cn-item projects-wrapper"
                    style="margin-left: unset; margin-right: unset;"
                    *ngIf="seriesList.length>0"
                  >
                    <div class="mt-4 pt-2" *ngFor="let series of seriesList">
                      <div class="card border-0 work-container work-classic">
                        <div class="card-body p-0">
                          <img
                            [src]="
                            viewUrl + '/Uploads/Package/' + series.ImageGUID + '.jpg'
                          "
                            class="img-fluid rounded work-image"
                            alt=""
                          />
                          <div class="content pt-3">
                            <h5 class="mb-0">
                              <a
                                routerLink="/page-work-detail"
                                class="text-dark title"
                              >
                                {{ series.Name }}</a
                              >
                            </h5>
                            <!-- <span class="text-muted tag mb-0">
                              {{
                                series.Price | currency: "USD":"symbol"
                              }}
                            </span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-lg-12"
                      style="padding: 2rem;"
                      *ngIf="seriesList.length>9"
                    >
                      <div class="col-12">
                        <ul class="pagination justify-content-center mb-0">
                          <li class="page-item">
                            <a
                              [ngxScrollTo]="'#series-course-tab'"
                              class="page-link"
                              (click)="prevPageSeries()"
                              aria-label="Previous"
                              >Prev</a
                            >
                          </li>
                          <li
                            [ngxScrollTo]="'#series-course-tab'"
                            class="page-item"
                            *ngFor="let inx of seriesPageNumber | async; let i = index"
                            [ngClass]="seriesIndex == inx ? 'active' : ''"
                          >
                            <!-- [ngxScrollTo]="'#courses'" -->
                            <a
                              [ngxScrollTo]="'#series-course-tab'"
                              class="page-link"
                              (click)="onPageIndexSeries(inx)"
                              >{{ inx+1}}
                            </a>
                          </li>

                          <li class="page-item">
                            <a
                              [ngxScrollTo]="'#series-course-tab'"
                              class="page-link"
                              (click)="nextPageSeries()"
                              aria-label="Next"
                              >Next</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
          <!--end row-->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Profile End -->

<!-- Back to top -->
<a
  href="javascript: void(0);"
  class="btn btn-icon btn-soft-primary back-to-top"
  id="back-to-top"
  [ngxScrollTo]="'#home'"
>
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
