<div class="row">
  <div
    class="col-lg-3 col-md-3 col-12 mt-4 pt-2"
    *ngFor="let channel of channelList"
    (click)="redirectToBrowsePage(channel)"
  >
    <div
      class="
        card
        border-0
        work-container work-modern
        position-relative
        d-block
        overflow-hidden
        rounded
      "
    >
      <div class="card-body p-0">
        <img
          [src]="url + '/Uploads/Channels/' + channel.ThumbnailGuid + '.jpg'"
          style="width: 100%"
          alt="work-image"
        />
        <div class="overlay-work bg-dark"></div>
        <div class="content">
          <h5 class="mb-0">
            <a href="page-work-detail" class="text-white title">{{
              channel.C_Name
            }}</a>
          </h5>
        </div>
      </div>
    </div>
  </div>
</div>
