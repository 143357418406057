<!-- Hero Start -->
<section class="main-slider" id="home">
  <ngb-carousel
    class="slides"
    [showNavigationArrows]="showNavigationArrows"
    [showNavigationIndicators]="showNavigationIndicators"
  >
    <ng-template ngbSlide>
      <li
        class="bg-slider d-flex align-items-center"
        style="background-image: url('assets/images/course/bg05.jpg')"
      >
        <div class="bg-overlay"></div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 text-center">
              <div class="title-heading text-white mt-4">
                <h1 class="display-4 title-dark font-weight-bold mb-3">
                  Browse our content below
                </h1>
                <p class="para-desc para-dark mx-auto text-light">
                  You will find courses that will help you grow your mindset,
                  communication, leadership, community, and lifestyle.
                </p>
                <div class="mt-4"></div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ng-template>
  </ngb-carousel>
</section>
<!--end section-->
<!-- Hero End -->
<!-- <br /><br />
<div *ngIf="channelList.length>0">
  <app-campaign></app-campaign>
</div> -->

<!-- About Start -->
<!-- <section class="section pt-0" *ngIf="channelList.length>0">
  <div class="container">
    <app-instructor-list [authors] ="authors"></app-instructor-list>
    <div class="col-12 mt-4 pt-2 text-center">
      <a (click)="redirectToViewMoreAuthor()" class="btn btn-outline-primary">See More instructors <i class="mdi mdi-chevron-right"></i></a>
    </div>
  </div>
</section> -->
<!--end section-->
<!-- About End -->
<!-- <div class="container mt-100 mt-60" id="portfolio">
  <div class="row">
    <div class="col-12">
      <div class="section-title mb-4 pb-2">
        <h4 class="title mb-4 text-center">Channels</h4>
  
      </div>
    </div>
  </div>
 

  <app-channel-list [channelList] ="channelList"></app-channel-list>

  <div class="col-12 mt-4 pt-2 text-center">
   <a (click)="routeToMorechannels()" class="btn btn-outline-primary text-center">See More Channels<i class="mdi mdi-chevron-right"></i></a>
   </div>
 
</div> -->

<!-- Cta Start -->
<!-- <section class="section bg-cta" style="background: url('assets/images/course/bg02.jpg') center center;" id="cta">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title title-dark text-white mb-4">We Bring New Online Courses</h4>
          <p class="text-light para-desc para-dark mx-auto">Start working with Landrick that can provide everything you
            need to generate awareness, drive traffic, connect.</p>
          <a (click)="openWindowCustomClass(content)" href="javascript:void(0);"
            class="play-btn border border-light mt-4 video-play-icon">
            <i-feather name="play" class="fea icon-ex-md text-white title-dark"></i-feather>
          </a>
          <ng-template #content let-modal>
            <div class="modal-header">
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <iframe src="https://player.vimeo.com/video/12022651" height="450" width="780" frameborder="0"
                allowfullscreen allow="autoplay; encrypted-media"></iframe>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

</section> -->
<!--end section-->
<!-- Cta End -->

<section class="section" id="series">
  <div class="container">
    <div class="row">
      <div class="col-6 text-right">
        <div class="section-title mb-2 pb-1 pt-4">
          <h4 class="title mb-4">Packages</h4>
        </div>
      </div>
      <div class="col-6 mb-2 pb-1 pt-4">
        <button
          (click)="navidateToViewAll()"
          *ngIf="packageData.packageList.length > 0"
          class="btn btn-soft-primary float-right"
        >
          See More
          <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
        </button>
      </div>
    </div>

    <app-package-list
      [packageList]="packageData.packageList"
      [isPackageLoaded]="packageData.isPackageLoaded"
    ></app-package-list>
  </div>
</section>
<!-- Courses Start -->
<section class="section" id="courses">
  <div class="container">
    <!--end row-->
    <app-courses-list
      [courses]="courseData.coursesList"
      [isCoursesLoaded]="courseData.isCoursesLoaded"
    ></app-courses-list>

    <div class="col-12">
      <ul class="pagination justify-content-center mb-0">
        <li class="page-item">
          <a
            [ngxScrollTo]="'#courses'"
            class="page-link"
            (click)="prevPage()"
            aria-label="Previous"
            *ngIf="pageIndex > 1"
            >Prev</a
          >
        </li>
        <li
          class="page-item"
          *ngFor="let inx of pageInx; let i = index"
          [ngClass]="pageIndex == inx ? 'active' : ''"
        >
          <a
            [ngxScrollTo]="'#courses'"
            class="page-link"
            (click)="onPageIndex(inx)"
            >{{ inx }}</a
          >
        </li>

        <li class="page-item">
          <button
            [ngxScrollTo]="'#courses'"
            class="page-link"
            (click)="nextPage()"
            aria-label="Next"
            *ngIf="pageIndex != totalIndex"
          >
            Next
          </button>
        </li>
      </ul>
    </div>
  </div>
  <!--end container-->
</section>

<a
  href="javascript: void(0);"
  class="btn btn-icon btn-soft-primary back-to-top"
  id="back-to-top"
  [ngxScrollTo]="'#home'"
>
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
