<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" id="home">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-8 col-md-8">
        <div class="title-heading mt-4">
          <h1 class="heading mb-3"><span class="textPR">CirclesNow</span></h1>
          <p class="para-desc text-muted">
            CirclesNow unveils healthy living by exploring life through five
            crucial lenses: mindset, communication, leadership, community, and
            lifestyle.
          </p>
          <div class="mt-4">
            <a
              href="https://apps.apple.com/in/app/circlesnow/id1499418854"
              target="_blank"
              class="btn btn-primary mt-2 mr-2"
              ><i class="mdi mdi-apple"></i> App Store</a
            >
            <a
              href="https://play.google.com/store/apps/details?id=com.goldbrickpub.circles"
              target="_blank"
              class="btn btn-outline-primary mt-2"
              ><i class="mdi mdi-google-play"></i> Play Store</a
            >
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="text-md-right text-center">
          <img src="assets/home.png" class="img-fluid images" alt="" />
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
        fill="currentColor"
      ></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Features Start -->
<section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">CirclesNow makes life better.</h4>
          <p class="text-muted para-desc mb-0 mx-auto">
            Being, thinking, feeling and doing “better” is a measurement of
            improvement in your personal and professional development. This
            improvement is marked by your increased ability to impact the world
            around you through these five unique lenses:
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center align-items-center">
      <div class="col-lg-8 col-md-8">
        <div class="row mt-4 pt-2">
          <div class="col-md-6 col-12">
            <div class="media features pt-4 pb-4">
              <div class="icon text-center rounded-circle textPR mr-3 mt-2">
                <mat-icon aria-hidden="false" aria-label="Example home icon">
                  people</mat-icon
                >
              </div>
              <div class="media-body">
                <h4 class="title">Leadership</h4>
                <p class="text-muted para mb-0">
                  How you reflect on your impact and outcomes as you engage your
                  vision and potential reveals your leadership
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-6 col-12">
            <div class="media features pt-4 pb-4">
              <div class="icon text-center rounded-circle textPR mr-3 mt-2">
                <mat-icon aria-hidden="false" aria-label="Example home icon">
                  psychology</mat-icon
                >
              </div>
              <div class="media-body">
                <h4 class="title">Mindset</h4>
                <p class="text-muted para mb-0">
                  How you choose to see and understand the world around you and
                  create your own point of view reveals your mindset.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-6 col-12">
            <div class="media features pt-4 pb-4">
              <div class="icon text-center rounded-circle textPR mr-3 mt-2">
                <mat-icon aria-hidden="false" aria-label="Example home icon">
                  record_voice_over</mat-icon
                >
              </div>
              <div class="media-body">
                <h4 class="title">Communication</h4>
                <p class="text-muted para mb-0">
                  How you transact daily with others seeking to understand and
                  be understood reveals your communication.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-6 col-12">
            <div class="media features pt-4 pb-4">
              <div class="icon text-center rounded-circle textPR mr-3 mt-2">
                <mat-icon aria-hidden="false" aria-label="Example home icon"
                  >directions_run</mat-icon
                >
              </div>
              <div class="media-body">
                <h4 class="title">Lifestyle</h4>
                <p class="text-muted para mb-0">
                  How you discipline your habits to serve you in developing and
                  reaching your desires reveals your lifestyle
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-6 col-12">
            <div class="media features pt-4 pb-4">
              <div class="icon text-center rounded-circle textPR mr-3 mt-2">
                <mat-icon aria-hidden="false" aria-label="Example home icon"
                  >groups</mat-icon
                >
              </div>
              <div class="media-body">
                <h4 class="title">Community</h4>
                <p class="text-muted para mb-0">
                  How you inspire and invite the best version of everyone around
                  you to show up and connect reveals your community
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--end row-->
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2 text-center text-md-right">
        <img src="assets/channel.png" class="img-fluid" alt="" />
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Feature End -->

<!-- Testi n Download cta start -->
<section class="section pt-0">
  <div class="container">
    <div
      class="
        row
        mt-md-5
        pt-md-3
        mt-4
        pt-2
        mt-sm-0
        pt-sm-0
        justify-content-center
      "
    >
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Get the app now!</h4>

          <p>
            Download the
            <span class="textPR font-weight-bold">CirclesNow</span> app to get
            started with our content
          </p>
          <div class="mt-4">
            <a
              href="https://apps.apple.com/in/app/circlesnow/id1499418854"
              target="_blank"
              class="btn btn-primary mt-2 mr-2"
              ><i class="mdi mdi-apple"></i> App Store</a
            >
            <a
              href="https://play.google.com/store/apps/details?id=com.goldbrickpub.circles"
              target="_blank"
              class="btn btn-outline-primary mt-2"
              ><i class="mdi mdi-google-play"></i> Play Store</a
            >
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Testi n Download cta End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
        fill="currentColor"
      ></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Back to top -->
<a
  href="javascript: void(0);"
  class="btn btn-icon btn-soft-primary back-to-top"
  id="back-to-top"
  [ngxScrollTo]="'#home'"
>
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
