import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'CirclesNow';
  addclass: string;
 
  /**
   * Router activation
   */
  onActivate(componentReference: any) {
    this.addclass = componentReference.navClass;
  }
  constructor(private router: Router,
    private gtmService: GoogleTagManagerService
    ) {
    /**
     * Unicons icon refreshed on route change.
     */
     const gtmTag = {
      ecommerce: null
     }

     this.gtmService.pushTag(gtmTag);

    router.events.forEach((event) => {
      window.scrollTo(0,0);
      if (event instanceof NavigationEnd) {
        window['Unicons']['refresh']();
      }
      this.gtmService.pushTag(gtmTag);
    });

    this.onChangeColor("");
  }
  onChangeColor(color: string) {
    document
      .getElementById('color-opt')
      .setAttribute('href', './assets/css/colors/' + 'skyblue' + '.css');
  }
}
