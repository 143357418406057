import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "./authentication.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class DataserviceService {
  url: string = environment.baseUrl;
  viewUrl: string = environment.viewUrl;
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}

  contactUsPost(payload: any): Observable<any> {
    return this.http.post(
      this.url + "/ProductRESTService.svc/ContactUs",
      payload
    );
  }

  getCoursesList(
    channelID: number,
    pageNo: number,
    pageSize: number
  ): Observable<Array<Course>> {
    return this.http.get<Array<Course>>(
      this.url +
        "/ProductRESTService.svc/GCRSBYCHNLID?ChannelId=" +
        channelID +
        "&PageNo=" +
        pageNo +
        "&PageSize=" +
        pageSize
    );
  }

  getAllCategory(
    channelID: number,
    pageNo: number,
    pageSize: number
  ): Observable<any> {
    return this.http.get(
      this.url +
        "/ProductRESTService.svc/GCATBYCHNLIDFR?ChannelId=" +
        channelID +
        "&PageNo=" +
        pageNo +
        "&PageSize=" +
        pageSize
    );
  }

  getAllAuthor(
    channelID: number,
    pageNo: number,
    pageSize: number
  ): Observable<any> {
    return this.http.get(
      this.url +
        "/ProductRESTService.svc/GAUTHBYCHNLID?ChannelId=" +
        channelID +
        "&PageNo=" +
        pageNo +
        "&PageSize=" +
        pageSize
    );
  }
  getChannelList(pageNo: number, pageSize: number): Observable<Array<Channel>> {
    return this.http.get<Array<Channel>>(
      this.url +
        "/ProductRESTService.svc/GCHNLLSTFRWEB?PageNo=" +
        pageNo +
        "&PageSize=" +
        pageSize
    );
  }

  getPopularAuthor(): Observable<any> {
    return this.http.get(this.url + "/ProductRESTService.svc/GAUTHL");
  }
  getPopularCourses(pageNo: number, pageSize: number): Observable<any> {
    return this.http.get<Array<Course>>(
      this.url +
        "/ProductRESTService.svc/GTOPENRLCRS?PageNo=" +
        pageNo +
        "&PageSize=" +
        pageSize
    );
  }
  getCampaing(): Observable<any> {
    return this.http.get<Array<Course>>(
      this.url + "/ProductRESTService.svc/v1/GALLCRSCMP"
    );
  }

  getCourseDetailsByID(id: number, userId?: number): Observable<Course> {
    return this.http.get<Course>(
      this.url +
        "/ProductRESTService.svc/GCECNTBCIDUID?CourseId=" +
        id +
        "&UserId=" +
        (userId ? userId : 0)
    );
  }
  getCourseDetailsByGUID(guid: string): Observable<Course[]> {
    let url: string = "";
    if (this.authService.isLoggednIn())
      url =
        this.url +
        "/ProductRESTService.svc/GCDBYCGUID?CourseGUID=" +
        guid +
        "&UserId=" +
        this.authService.getUserInfo().ID;
    else
      url =
        this.url +
        "/ProductRESTService.svc/GCDBYCGUID?CourseGUID=" +
        guid +
        "&UserId=0";
    return this.http.get<Course[]>(url);
  }

  getEnrolledCourse(): Observable<Course[]> {
    return this.http.get<Course[]>(
      this.url +
        "/ProductRESTService.svc/GMYENRLDCRS?userid=" +
        this.authService.getUserInfo().ID
    );
  }
  getEnrolledPackage(): Observable<[]> {
    return this.http.get<[]>(
      this.url +
        "/ProductRESTService.svc/GMYENRLDPCKS?userid=" +
        this.authService.getUserInfo().ID
    );
  }

  getCourseRelatedCourses(courseId: any): Observable<Course[]> {
    return this.http.get<[]>(
      this.url +
        "/ProductRESTService.svc/GRELCRSBYCRSID?CourseId=" +
        courseId +
        "&UserId=0"
    );
  }
  getPackageList(): Observable<Array<Package>> {
    return this.http.get<Array<Package>>(
      this.url + "/ProductRESTService.svc/GetPackages?PageNo=1&PageSize=10"
    );
  }

  getPackageDetails(packageID: any): Observable<Package> {
    return this.http.get<Package>(
      this.url + "/ProductRESTService.svc/GetPackagebyId?PackageId=" + packageID
    );
  }
  getSPData(payload: string): Observable<any> {
    return this.http
      .post(this.url + "/ProductRESTService.svc/getSPDataWOAuth", payload)
      .pipe(
        map((response) => {
          return response["dt"] ? JSON.parse(response["dt"]) : [];
        })
      );
  }
}

export class Course {
  ChannelId: number;
  ChannelName: string;
  CourseDesc: string;
  CourseId: number;
  CourseName: string;
  EnrollCount: number;
  Price: number;
  ThumbnailGUID: string;
  CourseLessons: number;
  EnrollCnt: number;
  web_price: number;
  course_count: number = 1;
  IsEnrolled: number;
  CourseGUID: string = "";
  payment: boolean = false;
  isInCart: boolean = false;
  CourseType: any;
  Description: string;
}

export class Channel {
  C_ID: number;
  C_Name: string;
  ChannelKeywords: string;
  ChnlSubsCount: number;
  CourseList: null;
  Description: string;
  IsChnlSubs: number;
  RoleId: number;
  ThumbnailGuid: string;
}
export class Package {
  CourseIds: string;
  Courses: Array<any>;
  CreatedDate: string;
  Description: string;
  ImageGUID: string;
  IsActive: boolean;
  IsEnrolled: boolean;
  Name: string;
  PackageAuthors: string;
  PackageProgress: string;
  Price: number;
  UpdatedDate: string;
  UserId: number;
  id: number;
  product_tier_id: number;
  type: string;
  web_price: any;
}
