import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStorageService } from "ngx-localstorage";
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { Course, Package } from "./dataservice.service";

@Injectable({
  providedIn: "root",
})
export class CheckoutService {
  private _obsShoppingBag = new Subject();
  private _ITEM_KEY: string = "courseIts";
  private _ITEM_PK_KEY: string = "packages";

  detailsType: Detailstype = new Detailstype();
  constructor(
    private _localStore: LocalStorageService,
    private _http: HttpClient
  ) {}

  url: string = environment.baseUrl;
  viewUrl: string = environment.viewUrl;

  payCourse(payload: any): Observable<any> {
    return this._http.post(
      this.url + "/ProductRESTService.svc/StripePayment",
      payload
    );
  }

  setStoreItem(Course: any,courseType?:string): void {
    Course.course_count = 1;
    Course.type = this.detailsType.course;
    Course.tLabel = courseType ? courseType : '';
    let courseId: Array<any> = [];

    if (this._localStore.get(this._ITEM_KEY)) {
      let tmpArray: Array<any> = this._localStore.get(this._ITEM_KEY);
      tmpArray = tmpArray.map((course) => !course.payment);
      courseId = [...[Course], ...this._localStore.get(this._ITEM_KEY)];
    } else {
      courseId.push(Course);
    }

    this._localStore.set(this._ITEM_KEY, courseId);
    this._obsShoppingBag.next(courseId);
  }

  isCourseAlreadyInBag(courseId: any): boolean {
    let courseArray: Array<Course> = this.getStoredCoursesArray();
    if (courseArray) {
      return (
        courseArray.filter((course) => course.CourseId == courseId).length > 0
      );
    } else return false;
  }
  clearStore(): void {
    this._localStore.set(this._ITEM_KEY, null);
  }
  setPurchasedState(): void {
    let courses: Array<any> = [];
    if (this._localStore.get(this._ITEM_KEY)) {
      courses = this._localStore.get(this._ITEM_KEY);
      courses.map((course) => {
        course.payment = true;
        return courses;
      });

      this._localStore.set(this._ITEM_KEY, courses);
    }
  }

  refresStoreItem(): void {
    let courseId: Array<any> = [];
    if (this._localStore.get(this._ITEM_KEY)) {
      courseId = this._localStore.get(this._ITEM_KEY);
    }
    this._obsShoppingBag.next(courseId);
  }
  storeArrayItem(courses: Array<any>): void {
    this._localStore.set(this._ITEM_KEY, courses);
  }

  getStoredCoursesArray(): Array<Course> {
    let courseList: Array<any> = [];
    if (this._localStore.get(this._ITEM_KEY))
      courseList = this._localStore.get(this._ITEM_KEY);
    return courseList.filter((item) => {
      return item.type === this.detailsType.course;
    });
  }

  getAllItemFromBag(): Array<any> {
    if (this._localStore.get(this._ITEM_KEY))
      return this._localStore.get(this._ITEM_KEY);
    else [];
  }
  getStoreItem(): Observable<any> {
    return this._obsShoppingBag.asObservable();
  }

  // package
  addPackageInBag(pack: Package): void {
    pack.type = this.detailsType.package;

    let storedPackList: Array<any> = [];
    if (this._localStore.get(this._ITEM_KEY)) {
      storedPackList = [...[pack], ...this._localStore.get(this._ITEM_KEY)];
    } else {
      storedPackList.push(pack);
    }
    this._localStore.set(this._ITEM_KEY, storedPackList);
    this._obsShoppingBag.next(storedPackList);
  }

  getStoredPackgeArray(): Array<Package> {
    let packageList: Array<any> = [];
    if (this._localStore.get(this._ITEM_KEY))
      packageList = this._localStore.get(this._ITEM_KEY);
    return packageList.filter((item) => {
      return item.type === this.detailsType.package;
    });
  }
  isPackageAlreadyInBag(pack: Package): boolean {
    let tmpArray: Array<Package> = this.getStoredPackgeArray();
    if (tmpArray) {
      return tmpArray.filter((pk) => pk.id === pack.id).length > 0;
    } else return false;
  }

  removePackage(pack: Package): void {
    let packageList: Array<Package> = this.getStoredPackgeArray();
    let courseList: Array<Course> = this.getStoredCoursesArray();
    if (packageList.length) {
      packageList = packageList.filter((pk) => pk.id != pack.id);
      this.storeArrayItem([...courseList, ...packageList]);
    }
    this.refresStoreItem();
  }

  removeCourse(course: Course): void {
    let courseList: Array<Course> = this.getStoredCoursesArray();
    let packageList: Array<Package> = this.getStoredPackgeArray();
    if (courseList.length) {
      courseList = courseList.filter((cr) => cr.CourseId != course.CourseId);
      this.storeArrayItem([...courseList, ...packageList]);
    }
    this.refresStoreItem();
  }

  getCountryList(): Observable<[]> {
    return this._http.get<[]>("/assets/country.json");
  }
}

export class Detailstype {
  package: string = "package";
  course: string = "course";
}
