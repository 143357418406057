



<!-- <app-switcher></app-switcher> -->


<router-outlet (activate)="onActivate($event)"></router-outlet>


<modal-outlet></modal-outlet>

<app-carousel></app-carousel>  

<ngx-spinner></ngx-spinner>
