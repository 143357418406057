import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  apiUrl: string = environment.apiUrl;
  constructor(private http: HttpClient) {}

  loginSuccessFul(data:any,rememberMe?:boolean) : void {
    localStorage.setItem("u_info",JSON.stringify(data))
    // if(rememberMe) 
    //   localStorage.setItem("u_info",JSON.stringify(data))
    // else
    //   sessionStorage.setItem("u_info",JSON.stringify(data))
  }
  login(payload: any): Observable<any> {
    return this.http.post(this.apiUrl + "Login", payload);
  }

  registration(payload: any): Observable<any> {
    return this.http.post(this.apiUrl + "RegisterUser", payload);
  }
  getToken(): string {
    if (this.getUserInfo() != null) return this.getUserInfo().TokenValue;
    else null;
  }

  isLoggednIn() {
    return this.getUserInfo() != null;
  }

  getUserInfo(): any {
    // let storage = localStorage.getItem("u_info");
    // let session = sessionStorage.getItem("u_info");
    // if(session) return JSON.parse(session);
    // else if(storage) return JSON.parse(storage);
    // else return null;
    let storage = localStorage.getItem("u_info");
    if(storage) return JSON.parse(storage);
    else return null;
  }
  sendOtp(email: string, firstname: string): Observable<any> {
    return this.http.get<number>(
      this.apiUrl + "SendOTPMail?EmailId=" + email + "&FName=" + firstname
    );
  }
  otpVerification(email: string, otp: any): Observable<number> {
    return this.http.get<number>(
      this.apiUrl + "OTPValidation?EmailId=" + email + "&OTP=" + otp
    );
  }

  passwordResetEmail(email: string): Observable<any> {
    return this.http.get<number>(
      this.apiUrl + "ForgotPasswordOTP?EmailId=" + email
    );
  }
  resetPasswordOTP(payload: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + "ResetPasswordOTP", payload);
  }

  getUserDetail(): Observable<UserDetails> {
    return this.http.get<UserDetails>(
      this.apiUrl + "GUSER?Id=" + this.getUserInfo().ID
    );
  }
  updateUserDetails(payload: any): Observable<any> {
    return this.http.post(this.apiUrl + "UpdateUser", payload);
  }

  checkEmailAlreadyExit(email: string): Observable<any> {
    return this.http.get(this.apiUrl + "IsEmailVal?EmailId=" + email);
  }
}

export interface UserDetails {
  ID: number;
  RegFrmType: number;
  TokenValue: any;
  createdby: number;
  email: string;
  firstname: string;
  isactive: number;
  lastname: string;
  message: string;
  middlename: string;
  name: string;
  password: null;
  usertypeid: number;
}
