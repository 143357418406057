import { Component, Input, OnInit } from "@angular/core";
import { DataserviceService } from "src/app/service/dataservice.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-campaign",
  templateUrl: "./campaign.component.html",
  styleUrls: ["./campaign.component.css"],
})
export class CampaignComponent implements OnInit {
  campaignList: Array<any> = [];
  viewUrl: string = environment.viewUrl;
  arraySize: number = 3;
  allCamp: Array<any> = [];

  constructor(private dataService: DataserviceService) {}

  ngOnInit(): void {
    this.getCampaing();
  }
  getCampaing(): void {
    this.dataService.getCampaing().subscribe((response) => {
      this.campaignList = response;
      this.allCamp = this.campaignList;
      this.campaignList = this.campaignList.slice(0, this.arraySize);
    });
  }
  loadMore(): void {
    this.arraySize = this.arraySize + 3;
    this.campaignList = this.allCamp.slice(0, this.arraySize);
  }
}
