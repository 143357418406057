<div *ngIf="course">
  <h1 class=" buy-title textPR font-weight-bold">Welcome AHRKpath member!</h1>
  <h5 class="buy-sub-title">
    Please login or sign up to CirclesNow to purchase your AHRKpath 
    {{
      course.CourseType===1?'course':course.CourseType===1?'package':'channel subscription.'
    }}
  </h5>

  <div class="card buy-course-card">
    <div class="card-body">
      <div class="course-cont">

        <img
          *ngIf="course.CourseType===1"
          [src]="viewUrl + '/Uploads/Courses/' + course.ThumbnailGUID + '.jpg'"
          class="img-fluid rounded-top mx-auto"
          alt=""
        />

        
        <img
          *ngIf="course.CourseType===2"
          [src]="viewUrl + '/Uploads/Package/' + course.ThumbnailGUID + '.jpg'"
          class="img-fluid rounded-top mx-auto"
          alt=""
        />

        <img
        *ngIf="course.CourseType===3"
        [src]="viewUrl + '/Uploads/Channels/' + course.ThumbnailGUID + '.jpg'"
        class="img-fluid rounded-top mx-auto"
        alt=""
      />


        <div style="padding: 1rem; padding-top: unset" >
          <span *ngIf="course.CourseType!=3">{{ course.web_price | currency: "USD":"symbol" }} <br /></span>
          <h5>{{ course.CourseName }}</h5>
          <p>
            {{ course.Description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
