import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Package } from "src/app/service/dataservice.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-package-list",
  templateUrl: "./package-list.component.html",
  styleUrls: ["./package-list.component.css"],
})
export class PackageListComponent implements OnInit {
  _packagetList: Array<Package> = [];
  @Input() set packageList(val) {
    this._packagetList = val.map((list) => {
      list.ImageGUID = list.ImageGUID + ".jpg?" + Date.now();
      return list;
    });
  }

  @Input() isPackageLoaded: boolean = false;
  viewUrl: string = environment.viewUrl;
  tmpLoadingList = [1, 2, 3];
  constructor(private router: Router) {}

  redirectDetailsPage(packge: Package): void {
    if (packge.id) this.router.navigateByUrl("/details/package/" + packge.id);
  }

  ngOnInit(): void {}

  getCurrentTime(): any {
    return Date.now();
  }
}
