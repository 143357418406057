import { Component, Input, OnInit } from '@angular/core';
import { DataserviceService } from 'src/app/service/dataservice.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-instructor-list',
  templateUrl: './instructor-list.component.html',
  styleUrls: ['./instructor-list.component.css']
})
export class InstructorListComponent implements OnInit {

  @Input() authors:Array<any> = [];
  @Input() channelId:number = 0;
  allAuthors:Array<any> = [];
  fileUrl:string =  environment.viewUrl;
  arraySize:number=4;
  constructor(
    private dataService:DataserviceService
  ) { }

  ngOnInit(): void {
  }

}
