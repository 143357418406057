<section class="section" id="courses">
  <div class="">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Most Popular Courses</h4>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="flex-courses" *ngIf="isCoursesLoaded">
      <div
        class="mt-4 pt-2 course-sec-1"
        *ngFor="let course of courses; let i = index"
      >
        <div
          class="card overflow-hidden rounded shadow border-0"
          style="cursor: pointer"
          (click)="redirectToCourseDetails(course)"
        >
          <div class="position-relative d-block overflow-hidden">
            <img
              [src]="
                viewUrl + '/Uploads/Courses/' + course.ThumbnailGUID + '.jpg'
              "
              class="img-fluid rounded-top mx-auto"
              alt=""
            />
            <div class="overlay-work bg-dark"></div>
          </div>

          <div class="card-body">
            <h5>
              <a class="title text-dark">
                {{ course.CourseName }}
              </a>
            </h5>

            <div class="fees d-flex justify-content-between">
              <ul class="list-unstyled mb-0 numbers">
                <li>
                  <i class="mdi mdi-school text-muted"></i>
                  <span class="enroll-count">
                    {{ course.EnrollCnt }} Enrolled
                  </span>
                </li>
                <li>
                  <i class="mdi mdi-book text-muted"></i>
                  <span class="lesson-count">
                    {{ course.NoOfLessons }} Lessons
                  </span>
                </li>
              </ul>
              <h4 class="price-cntr">
                <span class="h6">$</span>
                {{ course.web_price }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-courses" *ngIf="!isCoursesLoaded">
      <div class="" *ngFor="let sk of tmpSkeletonList; let i = index">
        <div
          class="card overflow-hidden rounded shadow border-0"
          style="cursor: pointer"
        >
          <div class="position-relative d-block overflow-hidden">
            <ngx-skeleton-loader
              animation="progress"
              [theme]="{
                'border-radius': '0px',
                height: '14rem',
                'background-color': '#E7F5F4'
              }"
            ></ngx-skeleton-loader>

            <div class="overlay-work bg-dark"></div>
          </div>

          <div class="card-body">
            <ngx-skeleton-loader
              count="2"
              animation="progress"
              [theme]="{
                'border-radius': '0px',
                height: '1rem',
                'background-color': '#E7F5F4 '
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
</section>
