<div class="container mt-4 mt-lg-0" *ngIf="allCamp.length > 0">
  <div class="row">
    <div class="col-12">
      <div class="section-title mb-4 pb-2">
        <h4 class="title mb-4 text-center">Campaign</h4>
      </div>
    </div>
    <!--end col-->
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-6 mt-4 pt-2" *ngFor="let camp of campaignList">
      <div class="card blog rounded border-0 shadow overflow-hidden">
        <div class="position-relative">
          <img
            [src]="
              viewUrl +
              '/Uploads/CourseCampaigns/thumb/' +
              camp.CampaignThumbnail +
              '.jpg'
            "
            class="card-img-top"
            alt="..."
          />
          <div class="overlay rounded-top bg-dark"></div>
        </div>
        <div class="card-body content">
          <h5>
            <a href="javascript:void(0)" class="card-title title text-dark">
              {{ camp.CampaignName }}
            </a>
          </h5>
        </div>
      </div>
    </div>
    <!--end col-->
  </div>
  <!--end row-->
</div>

<div class="col-12 mt-4 pt-2 text-center" *ngIf="allCamp.length > 3">
  <a (click)="loadMore()" class="btn btn-primary"
    >Load more <i class="mdi mdi-chevron-down"></i
  ></a>
</div>
