import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-index-apps',
  templateUrl: './index-apps.component.html',
  styleUrls: ['./index-apps.component.css']
})
export class IndexAppsComponent implements OnInit {
  navClass = 'bg-white';
  constructor() { }

  ngOnInit(): void {
  }
}
