import { Component, Input, OnInit } from "@angular/core";
import {
  DataserviceService,
  Course,
} from "src/app/service/dataservice.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-course-to-buy",
  templateUrl: "./course-to-buy.component.html",
  styleUrls: ["./course-to-buy.component.css"],
})
export class CourseToBuyComponent implements OnInit {
  @Input() courseGUID: string = "";
  viewUrl: string = environment.viewUrl;
  course: Course;
  constructor(private dataService: DataserviceService) {}

  ngOnInit(): void {
    this.dataService
      .getCourseDetailsByGUID(this.courseGUID)
      .subscribe((response) => {
        this.course = response[0];
      });
  }
}
