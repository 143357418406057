import { Injectable } from "@angular/core";
import { GoogleTagManagerService } from "angular-google-tag-manager";

@Injectable({
  providedIn: "root",
})
export class GtmService {
  constructor(private gtmService: GoogleTagManagerService) {}

  addInCart(name, id, price): void {
    const gtmTag = {
      event: "add_to_cart",
      ecommerce: {
        items: [
          {
            item_name: name,
            item_id: id,
            price: price,
            item_category: "AHRKpath",
            quantity: 1,
          },
        ],
      },
    };

    this.gtmService.pushTag(gtmTag);
  }

  checkOutBegin(name, id, price): void {
    const gtmTag = {
      event: "begin_checkout",
      ecommerce: {
        items: [
          {
            item_name: name,
            item_id: id,
            price: price,
            item_category: "AHRKpath",
            quantity: 1,
          },
        ],
      },
    };

    this.gtmService.pushTag(gtmTag);
  }

  removeFromCheckout(name, id, price): void {
    const gtmTag = {
      event: "remove_from_cart",
      ecommerce: {
        items: [
          {
            item_name: name,
            item_id: id,
            price: price,
            item_category: "AHRKpath",
            quantity: 1,
          },
        ],
      },
    };

    this.gtmService.pushTag(gtmTag);
  }

  afterPurchaseCourse(tr_id, tax, shipping, currency, name, id, price): void {
    const gtmTag = {
      event: "purchase",
      ecommerce: {
        transaction_id: tr_id,
        tax: tax,
        shipping: shipping,
        currency: currency,

        items: [
          {
            item_name: name,
            item_id: id,
            price: price,
            item_category: "AHRKpath",
            quantity: 1,
          },
        ],
      },
    };

    this.gtmService.pushTag(gtmTag);
  }
}
