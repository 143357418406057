import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "src/app/service/authentication.service";
import { NgxSpinnerService } from "ngx-spinner";
import {
  DataserviceService,
  Course,
} from "src/app/service/dataservice.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-auth-login",
  templateUrl: "./auth-login.component.html",
  styleUrls: ["./auth-login.component.css"],
})
export class AuthLoginComponent implements OnInit {
  loginForm: FormGroup;

  returnUrl: string = "";
  courseGUID: string;
  errorMessage: string;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private dataService: DataserviceService
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl("", [Validators.required]),
      password: new FormControl("", [Validators.required]),
    });
  }

  signUp(): void {
    this.router.navigateByUrl("/auth/signup?returnUrl=" + this.returnUrl);
  }
  formgotPassword(): void {
    this.router.navigateByUrl(
      "/auth/passwordReset?returnUrl=" + this.returnUrl
    );
  }
  submit(): void {
    this.spinner.show();
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.value).subscribe(
        (response) => {
          if (response) {
            if (response.ID) {
              this.authService.loginSuccessFul(response);
              this.router.navigateByUrl(this.returnUrl);
            } else {
              this.errorMessage = response.message;
            }

            this.spinner.hide();
          } else {
            this.errorMessage = "Error :: User not exist.";
            this.spinner.hide();
          }
        },
        (error) => {
          this.spinner.hide();
        }
      );
    }
  }
  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
    let url = window.encodeURI(this.returnUrl);

    if (url.includes("EnrollCourse")) {
      this.courseGUID = url.split("EnrollCourse/")[1];
    }
  }
}
