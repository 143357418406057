import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  Channel,
  Course,
  DataserviceService,
} from "src/app/service/dataservice.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-index-shop",
  templateUrl: "./index-shop.component.html",
  styleUrls: ["./index-shop.component.css"],
})
export class IndexShopComponent implements OnInit {
  navClass = "nav-light";
  showNavigationArrows = false;
  showNavigationIndicators = false;
  url: string = environment.baseUrl;
  coursesList: Array<Course> = [];
  category: Array<Category> = [];
  authors: Array<any> = [];
  channelId: number = 0;
  channel: Channel;
  viewUrl: string = environment.viewUrl;

  loadMoreCatIndex: number = 9;
  constructor(
    private dataService: DataserviceService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.channel = JSON.parse(localStorage.getItem("channel"));

  }

  ngOnInit(): void {
    this.route.params.subscribe((param) => {
      if (param.id) {
        this.channelId = param.id;
        this.getAuthor(param.id, true);
      }
    });
  }

  loadMoreCategory(): void {
    this.loadMoreCatIndex += 10;
    this.getCategory(this.channelId, true);
  }
  innstrPageSize: number = 4;
  loadMoreInstructors(): void {
    this.innstrPageSize += this.innstrPageSize;
    this.getAuthor(this.channelId, false);
  }
  redirectToViewMoreCourse(): void {
    this.router.navigateByUrl("/courses/" + this.channelId);
  }
  getCourses(channelId: number): void {
    this.dataService.getCoursesList(channelId, 1, 10).subscribe((response) => {
      this.coursesList = response;
    });
  }
  getCategory(channelId: number, only: boolean): void {
    this.dataService
      .getAllCategory(channelId, 1, this.loadMoreCatIndex)
      .subscribe((response) => {
        this.category = response;
        if (!only) this.getCourses(channelId);
      });
  }

  getAuthor(channelId: number, isLoading?: boolean): void {
    this.dataService
      .getAllAuthor(channelId, 1, this.innstrPageSize)
      .subscribe((response) => {
        this.authors = response;
        if (isLoading) this.getCategory(channelId, false);
      });
  }
}

export class Category {
  CategoryDescription: string = "";
  CategoryId: number;
  CategoryName: string = "";
  ChannelName: string = "";
  ColorCode: string = "";
  Courses: [];
  IsActive: false;
}
