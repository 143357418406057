import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { IndexAppsComponent } from "./core/components/index-apps/index-apps.component";
import { IndexShopComponent } from "./core/components/index-shop/index-shop.component";
import { PagePrivacyComponent } from "./core/components/page-privacy/page-privacy.component";

import { IndexPersonalComponent } from "./core/components/index-personal/index-personal.component";
import { PageWorkModernComponent } from "./core/components/page-work-modern/page-work-modern.component";

import { AuthLoginComponent } from "./authentication/auth-login/auth-login.component";
import { AuthRePasswordComponent } from "./authentication/auth-re-password/auth-re-password.component";
import { AuthSignupComponent } from "./authentication/auth-signup/auth-signup.component";
import { PageContactDetailComponent } from "./core/components/page-contact-detail/page-contact-detail.component";
import { PageContactOneComponent } from "./core/components/page-contact-one/page-contact-one.component";
import { HomeComponent } from "./core/home/home.component";
import { AuthentincationComponent } from "./authentication/authentincation/authentincation.component";
import { IndexCourseComponent } from "./core/components/index-course/index-course.component";
import { AuthOptVerificicationComponent } from "./authentication/auth-opt-verificication/auth-opt-verificication.component";
import { AdminGuard } from "./guard/admin.guard";
import { AccountWorksComponent } from "./core/components/account-works/account-works.component";
import { ShopProductDetailComponent } from "./core/components/shop-product-detail/shop-product-detail.component";

import { ViewAllPackagesComponent } from "./core/components/view-all-packages/view-all-packages.component";
import { StreamComponent } from "./core/components/stream/stream.component";

const routes: Routes = [
  {
    path: "buy",
    loadChildren: () =>
      import("./shopping/shopping.module").then((m) => m.ShoppingModule),
  },
  {
    path: "EnrollCourse/:guid",
    loadChildren: () =>
      import("./enroll-course/enroll-course.module").then(
        (m) => m.EnrollCourseModule
      ),
    canActivate: [AdminGuard],
  },
  {
    path: "EnrollCourse",
    loadChildren: () =>
      import("./enroll-course/enroll-course.module").then(
        (m) => m.EnrollCourseModule
      ),
    canActivate: [AdminGuard],
  },
  {
    path: "watch/:courseID",
    loadChildren: () =>
      import("./watch/watch.module").then((m) => m.WatchModule),
    canActivate: [AdminGuard],
  },
  {
    path: "",
    component: HomeComponent,
    children: [
      {
        path: "store",
        component: IndexCourseComponent,
      },
      {
        path: "browse/:id",
        component: IndexShopComponent,
      },
      {
        path: "privacy",
        component: PagePrivacyComponent,
      },
      {
        path: "channel",
        component: IndexPersonalComponent,
      },
      {
        path: "channels",
        component: PageWorkModernComponent,
      },
      {
        path: "courses",
        component: PageWorkModernComponent,
      },
      {
        path: "Instructors",
        component: PageWorkModernComponent,
      },
      {
        path: "Instructors/:id",
        component: PageWorkModernComponent,
      },
      {
        path: "courses/:id",
        component: PageWorkModernComponent,
      },
      {
        path: "contactUs",
        component: PageContactOneComponent,
      },
      {
        path: "account",
        component: AccountWorksComponent,
        canActivate: [AdminGuard],
      },
      {
        path: "workshop",
        component: StreamComponent,
      },
      {
        path: "",
        component: IndexAppsComponent,
      },
      {
        path: "details/:type/:id",
        component: ShopProductDetailComponent,
      },
      {
        path: "packages",
        component: ViewAllPackagesComponent,
      },
    ],
  },
  {
    path: "auth",
    component: AuthentincationComponent,
    children: [
      {
        path: "login",
        component: AuthLoginComponent,
      },
      {
        path: "signup",
        component: AuthSignupComponent,
      },
      {
        path: "passwordReset",
        component: AuthRePasswordComponent,
      },
      {
        path: "otpverfi/:email",
        component: AuthOptVerificicationComponent,
      },
    ],
  },
  {
    path: "**",
    redirectTo: "store",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "top",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
