<!-- Hero Start -->
<section
  class="bg-home rtl-personal-hero bg-light d-flex align-items-center"
  style="background: url('assets/images/personal/bg01.png') center center"
  id="home"
>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-8 col-md-9">
        <div class="title-heading mt-4">
          <h1 class="display-3 font-weight-bold mb-3">
            Here I'm <br />
            <ngx-typed-js
              [strings]="['Calvin Carlo', 'UI/UX Designer', 'Web Developer']"
              [shuffle]="true"
              [typeSpeed]="80"
              [loop]="true"
            >
              <span class="element typing"></span>
            </ngx-typed-js>
          </h1>
          <p class="para-desc text-muted">
            Launch your campaign and benefit from our expertise on designing and
            managing conversion centered bootstrap4 html page.
          </p>
          <div class="mt-4 pt-2">
            <a href="#portfolio" class="btn btn-primary mt-2 mr-2 mouse-down"
              ><i class="mdi mdi-camera"></i> View Portfolio</a
            >
            <a href="#contact" class="btn btn-outline-primary mt-2 mouse-down"
              ><i class="mdi mdi-cloud-download"></i> Hire Me</a
            >
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- About Start -->
<section class="section">
  <!--end container-->

  <div class="container mt-100 mt-60" id="portfolio">
    <div class="row">
      <div class="col-12">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Channels</h4>
   
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <app-channel-list [channelList]="channelList"></app-channel-list>

    <div class="col-12 mt-4 pt-2">
      <a (click)="routeToMorechannels()" class="btn btn-outline-primary"
        >See More <i class="mdi mdi-chevron-right"></i
      ></a>
    </div>

    <!--end row-->
  </div>
  <!--end container-->

  <!--end container-->
</section>

<!-- Back to top -->
<a
  href="javascript: void(0);"
  class="btn btn-icon btn-soft-primary back-to-top"
  id="back-to-top"
  [ngxScrollTo]="'#home'"
>
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
